import axios from 'axios'

export const getCoords = async (address, distance) => {
	let result = null
	if (address) {
		try {
			const { data } = await axios.post(`${process.env.REACT_APP_ENDPOINT}/gmap/get-coords`, { address })
			if (data) {
				result = data
			}
		} catch (error) {
			result = error
			console.log('error: ', error.message)
		}
	} else {
		result = 'No address provided.'
	}

	return result
}
