import React from 'react'
import { useParams } from 'react-router-dom'

import { StyledPage } from './styles'
import { useUser } from 'global/context/UserContext'
import Login from './forms/Login'
import NewPassword from './forms/NewPassword'
import ForgotPassword from './forms/ForgotPassword'
import CreateAgent from './forms/CreateAgent'
import Typography from '@material-ui/core/Typography'

const AgentPage = () => {
	const { form, jwt } = useParams()
	const { agent } = useUser()
	return (
		<StyledPage>
			{form === 'login' || (!form && <Login />)}
			{form === 'new-password' && jwt && <NewPassword />}
			{((form === 'new-password' && !jwt) || form === 'forgot-password') && <ForgotPassword />}
			{form === 'create-agent' && agent?.isAdmin && <CreateAgent />}
			{form === 'create-agent' && !agent?.isAdmin && (
				<>
					<Typography variant='h3'>Access Denied</Typography>
					<Typography variant='h5'>You must be an Admin to create an agent</Typography>
				</>
			)}
		</StyledPage>
	)
}

export default AgentPage
