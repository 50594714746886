import React from 'react'
import styled from 'styled-components'

const IconPoint = ({ icon, iconWidth, iconHeight, children }) => {
	return (
		<StyledPoint iconWidth={iconWidth} iconHeight={iconHeight}>
			<div className='icon-container'>{icon}</div>
			<div className='text-container'>{children}</div>
		</StyledPoint>
	)
}

const StyledPoint = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	min-width: 270px;
	max-width: 450px;
	margin-bottom: 60px;
	padding: 0 25px;

	.icon-container {
		width: ${props => (props.iconWidth ? props.iconWidth : '100px')};
		height: ${props => (props.iconHeight ? props.iconHeight : '100px')};
		margin-bottom: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;

		img {
			width: 100%;
			height: auto;
		}

		svg {
			font-size: 3em;
		}
	}

	.text-container p {
		font-size: 18px;
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 600;
		text-align: center;
	}
`

export default IconPoint
