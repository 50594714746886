import React, { useState, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import cloneDeep from "lodash/cloneDeep";
import { FixedSizeList as List } from "react-window";

import { StyledLocationsSidebar } from "../styles";
import LocationSidebarItem from "./LocationSidebarItem";
import { useWindowResize } from "../../../utils/hooks/useWindowResize";

const LocationsSidebar = ({
  locations = [],
  activeLocation,
  setActiveLocation,
  setMapCenter,
}) => {
  const [sortByDistance, setSortByDistance] = useState(true);
  const [sortedLocations, setSortedLocations] = useState(locations);
  const [width, height] = useWindowResize();

  useEffect(() => {
    const locationsCopy = cloneDeep(locations);
    const sorted = locationsCopy.sort((a, b) => a.distance - b.distance);
    setSortedLocations(sorted);
  }, [JSON.stringify(locations)]);

  const listRef = useRef(null);

  useEffect(() => {
    if (activeLocation?.id && activeLocation.setBy === "marker-click") {
      let index;
      if (sortByDistance) {
        index = sortedLocations.findIndex(
          (obj) => obj.id === activeLocation.id
        );
      } else {
        index = locations.findIndex((obj) => obj.id === activeLocation.id);
      }
      listRef.current.scrollToItem(index, "start");
    }
  }, [activeLocation?.id, activeLocation?.setBy]);

  const Row = ({ index, style }) => {
    let loc;
    if (sortByDistance && !!sortedLocations.length) {
      loc = sortedLocations[index];
    } else {
      loc = locations[index];
    }
    if (loc) {
      return (
        <LocationSidebarItem
          key={loc.id}
          location={loc}
          activeLocation={activeLocation}
          setActiveLocation={setActiveLocation}
          setMapCenter={setMapCenter}
          style={style}
        />
      );
    } else {
      return <></>;
    }
  };

  const setSidebarWidth = () => {
    if (width <= 880) {
      return width;
    }
    return width * 0.25;
  };

  return (
    <StyledLocationsSidebar>
      <div className="list-meta">
        <Typography variant="h6">
          Search Results <small>({locations.length} results)</small>
        </Typography>
        <FormControl className="sort-type-toggle">
          <Typography variant="body1">sort by: </Typography>
          <FormLabel className={`${sortByDistance && "bold"}`}>
            Distance
          </FormLabel>
          <Switch
            onChange={() => setSortByDistance((prev) => !prev)}
            size="small"
          />
          <FormLabel className={`${!sortByDistance && "bold"}`}>
            Business Name
          </FormLabel>
        </FormControl>
      </div>
      {!!sortedLocations && (
        <List
          ref={listRef}
          height={height - 350}
          itemCount={locations?.length}
          itemSize={150}
          width={setSidebarWidth}
        >
          {Row}
        </List>
      )}
    </StyledLocationsSidebar>
  );
};

export default React.memo(LocationsSidebar);
