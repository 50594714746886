import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useGetTags } from "global/hooks/useGetTags";
import DefaultBtn from "global/DefaultBtn";

const Sidebar = ({
  search,
  searchedName,
  setSearchedName,
  selectedTags,
  setSelectedTags,
}) => {
  const { tags, loading } = useGetTags([]);

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    search(searchedName, selectedTags);
  };

  const handleTagChange = (e) => {
    if (e.target.value === "all") {
      setSelectedTags([]);
    } else if (!e.target.checked) {
      const newTags = [...selectedTags];
      const indexOf = newTags.indexOf(parseInt(e.target.value));
      newTags.splice(indexOf, 1);
      setSelectedTags(newTags);
    } else {
      setSelectedTags([...selectedTags, parseInt(e.target.value)]);
    }
  };

  const handleAutocompleteChange = (e, newValues) => {
    const newTags = newValues.map((tag) => tag.id);
    setSelectedTags(newTags);
  };

  return (
    <StyledSidebar>
      <form onSubmit={handleSearch}>
        <TextField
          className="search-bar"
          autoFocus
          variant="outlined"
          label="Search by business name"
          value={searchedName}
          onChange={(e) => setSearchedName(e.target.value)}
        />
        <FormGroup className="tags-group" onChange={handleTagChange}>
          <FormControlLabel
            className="tag-checkbox"
            control={
              <Checkbox
                name="All"
                defaultChecked
                value="all"
                checked={!selectedTags.length}
              />
            }
            label="All"
          />
          {loading && "Loading Categories..."}
          {!!tags.length &&
            tags.map((tag) => (
              <FormControlLabel
                key={tag.id}
                className="tag-checkbox"
                control={
                  <Checkbox
                    name={tag.title}
                    value={tag.id}
                    checked={selectedTags.indexOf(tag.id) >= 0}
                  />
                }
                label={tag.title}
              />
            ))}
        </FormGroup>
        {!!tags.length && (
          <Autocomplete
            multiple
            autoComplete
            autoHighlight
            className="mobile-tags-selector"
            name="businessType"
            options={tags}
            getOptionLabel={(tag) => tag.title}
            filterSelectedOptions
            fullWidth
            loading={loading}
            onChange={handleAutocompleteChange}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Business Type"
                fullWidth
              />
            )}
          />
        )}
        <DefaultBtn
          className="search-btn"
          onClick={handleSearch}
          variant="contained"
          color="primary"
        >
          SEARCH
        </DefaultBtn>
      </form>
    </StyledSidebar>
  );
};

const StyledSidebar = styled.div`
  width: 22.5%;
  min-width: 300px;
  height: 100%;
  margin: 0 15px;

  form {
    height: 100%;
  }

  .search-bar {
    width: 100%;
  }

  .tags-group {
    margin: 40px 0;
    padding: 10px 25px;
    height: calc(100% - 188px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-shadow: 0 -10px 10px -10px rgba(50, 50, 50, 0.2) inset;
  }

  .search-btn {
    width: 100%;
  }

  .mobile-tags-selector {
    display: none;
    margin: 15px 0;
  }

  @media (max-width: 990px) {
    width: calc(100% - 30px);
    height: auto;
    margin-bottom: 15px;

    .tags-group {
      display: none;
    }

    .mobile-tags-selector {
      display: block;
    }
  }
`;

export default Sidebar;
