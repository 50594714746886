import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

import Container from "styles/Container";

const TermsPage = () => {
  return (
    <StyledPage>
      <Typography variant="h2">Terms and Conditions</Typography>
      <Typography variant="body1">
        The following is a list of guidelines for ad submissions into all AMAC
        media. Each advertisement will be individually assessed for approval.{" "}
      </Typography>
      <ul>
        <li>
          All advertisements must comply with the letter of the law and
          government regulations and to the standards of the Association of
          Mature American Citizens (AMAC).
        </li>
        <li>
          Company Financials: Company must be sound and in good credit standing.
        </li>
        <li>Company must maintain an excellent customer service history.</li>
        <li>
          Advertisers may not use the AMAC logo or URL without prior written
          permission.
        </li>
      </ul>

      <Typography variant="h5">Acceptable Advertisements</Typography>
      <Typography variant="body1">
        The following ad categories will require in-depth evaluation and may be
        asked to provide additional information:
      </Typography>

      <ul>
        <li>All insurance products</li>
        <li>Financial investments/products</li>
        <li>Business opportunities</li>
        <li>Pharmaceuticals</li>
        <li>Holistic and vitamin supplements</li>
      </ul>

      <Typography variant="h5">
        Unacceptable Advertising Subject Matter
      </Typography>

      <ul>
        <li>Political ads</li>
        <li>Ads with sexual content</li>
        <li>
          Claim to Cure: AMAC will not publish ads that claim to cure without
          scientific data substantiating such claims.
        </li>
        <li>Personal ads</li>
      </ul>

      <Typography variant="h5">Terms and Conditions</Typography>

      <ul>
        <li>
          Liability for errors in advertisements shall not exceed the cost of
          the space occupied by the error.
        </li>
        <li>
          Advertisers shall notify us of any error in time for correction before
          insertion or ad will be considered acceptable.
        </li>
      </ul>

      <Typography variant="body1">
        All advertising accepted by AMAC is subject to approval. We shall have
        the right to revise or reject, in whole or in part, any advertisement.
        AMAC, Inc. reserves the right to terminate advertising placement at any
        time.
      </Typography>
    </StyledPage>
  );
};

const StyledPage = styled(Container)`
  padding-top: 50px;
  padding-bottom: 50px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }
`;

export default TermsPage;
