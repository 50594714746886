import styled from "styled-components";
import Popper from "@material-ui/core/Popper";

export const StyledMapPage = styled.div`
  padding: 40px 0;

  .map-row {
    display: flex;
    padding: 0 24px;
    height: calc(100vh - 300px);

    .map-container {
      flex: 1;
      height: 100%;
      margin-left: 20px;

      > div {
        height: 100%;
      }
    }

    @media (max-width: 880px) {
      flex-direction: column;
      height: unset;
      padding: 0;

      .map-container {
        height: 50vh;
        margin: 0;
        z-index: 10;
      }
    }
  }
`;

export const StyledSearchBar = styled.form`
  width: 90%;
  margin: 0 auto 24px;
  display: flex;
  align-items: center;
  background: #e0e7fd;
  border-radius: 4px;

  > div {
    flex: 1;
    border-right: 1px solid #1a2f54;
    padding: 0 12px;
    margin: 12px auto;

    &:last-child {
      border-right: none;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  input,
  select {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(26, 47, 84, 0.2);
  }

  .zip-input {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(26, 47, 84, 0.2);

    input {
      box-shadow: none;
      border-radius: 0;
      padding: 10.5px 0;
    }

    .MuiInputAdornment-root {
      cursor: pointer;
      transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .MuiInputAdornment-root:hover {
      transform: scale(1.2);
      color: #469ce8;
    }
  }

  .name-zip-input-section {
    flex: 3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .name-zip-input {
      flex: 2;
      min-width: 150px;
      margin: 6px;

      &.zip-input {
        flex: 1;
        min-width: 100px;
      }

      &.error input {
        border-bottom: 2px solid #e31937;
      }
    }

    .MuiFormHelperText-root {
      position: absolute;
      top: 90%;
      color: #e31937;
    }

    span.error {
      position: absolute;
      bottom: -12px;
      right: 30px;
      color: #e31937;
    }
  }

  .business-type-input-section {
    flex: 2;

    .MuiAutocomplete-root {
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 3px 5px rgba(26, 47, 84, 0.2);

      input {
        box-shadow: none;
      }
    }

    .business-type-input {
      width: 100%;
      height: 40px;

      .MuiInputBase-formControl {
        height: 100%;
        margin: 6px;

        select {
          padding: 11px 14px;
        }
      }
    }
  }

  .radius-input-section {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .radius-group {
      flex: 1;
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 3px 5px rgba(26, 47, 84, 0.2);
      padding: 6px 14px;
      margin: 6px 24px 6px 6px;

      position: relative;

      h6 {
        margin-right: 15px;
        font-size: 16px;
      }

      p {
        font-size: 0.6rem;
        position: absolute;
        right: 12px;
        bottom: 3px;
      }
    }
  }

  @media (max-width: 880px) {
    flex-direction: column;

    & > div {
      width: 100%;
      border-right: none;
      margin: 3px auto;

      .MuiAutocomplete-root {
        margin: 0 6px;
        width: unset;
      }
    }
  }

  @media (max-width: 375px) {
    .radius-group {
      flex-direction: column;
      margin-right: 10px !important;

      h6 {
        margin-top: 5px;
        line-height: 1;
      }

      .MuiSlider-root {
        padding: 12px 0 16px;
      }
    }
  }
`;

export const StyledLocationsSidebar = styled.div`
  width: 25%;
  background: #fff;

  h6 {
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    small {
      text-transform: lowercase;
      font-size: 12px;
    }
  }

  .sort-type-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-right: 15px;
      font-size: 12px;
    }

    .bold {
      color: #1a2f54;
      font-weight: bold;
    }

    .MuiSwitch-thumb,
    .MuiSwitch-track {
      color: #469ce8;
      background-color: #469ce8;
    }
  }

  .locations-list {
    padding-right: 20px;
    list-style: none;
    padding: 0;
    height: calc(100% - 39px);
    overflow-y: scroll;
  }

  .no-results {
    padding: 16px;
    background: #f4f7fe;
  }

  @media (max-width: 880px) {
    width: 100%;
    order: 2;

    .list-meta {
      padding: 24px 10px;
      z-index: 10;
      background: #fff;
      position: relative;
    }

    .locations-list {
      max-height: 50vh;
      margin-top: 0;
    }
  }
`;

export const StyledPopper = styled(Popper)`
  ul a:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  .tooltip-icon {
    position: absolute;
    left: -24px;
    font-size: 3em;
    color: #fff;
    top: 6px;
    filter: drop-shadow(-2px 1px 1px rgba(0, 0, 0, 0.1));
  }
`;

export const StyledLocationItem = styled.div`
	overflow: hidden;
	background: #f4f7fe;
	margin-right: 20px;
	padding: 16px;
	border-radius: 4px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	.location-data-container {
		flex: 1;
	}

	.icon-container {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
	}

	.distance {
		margin-top: -10px;
	}

	a {
		color: rgba(0, 0, 0, 0.54);
		text-decoration: none;
	}

	h4 {
		font-weight: 800;
		font-size: 16px;
	}

	p {
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 12px;
	}

	h5 {
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 700;
		font-size: 12px;
		margin-top: 10px;
	}

	ul {
		padding-left: 24px;
	}

	h5,
	a {
		transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}

	.discount-item {
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 12px;
		a {
			color: #1a2f54;
			text-decoration: none;
		}
	}

	&.active {
		background: #469ce8;
		color: #fff !important;

		h5,
		a,
		.center-map-btn {
			color: #fff;
		}
	}

	@media (max-width: 880px) {
		margin-right: 0;
	}
`;
