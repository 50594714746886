import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useUser } from 'global/context/UserContext'

import { pageVariants, pageTransitions, pageStyles } from 'utils/pageTransitions'

// Use this component instead of standard <Route /> for routes only available to active agents

const AgentRouteController = ({ component: Component, ...rest }) => {
	const { agent } = useUser()

	const isLoggedIn = agent && agent.status === 'active'
	return (
		<Route
			{...rest}
			render={props =>
				isLoggedIn ? (
					<motion.div
						initial='initial'
						animate='animate'
						exit='exit'
						variants={pageVariants}
						transition={pageTransitions}
						style={pageStyles}
					>
						<Component {...props} />
					</motion.div>
				) : (
					<motion.div
						initial='initial'
						animate='animate'
						exit='exit'
						variants={pageVariants}
						transition={pageTransitions}
						style={pageStyles}
					>
						<Redirect
							to={{
								pathname: '/agent-access',
								state: { from: props.location },
							}}
						/>
					</motion.div>
				)
			}
		/>
	)
}

export default AgentRouteController
