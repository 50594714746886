import styled from "styled-components";

export const StyledPage = styled.div`
  padding: 60px 0;
  background: #f4f7fe;
  border-bottom: 1px solid rgba(50, 50, 50, 0.2);

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  .search-form {
    display: flex;
    margin-bottom: 25px;

    .MuiTextField-root {
      margin-right: 15px;
      background: #fff;
    }

    button,
    a {
      margin: 0 5px;
      color: #fff;
    }

    .status-group {
      height: 35px;
      margin-top: auto;

      button {
        margin: 0;
        color: #444;

        p {
          font-size: 12px;
        }
      }
    }
  }

  .business-list {
    .business-list-item {
      background: #fff;
      padding: 25px 0;
      border-bottom: 1px solid #f0f0f0;
      transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      .business-logo {
        max-width: 75%;
        max-height: 100px;
      }

      .name {
        text-align: left;

        .business-status {
          opacity: 0.5;
        }
      }

      .links {
        opacity: 0;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &:hover {
        background: #fafafa;
        .links {
          opacity: 1;
        }
      }
    }
  }

  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    p {
      margin-right: 20px;
    }
  }

  .MuiCircularProgress-svg {
    color: #fff;
  }
`;
