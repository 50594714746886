import React from 'react'
import { motion } from 'framer-motion'

import HeroSection from './sections/HeroSection'
import IconSection from './sections/IconSection'
import BusinessOwnerSection from './sections/BusinessOwnerSection'

const UserLanding = () => {
	return (
		<>
			<HeroSection />
			<IconSection />
			<BusinessOwnerSection />
		</>
	)
}

export default UserLanding
