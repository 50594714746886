import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import LoadingSpinner from "global/LoadingSpinner";
import { useUser } from "global/context/UserContext";
import { StyledMapPage } from "./styles";
import Map from "global/Map";
import { useToastMessage } from "global/context/ToastMessageContext";
import { useWindowResize } from "utils/hooks/useWindowResize";
import SearchBar from "./components/SearchBar";
import LocationsSidebar from "./components/LocationsSidebar";
import useUrlParams from "utils/hooks/useUrlParams";

const MainMap = () => {
  const [width] = useWindowResize();
  const history = useHistory();

  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (width >= 880) {
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden";
    }
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, [width]);

  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(4)
  const [radius, setRadius] = useState(25);
  const [locations, setLocations] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeLocation, setActiveLocation] = useState(null);

  const urlParams = useUrlParams();

  const [searchTerms, setSearchTerms] = useState({
    distance: urlParams.get("radius") || 25,
    address: urlParams.get("zip") || "",
    name: urlParams.get("name") || "",
    tags: urlParams.get("tags") ? urlParams.get("tags").split(",") : [],
  });

  const { showToastMessage } = useToastMessage();

  const { userLocation, getUserLocation } = useUser();

  // default lat and lng makes dev easier
  const [coords, setCoords] = useState(
    userLocation?.coords
      ? {
          lat: userLocation.coords.latitude,
          lng: userLocation.coords.longitude,
        }
      : {}
  );

  useEffect(() => {
    getUserLocation();
  }, []);

  useEffect(() => {
    if (!!searchTerms.address || !!userLocation?.coords) {
      if (!!userLocation?.coords) {
        setSearchTerms((prevTerms) => {
          return {
            ...prevTerms,
            userCoords: {
              lat: userLocation.coords.latitude,
              lng: userLocation.coords.longitude,
            },
          };
        });
      }

      const getLocations = async () => {
        setLoading(true);
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/location/search`,
            searchTerms
          );


          if (data && data.locations?.length < 1) {
            showToastMessage({
              type: "error",
              message:
                "No Results. Try broadening your search or searching a different area!",
            });
            setCoords(data.coords ? data.coords : {});
            setLocations([]);
          } else {
            setCoords(data.coords);
            setZoom(10)
            setLocations(data.locations);
          }
          setLoading(false);
        } catch (err) {
          showToastMessage({
            type: "error",
            message: "Error! Please try searching again.",
          });
          setLoading(false);
        }
      };

      getLocations();
    }
  }, [JSON.stringify(searchTerms)]);

  const setMapCenter = (location, zoom) => {
    if (zoom) {
      setZoom(zoom)
    }
    setCoords({ lat: location.lat, lng: location.lng });
  };

  const search = (searchData) => {
    setLoading(true);
    const tagIds = selectedTags.map((tag) => tag.id);
    const { businessName, businessZip } = searchData;

    let searchParams = "";
    searchParams += businessZip ? `zip=${businessZip}` : "";
    searchParams += businessName ? `&name=${businessName}` : "";
    searchParams += radius ? `&radius=${radius}` : "";
    searchParams += tagIds.length ? `&tags=${tagIds.join(",")}` : "";

    history.push({
      pathname: "/map/",
      search: `?${searchParams}`,
    });

    setSearchTerms((prevTerms) => {
      return {
        ...prevTerms,
        distance: radius,
        address: businessZip || "",
        name: businessName || "",
        tags: tagIds.length ? tagIds : [],
      };
    });
  };

  const setActiveLocationId = useCallback(
    (id, setBy) => {
      setActiveLocation({id, setBy});
    },
    [setActiveLocation]
  );

  return (
    <StyledMapPage>
      <LoadingSpinner loading={loading} wait="0" />
      <SearchBar
        search={search}
        radius={radius}
        setRadius={setRadius}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        searchTerms={searchTerms}
      />

      <div className="map-row">
        <LocationsSidebar
          locations={locations}
          activeLocation={activeLocation}
          setActiveLocation={setActiveLocationId}
          setMapCenter={setMapCenter}
        />
        <div className="map-container">
          <Map
            markers={locations}
            height="100%"
            width="100%"
            zoom={zoom}
            coords={coords}
            activeLocation={activeLocation}
            setActiveLocation={setActiveLocationId}
            hideSearch
          />
        </div>
      </div>
    </StyledMapPage>
  );
};

export default MainMap;
