import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useIsPresent, motion } from "framer-motion";

import {
  pageVariants,
  pageTransitions,
  pageStyles,
} from "utils/pageTransitions";

const TransitionRoute = ({ children, ...rest }) => {
  const isPresent = useIsPresent();
  useEffect(() => {
    const scrollToTop = () => {
      if (isPresent) {
        window.scrollTo(0, 0);
      }
    };
    return () => {
      scrollToTop();
    };
  }, []);

  return (
    <Route {...rest}>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
        transition={pageTransitions}
        style={pageStyles}
      >
        {children}
      </motion.div>
    </Route>
  );
};

export default TransitionRoute;
