import React from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

const ElevateOnScroll = ({ children }) => {
	// Trigger will return true once scrolling passes threshold
	const trigger = useScrollTrigger({
		disableHysteresis: false,
		threshold: 0,
	})

	// Add elevation to children elements when triggered
	return React.cloneElement(children, {
		className: trigger ? 'scrolling' : null,
	})
}

export default ElevateOnScroll
