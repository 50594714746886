import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Typography,
  TextField,
  Select,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  FormGroup,
  IconButton,
  Paper,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import AddIcon from "@material-ui/icons/Add";
import { useForm, Controller } from "react-hook-form";
import { useCookies } from "react-cookie";

import { useToastMessage } from "global/context/ToastMessageContext";
import { StyledFields } from "styles/FormStyles";
import states from "utils/stateAbbr";
import TagsSelector from "./TagsSelector";
import DefaultBtn from "global/DefaultBtn";
import NewDiscountsField from "./NewDiscountsFields";
import CurrentDiscount from "./CurrentDiscount";
import { useBusinessObjectsCrud } from "../hooks/useBusinessObjectsCrud";
import useSubmitBusiness from "utils/hooks/useSubmitBusiness";
import referralOptions from 'utils/referralOptions';


const BusinessSection = forwardRef(
  (
    {
      getBusiness,
      currentBusiness,
      setCurrentBusiness,
      setLoading,
      show,
      saveBusiness,
      clientFacing,
    },
    ref
  ) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [discounts, setDiscounts] = useState( [] );
    const [otherReferral, setOtherReferral] = useState(currentBusiness.referral && referralOptions.indexOf(currentBusiness.referral) === -1)
    const [logo, setLogo] = useState({});
    const [cookies] = useCookies(["jwt"]);
    const { jwt } = useParams();

    const handleReferral = e => {
      if (e.target.value === 'other' || otherReferral) {
        setOtherReferral(e.target.value === 'other');
      }
    };

    const { submit } = useSubmitBusiness({
      currentBusiness,
      logo,
      discounts,
      selectedTags,
      setLoading,
      jwt: cookies?.jwt ? cookies.jwt : jwt,
    });

    const { control, register, errors, handleSubmit } = useForm({
      mode: "onTouched",
    });
    const logoInput = useRef(null);
    const defaultEmptyCoupon = {
      type: "discount",
      file: "",
      text: "",
    };

    const history = useHistory();
    const { showToastMessage } = useToastMessage();

    const handleBusinessSubmit = async (data) => {
      const status = clientFacing ? "active" : null;
      const result = await submit(data, status);

      if (result.status === 200) {
        showToastMessage({
          type: "success",
          message: "Business Successfully saved!",
        });
        if (clientFacing) {
          history.push(`/business/${currentBusiness.id}`);
        } else {
          console.log("result: ", result);
          getBusiness(
            currentBusiness.id ? currentBusiness.id : result.data.business.id
          );
        }
      } else {
        showToastMessage({
          type: "error",
          message: result.message,
        });
      }
    };

    useEffect(() => {
      setSelectedTags(currentBusiness.tags || []);
    }, [currentBusiness?.tags?.length]);

    const getLogo = (e) => {
      const files = e.target.files;
      if (files && files.length) {
        setLogo(files[0]);
      }
    };

    const clearLogo = () => {
      setLogo({});
    };

    const {
      addNew: addDiscount,
      editCurrent: editDiscount,
      removeNew: removeDiscount,
      deleteCurrent: deleteDiscount,
    } = useBusinessObjectsCrud({
      currentData: currentBusiness,
      setCurrentData: setCurrentBusiness,
      newData: discounts,
      setNewData: setDiscounts,
      defaultObject: defaultEmptyCoupon,
      jwt: cookies?.jwt,
    });

    return (
      <StyledFields className="business-information fields-group" show={show}>
        <form onSubmit={handleSubmit(handleBusinessSubmit)} ref={ref}>
          <div className="row">
            <div className="left">
              <Typography variant="h5" className="header-with-switch">
                BUSINESS INFORMATION
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      defaultChecked={!!currentBusiness.isRemote}
                    />
                  }
                  name="isRemote"
                  inputRef={register}
                  label="Online Business"
                />
              </Typography>
              <FormGroup className="outer-form-group">
                <TextField
                  variant="outlined"
                  label="Business Name"
                  name="businessName"
                  defaultValue={currentBusiness.name}
                  inputRef={register({ required: true })}
                  helperText={
                    errors.businessName ? 'This is a required field' : null
                  }
                />
                <TextField
                  variant="outlined"
                  label="EIN"
                  name="ein"
                  inputRef={register}
                  defaultValue={currentBusiness.ein}
                />
                {(currentBusiness.logo || logo.name) && (
                  <div className="logo-row">
                    {currentBusiness.logo && (
                      <div className="current-logo">
                        <Typography variant="h6">Current Logo</Typography>
                        <img
                          className="logo-thumbnail"
                          src={`${process.env.REACT_APP_S3_URL}${currentBusiness.logo}`}
                          alt="Current Logo"
                        />
                      </div>
                    )}
                    {logo.name && (
                      <div className="new-logo">
                        <Typography variant="h6">New Logo</Typography>
                        <img
                          className="logo-thumbnail"
                          src={window.URL.createObjectURL(logo)}
                          alt="New Logo"
                        />
                        <IconButton
                          className="clear-picture"
                          onClick={() => {
                            logoInput.current.value = '';
                            clearLogo();
                          }}
                          title="Remove New Logo"
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                )}
                <FormControl variant="outlined" error={errors.logo}>
                  <InputLabel htmlFor="logo">Logo</InputLabel>
                  <OutlinedInput
                    variant="outlined"
                    type="file"
                    name="logo"
                    id="logo-input"
                    label="Logo"
                    inputProps={{ accept: '.jpg, .png, .gif, .pdf' }}
                    onChange={getLogo}
                    endAdornment={
                      <IconButton
                        onClick={() => {
                          logoInput.current.value = '';
                          clearLogo();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                    inputRef={logoInput}
                  />
                  {errors.logo && (
                    <FormHelperText>
                      There was an error in this field.
                    </FormHelperText>
                  )}
                </FormControl>
                <TextField
                  variant="outlined"
                  label="Website"
                  name="website"
                  inputRef={register}
                  defaultValue={currentBusiness.website}
                />
                <TextField
                  variant="outlined"
                  label="Business Description"
                  multiline
                  rows={3}
                  rowsMax={8}
                  name="businessDescription"
                  inputRef={register}
                  defaultValue={currentBusiness.description}
                />
                <Typography variant="h5">AMAC MEMBER?</Typography>
                <TextField
                  variant="outlined"
                  label="AMAC Member Id"
                  name="amacMemberId"
                  inputRef={register}
                  defaultValue={currentBusiness.amacMemberId}
                />
              </FormGroup>

              <Typography variant="h5">Referral Source</Typography>
              <FormGroup className="outer-form-group">
                <FormControl
                  variant="outlined"
                  className="referral"
                  error={!!errors.referral}
                >
                  <Controller
                    control={control}
                    name="referral"
                    render={() => (
                      <Select
                        inputRef={register}
                        name="referral"
                        onChange={handleReferral}
                        defaultValue={
                          !currentBusiness.referral
                            ? ''
                            : referralOptions.indexOf(currentBusiness.referral) === -1
                            ? 'other'
                            : currentBusiness.referral
                        }
                        native
                      >
                        <option value=""></option>
                        {referralOptions &&
                          referralOptions.map(option => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        <option value="other">Other...</option>
                      </Select>
                    )}
                  />
                </FormControl>

                {otherReferral && (
                  <TextField
                    className={errors.otherReferral && 'form-error'}
                    variant="outlined"
                    label="Tell us how you heard about us *"
                    name="otherReferral"
                    defaultValue={
                      referralOptions.indexOf(currentBusiness.referral) === -1
                        ? currentBusiness.referral.replace('other - ', '')
                        : ''
                    }
                    inputRef={register({
                      required: true,
                      maxLength: 255,
                      minLength: 5,
                    })}
                    helperText={
                      errors.otherReferral?.type === 'required'
                        ? 'This is a required field'
                        : errors.otherReferral &&
                          'Referral must be between 5 and 255 characters'
                    }
                    size="small"
                  />
                )}
              </FormGroup>
            </div>
            <div className="right">
              <Typography variant="h5">BUSINESS MAILING ADDRESS</Typography>
              <FormGroup className="outer-form-group">
                <TextField
                  variant="outlined"
                  label="Address"
                  name="businessAddress"
                  inputRef={register({ required: true })}
                  helperText={
                    errors.businessAddress ? 'This field is required' : null
                  }
                  defaultValue={currentBusiness.streetAddress1}
                />
                <TextField
                  variant="outlined"
                  label="Address line 2 (optional)"
                  name="businessAddress2"
                  inputRef={register}
                  helperText={
                    errors.businessAddress2 ? 'This field is required' : null
                  }
                  defaultValue={currentBusiness.streetAddress2}
                />
                <div className="field-row">
                  <TextField
                    className="city"
                    variant="outlined"
                    label="City"
                    name="businessCity"
                    inputRef={register({ required: true })}
                    helperText={
                      errors.businessCity ? 'This field is required' : null
                    }
                    defaultValue={currentBusiness.city}
                  />
                  <FormControl
                    variant="outlined"
                    className="state"
                    error={errors.businessState}
                  >
                    <InputLabel htmlFor="state-select">State</InputLabel>
                    <Controller
                      as={Select}
                      control={control}
                      label="State"
                      name="businessState"
                      inputRef={register({ required: true })}
                      defaultValue={
                        currentBusiness?.state ? currentBusiness.state : ''
                      }
                      native
                    >
                      <option value=""></option>
                      {states &&
                        states.map(state => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                    </Controller>
                    {errors.businessState && (
                      <FormHelperText className="phone-error">
                        This field is required
                      </FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    className="zip"
                    variant="outlined"
                    label="Zip Code"
                    name="businessZip"
                    inputRef={register({
                      required: true,
                      minLength: 5,
                      maxLength: 5,
                    })}
                    inputProps={{ maxLength: 5 }}
                    helperText={
                      errors.businessZip?.type === 'required'
                        ? 'This is a required field'
                        : errors.businessZip
                        ? 'Please enter a valid zip code'
                        : null
                    }
                    defaultValue={currentBusiness.zip}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="left">
              <Typography variant="h5">BUSINESS CATEGORIES</Typography>
              <TagsSelector
                defaultTags={currentBusiness.tags}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
              />
            </div>
            <div className="right">
              <Typography variant="h5">DISCOUNTS</Typography>
              <div className="discount-list">
                <Paper>
                  <ul className="current-discounts">
                    {!!currentBusiness.discounts?.length &&
                      currentBusiness.discounts.map((discount, index) => (
                        <CurrentDiscount
                          key={discount.id}
                          discount={discount}
                          editDiscount={editDiscount}
                          deleteDiscount={deleteDiscount}
                          index={index}
                        />
                      ))}
                  </ul>
                </Paper>
                {!!discounts.length &&
                  discounts.map((discount, index) => (
                    <NewDiscountsField
                      key={discount.id ? discount.id : discount.key}
                      register={register}
                      errors={errors}
                      discount={discount}
                      discounts={discounts}
                      setDiscounts={setDiscounts}
                      deleteDiscount={deleteDiscount}
                      removeDiscount={removeDiscount}
                      index={index}
                    />
                  ))}
                <DefaultBtn
                  variant="contained"
                  color="secondary"
                  onClick={addDiscount}
                  endIcon={<AddIcon />}
                  size="small"
                >
                  Add Discount
                </DefaultBtn>
              </div>
            </div>
          </div>
        </form>
      </StyledFields>
    );
  }
);

export default BusinessSection;
