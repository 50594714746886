import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Tabs, Tab, Paper, Backdrop } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/BusinessCenterOutlined';
import LocationIcon from '@material-ui/icons/PinDropOutlined';
import RepsIcon from '@material-ui/icons/FaceOutlined';
import EmailIcon from '@material-ui/icons/AlternateEmailOutlined';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import MoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { StyledFormPage } from 'styles/FormStyles';
import Container from 'styles/Container';
import BusinessSection from './components/BusinessSection';
import LocationsSection from './components/LocationsSection';
import RepsSection from './components/RepsSection';
import DefaultBtn from 'global/DefaultBtn';
import BusinessPaymentModal from 'global/BusinessPaymentModal';
import DeleteBusinessConfirm from 'global/DeleteBusinessConfirm';
import TransactionsList from '../../global/TransactionsList';
import SubscriptionData from 'global/SubscriptionData';
import WelcomeEmailModal from './components/WelcomeEmailModal';
import ActivateBusinessModal from './components/ActivateBusinessModal';
import LoadingSpinner from 'global/LoadingSpinner';

const BusinessAdmin = () => {
  const businessFormRef = useRef();
  const [loading, setLoading] = useState(false);
  const [fieldsDisplayed, setFieldsDisplayed] = useState(0);
  const [currentBusiness, setCurrentBusiness] = useState({});

  const [openModal, setOpenModal] = useState({
    open: false,
  });

  const [cookies] = useCookies(['jwt']);
  let { businessId } = useParams();

  const getBusiness = id => {
    const businessById = id ? id : businessId;
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/business/${businessById}`, {
        params: { noLocations: true },
        headers: { 'x-auth-token': cookies?.jwt },
      })
      .then(business => {
        if (business) {
          setCurrentBusiness(business.data);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log('error: ', err.response?.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (businessId) {
      getBusiness(businessId);
    }
  }, [businessId]);

  const handleFieldChange = (e, newValue) => {
    setFieldsDisplayed(newValue);
  };

  const closeModal = () => {
    setOpenModal({ open: false });
  };

  const handleBusinessSubmit = () => {
    if (!currentBusiness?.transactions?.length) {
      setOpenModal({ open: true, name: 'payment' });
    } else if (businessFormRef.current) {
      businessFormRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  };

  const handleNext = () => {
    // submit business form to create business so that locations and reps have a business ID to save to
    if (!currentBusiness.id && fieldsDisplayed === 0) {
      businessFormRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
    setFieldsDisplayed(fieldsDisplayed + 1);
  };

  console.log('currentBusiness: ', currentBusiness);

  return (
    <StyledFormPage>
      <LoadingSpinner loading={loading} />
      <Container className="page-container">
        <Typography variant="h2">
          {businessId ? 'Edit Business' : 'Create New Business'}
        </Typography>
        <div className="admin-btns">
          {currentBusiness.id && currentBusiness.status !== 'active' && (
            <DefaultBtn
              color="secondary"
              variant="contained"
              onClick={() => setOpenModal({ open: true, name: 'welcome' })}
              startIcon={<EmailIcon />}
              size="small"
            >
              Send Welcome Email
            </DefaultBtn>
          )}
          <DefaultBtn
            color="primary"
            variant="contained"
            onClick={() => setOpenModal({ open: true, name: 'payment' })}
            startIcon={<MoneyIcon />}
            size="small"
          >
            Make a Payment
          </DefaultBtn>
          {currentBusiness.status !== 'active' &&
            !!currentBusiness?.transactions?.length && (
              <DefaultBtn
                color="secondary"
                variant="contained"
                onClick={() => setOpenModal({ open: true, name: 'activate' })}
                startIcon={<CheckIcon />}
                size="small"
              >
                Activate Business
              </DefaultBtn>
            )}
          <DeleteBusinessConfirm businessId={currentBusiness?.id} />
        </div>
        <SubscriptionData currentBusiness={currentBusiness} />
        {!!currentBusiness?.transactions?.length && (
          <TransactionsList transactions={currentBusiness.transactions} />
        )}
        <Paper>
          <Tabs
            value={fieldsDisplayed}
            onChange={handleFieldChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab icon={<BusinessIcon />} label="Business" />
            <Tab icon={<LocationIcon />} label="Locations" />
            <Tab icon={<RepsIcon />} label="Representatives" />
          </Tabs>
        </Paper>
        <BusinessSection
          key={currentBusiness.id} // needed to rerender form fields once default vals populate
          getBusiness={getBusiness}
          setLoading={setLoading}
          show={fieldsDisplayed === 0}
          currentBusiness={currentBusiness}
          setCurrentBusiness={setCurrentBusiness}
          ref={businessFormRef}
        />
        <LocationsSection
          setLoading={setLoading}
          show={fieldsDisplayed === 1}
          businessId={currentBusiness?.id}
        />
        <RepsSection
          currentBusiness={currentBusiness}
          getBusiness={getBusiness}
          setLoading={setLoading}
          show={fieldsDisplayed === 2}
        />
        <div className="button-row">
          <DefaultBtn
            className="next-btn"
            color="primary"
            variant="outlined"
            startIcon={<ChevronLeft />}
            disabled={fieldsDisplayed === 0}
            onClick={() => setFieldsDisplayed(fieldsDisplayed - 1)}
          >
            Prev Section
          </DefaultBtn>
          <DefaultBtn
            className="submit-btn"
            color="primary"
            variant="contained"
            onClick={handleBusinessSubmit}
          >
            {!!currentBusiness?.transactions?.length ? 'Save' : 'Make Payment'}
          </DefaultBtn>
          <DefaultBtn
            className="next-btn"
            color="primary"
            variant="outlined"
            endIcon={<ChevronRight />}
            disabled={fieldsDisplayed === 2}
            onClick={handleNext}
          >
            Next Section
          </DefaultBtn>
        </div>
      </Container>
      <Backdrop open={openModal.open} style={{ zIndex: 1200 }}>
        {openModal.open && openModal.name == 'payment' && (
          <BusinessPaymentModal
            closeModal={closeModal}
            business={currentBusiness}
            onPaymentComplete={() => {
              getBusiness(currentBusiness.id);
              closeModal();
            }}
          />
        )}
        {openModal.open && openModal.name == 'welcome' && (
          <WelcomeEmailModal
            businessId={currentBusiness.id}
            reps={[...currentBusiness.representatives]}
            closeModal={closeModal}
          />
        )}
        {openModal.open && openModal.name == 'activate' && (
          <ActivateBusinessModal
            businessId={currentBusiness.id}
            closeModal={closeModal}
            getBusiness={getBusiness}
          />
        )}
      </Backdrop>
    </StyledFormPage>
  );
};

export default BusinessAdmin;
