import React, { useRef } from 'react'

import HeroSection from './sections/HeroSection'
import PointsSection from './sections/PointsSection'
import ApplicationSection from './sections/ApplicationSection'
import scrollToRef from 'utils/scrollToRef'

const BusinessLanding = () => {
	const formRef = useRef(null)

	const scrollToForm = () => {
		scrollToRef(formRef, 50)
	}
	return (
		<>
		<HeroSection scrollToForm={scrollToForm} />
		<PointsSection />
		<ApplicationSection formRef={formRef} />
		</>
	);
}

export default BusinessLanding
