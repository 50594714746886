import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import PhoneIcon from '@material-ui/icons/PhoneIphoneOutlined'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { StyledPage, StyledPopover } from './styles'
import DefaultBtn from 'global/DefaultBtn'

const FaqPage = () => {
	const [contactAnchor, setContactAnchor] = useState(null)

	const handleClick = event => {
		setContactAnchor(event.currentTarget)
	}

	const handleClose = () => {
		setContactAnchor(null)
	}
	return (
			<StyledPage>
				<Typography variant='h1'>FAQ's</Typography>
				<div className='accordion-container'>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
							<Typography className='faq-question'>What do I get when I list my business on the map?</Typography>
						</AccordionSummary>
						<AccordionDetails className='faq-answer'>
							<Typography>Besides showcasing your business to AMAC’s over 2.3 million members, you’ll get:</Typography>
							<ul>
								<li>A voice for small business in Washington, making sure your needs and priorities are not ignored. </li>
								<li>The support of like- minded AMAC member-customers </li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2a-content' id='panel2a-header'>
							<Typography className='faq-question'>Who will be advocating for me in Washington?</Typography>
						</AccordionSummary>
						<AccordionDetails className='faq-answer'>
							<Typography>
								AMAC Small Business Solutions (“SBS”) is the advocacy arm of AMAC and AMAC Action. AMAC SBS is dedicated
								to:
							</Typography>
							<ul>
								<li>helping you increase your profits and productivity</li>
								<li>reducing your taxes </li>
								<li>helping you comply with state and federal regulatory requirements</li>
								<li>making your voice heard in Washington </li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel3a-content' id='panel3a-header'>
							<Typography className='faq-question'>What will SBS do for me and my small business?</Typography>
						</AccordionSummary>
						<AccordionDetails className='faq-answer'>
							<Typography>
								AMAC SBS and its advocacy affiliate, AMAC Action, are strongly committed to small business. As a component
								of your AMAC membership, we will fight to protect the rights this country affords you as a business owner,
								with a special emphasis on issues pertaining to replacing Obamacare with truly affordable healthcare and
								insurance options, reducing excessive federal taxation and regulations, and recovery from the devastating
								effects of Covid-19 on small business.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel4a-content' id='panel4a-header'>
							<Typography className='faq-question'>
								I own a national business. How can I promote my products/services to AMAC’s membership at large?
							</Typography>
						</AccordionSummary>
						<AccordionDetails className='faq-answer'>
							<Typography>
								If you are interested in advertising or partnership opportunities,{' '}
								<a href='https://amac.us/advertise' target='_blank' rel='noopener noreferrer'>
									click here
								</a>{' '}
								for our media kit and contact information.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel5a-content' id='panel5a-header'>
							<Typography className='faq-question'>How can I get involved in supporting small business?</Typography>
						</AccordionSummary>
						<AccordionDetails className='faq-answer'>
							<Typography>
								If you are interested in becoming an advocate for small businesses,{' '}
								<a href='https://amacaction.org/#getting-involved' target='_blank' rel='noopener noreferrer'>
									click here
								</a>{' '}
								for more information.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel6a-content' id='panel6a-header'>
							<Typography className='faq-question'>
								How do I learn about AMAC’s advocacy actions on behalf of small business?
							</Typography>
						</AccordionSummary>
						<AccordionDetails className='faq-answer'>
							<Typography>
								<a href='https://amac.us/category/advocacy/advocating-for-you' target='_blank' rel='noopener noreferrer'>
									Click here
								</a>{' '}
								for more information about what we're doing to help. You may also wish to contact us by email at{' '}
								<a href='mailto:map@amac.us'>map@amac.us</a> and we will respond promptly by email or telephone. Lastly,
								our AMAC website is updated several times daily on AMAC and AMAC Action advocacy and other information
								relevant our membership, in addition to our daily and weekly updates.
							</Typography>
						</AccordionDetails>
					</Accordion>
				</div>
				<DefaultBtn variant='contained' color='secondary' onClick={handleClick} className='more-info-btn'>
					Question not answered?
				</DefaultBtn>
				<StyledPopover
					id='contact-popover'
					open={!!contactAnchor}
					anchorEl={contactAnchor}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					transformOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
					<Typography variant='h4' color='secondary'>
						Get In Touch!
					</Typography>
					<div className='button-row'>
						<DefaultBtn variant='outlined' color='primary' startIcon={<PhoneIcon />} href='tel:800-876-6196'>
							800-876-6196
						</DefaultBtn>
						<DefaultBtn variant='outlined' color='primary' startIcon={<EmailIcon />} href='mailto:map@amac.us'>
							map@amac.us
						</DefaultBtn>
					</div>
				</StyledPopover>
			</StyledPage>
	)
}

export default FaqPage
