import { createMuiTheme } from '@material-ui/core/styles'

export const muiTheme = createMuiTheme({
	typography: {
		fontFamily: ['Mulish', 'Source Sans Pro', 'sans-serif'].join(','),
	},
	palette: {
		blue: {
			light: '70,156,232',
			medium: '0,83,155',
			dark: '26,47,84',
		},
		primary: {
			main: '#00539b',
			contrastText: '#fff',
		},
		secondary: {
			main: '#469ce8',
			contrastText: '#fff',
		},
		info: {
			main: '#e31937',
			contrastText: '#fff',
		},
		text: {
			primary: '#1a2f54',
		},
		background: {
			default: '#fff',
		},
	},
})
