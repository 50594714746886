import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import axios from 'axios'

function not(a, b) {
	return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
	return a.filter(value => b.indexOf(value) !== -1)
}

const TagsSelector = ({ defaultTags, selectedTags = [], setSelectedTags }) => {
	const [checked, setChecked] = useState([])
	const [unselectedTags, setUnselectedTags] = useState([])

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_ENDPOINT}/tag/`)
			.then(response => {
				if (response.data.length) {
					if (defaultTags && defaultTags.length) {
						const filteredTags = response.data.filter(tag => defaultTags.findIndex(sTag => sTag.id === tag.id) < 0)
						setUnselectedTags(filteredTags)
					} else {
						setUnselectedTags(response.data)
					}
				}
			})
			.catch(err => console.log('error: ', err.response?.data))
	}, [defaultTags])

	const leftChecked = intersection(checked, unselectedTags)
	const rightChecked = intersection(checked, selectedTags)

	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	const handleCheckedRight = () => {
		setSelectedTags(selectedTags.concat(leftChecked))
		setUnselectedTags(not(unselectedTags, leftChecked))
		setChecked(not(checked, leftChecked))
	}

	const handleCheckedLeft = () => {
		setUnselectedTags(unselectedTags.concat(rightChecked))
		setSelectedTags(not(selectedTags, rightChecked))
		setChecked(not(checked, rightChecked))
	}

	const customList = (items, selected) => (
		<StyledPaper>
			<div className='category-header'>
				<Typography variant='subtitle1'>{selected ? 'Selected Categories' : 'Available Categories'}</Typography>
			</div>
			<List dense component='div' role='list'>
				{items.length || selected
					? items.map(tag => {
							const labelId = `transfer-list-item-${tag.title}-label`

							return (
								<ListItem key={tag.id} role='listitem' button onClick={handleToggle(tag)}>
									<ListItemIcon>
										<Checkbox
											checked={checked.indexOf(tag) !== -1}
											tabIndex={-1}
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={tag.title} />
								</ListItem>
							)
					  })
					: 'Loading Categories...'}
				<ListItem />
			</List>
		</StyledPaper>
	)

	return (
		<Grid className='category-container' container spacing={2} justify='center' alignItems='center'>
			<StyledTagContainer item>{customList(unselectedTags)}</StyledTagContainer>
			<Grid item>
				<Grid container direction='column' alignItems='center'>
					<StyledButton
						variant='outlined'
						onClick={handleCheckedRight}
						disabled={leftChecked.length === 0}
						aria-label='move selected right'
					>
						<ChevronRight />
					</StyledButton>
					<StyledButton
						variant='outlined'
						onClick={handleCheckedLeft}
						disabled={rightChecked.length === 0}
						aria-label='move selected left'
					>
						<ChevronLeft />
					</StyledButton>
				</Grid>
			</Grid>
			<StyledTagContainer item>{selectedTags && customList(selectedTags, true)}</StyledTagContainer>
		</Grid>
	)
}

const StyledTagContainer = styled(Grid)`
	width: 43%;
	min-width: 150px;
	height: 330px;
`
const StyledPaper = styled(Paper)`
	width: 100%;
	height: 100%;
	overflow: auto;

	.category-header {
		padding: 15px 5px;
		text-align: center;
		border-bottom: 1px solid rgba(50, 50, 50, 0.1);
		position: sticky;
		top: 0;
		z-index: 2;
		background: #fff;
	}
`

const StyledButton = styled(Button)`
	margin: 10px auto;
`

export default TagsSelector
