import React from "react";
import CenterIcon from "@material-ui/icons/CenterFocusStrongOutlined";
import ExternalLink from "@material-ui/icons/OpenInNewOutlined";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import Hours from "./Hours";
import formatPhone from "utils/formatPhone";

const LocationDetail = ({
  activeLocation,
  location,
  setActiveLocation,
  setMapCenter,
}) => {
  let normalizedWebsite = "";
  if (location.locationUrl) {
    normalizedWebsite =
      location.locationUrl.indexOf("http") !== -1
        ? location.locationUrl
        : `http://${location.locationUrl}`;
  }

  return (
    <div
      id={`location-${location.id}`}
      className={`location-description ${
        activeLocation?.id === location.id ? "active" : ""
      }`}
      key={location.id}
      onMouseEnter={() => setActiveLocation(location.id, 'detail')}
    >
      <div className="location-description-text">
        <Typography variant="h6">{location.locationName}</Typography>
        {location.phone && (
          <Link href={`tel:${location.phone}`}>
            {formatPhone(location.phone)}
          </Link>
        )}
        {location.streetAddress1 && (
          <Typography variant="body1">{location.streetAddress1}</Typography>
        )}
        {location.streetAddress2 && (
          <Typography variant="body1">{location.streetAddress2}</Typography>
        )}
        <Typography variant="body1">
          {location.city}, {location.state} {location.zip}
        </Typography>
        {location.hours && <Hours hours={location.hours} />}
      </div>
      <div className="location-description-links">
        <IconButton
          className="center-map-btn"
          title="Center map on this location"
          onClick={(e) => {
            e.stopPropagation();
            setMapCenter(location);
          }}
        >
          <CenterIcon />
        </IconButton>
        {location.locationUrl && (
          <IconButton
            className="location-url-btn"
            title="Visit Location Website"
            href={normalizedWebsite}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExternalLink />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default React.memo(LocationDetail);
