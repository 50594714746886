import React, { useState } from "react";
import EditIcon from "@material-ui/icons/EditOutlined";
import TrashIcon from "@material-ui/icons/DeleteForeverOutlined";
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from "@material-ui/icons/ZoomInOutlined";
import ZoomOutIcon from "@material-ui/icons/ZoomOutOutlined";

const CurrentDiscount = ({ discount, index, editDiscount, deleteDiscount }) => {
  const [magnifyImage, toggleMagnifyImage] = useState(false);

  return (
    <li className="current-discount-item">
      <div className={`left ${magnifyImage ? "magnify" : null}`}>
        <div className="coupon-image">
          <ZoomInIcon className="zoom-in" />
          <ZoomOutIcon className="zoom-out" />
          <img
            src={`${process.env.REACT_APP_S3_URL}${discount.file}`}
            alt=""
            onClick={() => toggleMagnifyImage(!magnifyImage)}
          />
        </div>
        <span>{discount.text}</span>
      </div>
      <div className="right">
        <IconButton color="secondary" onClick={() => editDiscount(index)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => deleteDiscount('discount', discount.id)}>
          <TrashIcon />
        </IconButton>
      </div>
    </li>
  );
};

export default CurrentDiscount;
