import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/material.css";
import AddIcon from "@material-ui/icons/AddOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

import { useToastMessage } from "global/context/ToastMessageContext";
import CurrentLocations from "./CurrentLocations";
import { StyledLocations } from "styles/FormStyles";
import DefaultBtn from "global/DefaultBtn";
import LocationModal from "./LocationModal";

import { usePagination } from "utils/hooks/usePagination";
import { useBusinessObjectsCrud } from "../hooks/useBusinessObjectsCrud";
import axios from "axios";

const LocationsSection = ({ setLoading, show, businessId, clientFacing }) => {
  const [locations, setLocations] = useState([]);
  const [cookies] = useCookies(["jwt"]);
  const [locationBeingEdited, setLocationBeingEdited] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { currentPage, setCurrentPage, handlePageChange } = usePagination();
  const { jwt: jwtParam } = useParams();

  const { showToastMessage } = useToastMessage();

  const getLocations = (defaultPage) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/location/${businessId}`, {
        params: { page: defaultPage ? defaultPage : currentPage },
        headers: { "x-auth-token": cookies?.jwt ? cookies.jwt : jwtParam },
      })
      .then((res) => {
        setLocations(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error: ", err.message);
        showToastMessage({
          type: "error",
          message: "Error retrieving locations, please try reloading the page.",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (show) {
      if (searchSubmitted) {
        handleSearch();
      } else {
        getLocations();
      }
    }
  }, [businessId, currentPage, show]);

  const handleSubmitLocation = async (locationData) => {
    let result;
    result = await addOrUpdateLocation(locationData, businessId);

    if (result === "success") {
      handleCloseModal();
      getLocations();
    }
  };

  const handleCloseModal = () => {
    setLocationBeingEdited({});
    setModalOpen(false);
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }

    // if no search query, just do standard get all locations query
    if (!searchQuery.length) {
      setSearchSubmitted(false);
      setCurrentPage(1);
      return getLocations(1);
    }
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_ENDPOINT}/location/search/${businessId}`,
        { query: searchQuery, page: !searchSubmitted ? 1 : currentPage },
        {
          headers: { "x-auth-token": cookies?.jwt },
        }
      )
      .then((res) => {
        if (!searchSubmitted) {
          setSearchSubmitted(true);
        }
        setLocations(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error: ", err.message);
        showToastMessage({
          type: "error",
          message: "Error retrieving locations, please try reloading the page.",
        });
        setLoading(false);
      });
  };

  const {
    deleteCurrent: deleteLocation,
    addOrUpdateLocation,
  } = useBusinessObjectsCrud({
    callback: handleSearch,
    jwt: cookies?.jwt ? cookies.jwt : jwtParam,
  });

  return (
    <StyledLocations className="location-information fields-group" show={show}>
      <div className="title-row">
        {!!locations?.count && (
          <Typography variant="h5">Current Locations</Typography>
        )}
        {locations?.count > 10 && (
          <Pagination
            count={Math.ceil(locations.count / 10)}
            page={
              currentPage > Math.ceil(locations.count / 10) ? 1 : currentPage
            }
            onChange={handlePageChange}
          />
        )}
      </div>
      {locations?.count > 10 && (
        <div className="search-row">
          <form onSubmit={handleSearch}>
            <TextField
              variant="outlined"
              label="Search"
              placeholder="Search by location name or city"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
            />
            <DefaultBtn
              color="primary"
              variant="contained"
              size="small"
              type="submit"
            >
              Search
            </DefaultBtn>
          </form>
        </div>
      )}

      {!!locations?.count && (
        <CurrentLocations
          currentLocations={locations.rows}
          deleteLocation={deleteLocation}
          setLocationBeingEdited={setLocationBeingEdited}
          setModalOpen={setModalOpen}
        />
      )}
      {locations?.count > 10 && (
        <Pagination
          count={Math.ceil(locations.count / 10)}
          page={currentPage > Math.ceil(locations.count / 10) ? 1 : currentPage}
          onChange={handlePageChange}
        />
      )}
      <DefaultBtn
        variant="contained"
        color="secondary"
        onClick={() => setModalOpen(true)}
        endIcon={<AddIcon />}
        size="small"
      >
        Add Location
      </DefaultBtn>
      {modalOpen && (
        <LocationModal
          open={modalOpen}
          handleClose={handleCloseModal}
          location={locationBeingEdited}
          handleSubmitLocation={handleSubmitLocation}
        />
      )}
    </StyledLocations>
  );
};

export default LocationsSection;
