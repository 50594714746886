import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

const DefaultBtn = ({ textColor, backgroundColor, children, ...attrs }) => {
	return (
		<StyledButton textcolor={textColor} backgroundcolor={backgroundColor} {...attrs}>
			{children}
		</StyledButton>
	)
}

const StyledButton = styled(Button)`
	font-size: ${({ size }) => (size === 'small' ? '16px' : '23px')};
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: bold;
	letter-spacing: 1px;
	width: ${props => (props.width ? props.width : null)};
	height: ${props => (props.height ? props.height : null)};
	text-transform: unset;
	border-radius: ${props => (props.squared ? 0 : props.circle ? '50%' : null)};
	color: ${props => (props.textcolor ? props.textcolor : null)};
	background: ${props => (props.backgroundcolor ? props.backgroundcolor : null)};
	display: flex;
	align-items: center;
	text-align: center;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	&:hover {
		background: ${({ backgroundcolor }) => (backgroundcolor ? backgroundcolor : null)};
		opacity: ${({ backgroundcolor }) => (backgroundcolor ? 0.8 : null)};
	}

	svg {
		font-size: 26px !important;
		margin-bottom: ${({ size }) => (size === 'small' ? null : '-3px')};
	}
`

export default DefaultBtn
