export const products = [
  {
    id: 1,
    title: 'First Year Business Listing',
    label: '1st Year Listing - Promotion',
    price: 99,
    years: 1,
    type: 'standard',
  },
  {
    id: 2,
    title: 'Regional/National Business Listing',
    label: 'Regional/National Business (10+ locations)',
    price: 500,
    years: 1,
    type: 'standard',
  },
  {
    id: 3,
    title: 'Local to Regional/National Upgrade',
    label: 'Local to Regional/National upgrade difference',
    price: 401,
    years: 0,
    type: 'upgrade',
  },
  {
    id: 4,
    title: 'Discounted Regional/National',
    label: 'Discounted Regional/National',
    price: 250,
    years: 1,
    type: 'standard',
  },
  {
    id: 5,
    title: 'Local to Discounted Regional/National Upgrade',
    label: 'Local to Discounted Regional/National upgrade difference',
    price: 151,
    years: 0,
    type: 'upgrade',
  },
  {
    id: 6,
    title: 'Regional/National Business 1 YEAR RENEWAL',
    label: 'Regional/National Business 1 YEAR RENEWAL',
    price: 500,
    years: 1,
    type: 'renewal',
  },
  {
    id: 7,
    title: 'Discounted Regional/National 1 YEAR RENEWAL',
    label: 'Discounted Regional/National 1 YEAR RENEWAL',
    price: 250,
    years: 1,
    type: 'renewal',
  },
  {
    id: 8,
    title: '1 Year Business Listing RENEWAL',
    label: '1 Year Business Listing RENEWAL',
    price: 79,
    years: 1,
    type: 'renewal',
  },
];
