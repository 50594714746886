import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider as MuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { CookiesProvider } from "react-cookie";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { muiTheme } from "./styles/theme";
import { UserProvider } from "global/context/UserContext";
import { ToastMessageProvider } from "global/context/ToastMessageContext";
import Routes from "./Routes";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  return (
    <CookiesProvider>
      <MuiTheme theme={muiTheme}>
        <CssBaseline />
        <Router>
          <ToastMessageProvider>
            <UserProvider>
              <Elements stripe={stripePromise}>
                <Routes />
              </Elements>
            </UserProvider>
          </ToastMessageProvider>
        </Router>
      </MuiTheme>
    </CookiesProvider>
  );
}

export default App;
