import React from 'react'

const LocationDetail = () => {
    return (
        <div>
            <p>Single Location</p>
        </div>
    )
}

export default LocationDetail
