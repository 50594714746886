import axios from 'axios'
import generateRandomString from '../generateRandomString'

const uploadImage = async (imageFile, currentImageKey, jwt) => {
	const contentType = imageFile.type
	const imageId = currentImageKey ? currentImageKey : generateRandomString(16)

	const generatePutUrl = `${process.env.REACT_APP_ENDPOINT}/aws/generate-put-url`
	const options = {
		params: {
			Key: imageId,
			ContentType: contentType,
		},
		headers: {
			'Content-Type': contentType,
			'x-auth-token': jwt,
		},
	}

	let result = null
	let error = null

	try {
		let putUrlResult = await axios.get(generatePutUrl, options)
		if (putUrlResult.data.putURL) {
			let uploadResult = await axios.put(putUrlResult.data.putURL, imageFile, options)

			if (uploadResult.status === 200) {
				result = imageId
			} else {
				error = `Image upload failed with status code: ${uploadResult.status}`
			}
		}
	} catch (error) {
		error = error.message
	}

	return { result, error }
}

export default uploadImage
