export const pageVariants = {
    initial: {
        opacity: 0,
        y: '-75px'
    },
    animate: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        y: '-75px'
    }
}

export const pageTransitions = {
    duration: .25
}

export const pageStyles = {
}