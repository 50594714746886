import React from "react";
import EditIcon from "@material-ui/icons/EditOutlined";
import TrashIcon from "@material-ui/icons/DeleteForeverOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

const CurrentLocations = ({
  currentLocations,
  deleteLocation,
  setLocationBeingEdited,
  setModalOpen,
}) => {
  return (
    <div className="current-locations">
      <Paper>
        <ul>
          {!!currentLocations?.length &&
            currentLocations.map((location) => (
              <li className="current-location-item" key={location.id}>
                <div className="left">
                  <Typography variant="h6">{location.locationName}</Typography>
                  <Typography variant="body1">
                    Status: <i>{location.status}</i>
                  </Typography>
                </div>

                <div className="middle">
                  {location.streetAddress1 && (
                    <Typography variant="body1">
                      {location.streetAddress1}
                    </Typography>
                  )}
                  {location.streetAddress2 && (
                    <Typography variant="body1">
                      {location.streetAddress2}
                    </Typography>
                  )}
                  <Typography variant="body1">
                    {location.city}, {location.state} {location.zip}
                  </Typography>
                  <Typography variant="body1">{location.phone}</Typography>
                </div>

                <div className="right">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setLocationBeingEdited(location);
                      setModalOpen(true);
                    }}
                    title="Edit Current Location"
                  >
                    <EditIcon />
                  </IconButton>
                  {location.status !== "deleted" && (
                    <IconButton
                      onClick={() => deleteLocation("location", location.id)}
                      title="Delete Current Location"
                    >
                      <TrashIcon />
                    </IconButton>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </Paper>
    </div>
  );
};

export default CurrentLocations;
