import React, { useState } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Collapse from '@material-ui/core/Collapse'
import ChevronDown from '@material-ui/icons/KeyboardArrowDownOutlined'
import moment from 'moment'

import generateKey from 'utils/generateRandomString'

const Hours = ({ hours }) => {
	const [showHours, toggleHours] = useState(false)
	return (
		<HoursSection className='hours-section'>
			<Link onClick={() => toggleHours(!showHours)}>
				Hours <ChevronDown className={showHours ? 'upside-down' : null} />
			</Link>
			<Collapse in={showHours} unmountOnExit>
				{hours.split(',').map(day => {
					const dayArray = day.split('-')
					return (
						<div className='hour-row' key={generateKey()}>
							<Typography variant='body2'>{dayArray[0]}: </Typography>
							{dayArray[1] === 'Closed' ? (
								<Typography variant='body2'>Closed</Typography>
							) : (
								<Typography variant='body2'>
									{moment(dayArray[1], 'HH:mm').format('h:mm a')} - {moment(dayArray[2], 'HH:mm').format('h:mm a')}
								</Typography>
							)}
						</div>
					)
				})}
			</Collapse>
		</HoursSection>
	)
}

const HoursSection = styled.div`
	overflow: hidden;

	a {
		display: flex;
		align-items: center;
		cursor: pointer;

		svg {
			transition: all 0.2s linear;

			&.upside-down {
				transform: rotate(180deg);
			}
		}
	}

	.hour-row {
		display: flex;
		justify-content: space-between;
	}
`

export default Hours
