import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'

import Container from 'styles/Container'
import storeFront from 'images/storefront.png'

export const StyledHeroContent = styled(Container)`
	width: 100%;
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	padding: 104px 50px;
	text-align: center;

	h5.with-background {
		font-weight: 200;
		font-size: 60px;
		line-height: 70px;
		margin-bottom: 36px;
		padding: 10px;
		background: rgba(26, 47, 84, 0.8);
	}

	h3 {
		font-weight: 800;
		font-size: 48px;
		line-height: 58px;
		margin-bottom: 36px;
	}

	.MuiTypography-body1 {
		font-size: 25px;
		line-height: 35px;
		margin-bottom: 48px;
		font-weight: 600;
	}

	.input-row {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 75%;
		min-width: 500px;

		button {
			box-shadow: 0px 5px 10px rgba(26, 47, 84, 0.3);
		}

		.MuiInputAdornment-root {
			cursor: pointer;
			transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputAdornment-root:hover {
			transform: scale(1.2);
			color: #469ce8;
		}

		@media (max-width: 767px) {
			width: 100%;
			min-width: 100px;
		}
	}

	@media (max-width: 767px) {
		padding: 48px 5%;

		h3 {
			font-size: 28px;
			line-height: 38px;
		}

		h5.with-background {
			font-size: 42px;
			line-height: 52px;
		}

		.MuiTypography-body1 {
			font-size: 18px;
			line-height: 28px;
		}
	}

	@media (max-width: 425px) {
		h3 {
			font-size: 24px;
			line-height: 34px;
		}

		h5.with-background {
			font-size: 32px;
			line-height: 42px;
		}

		.MuiTypography-body1 {
			font-size: 16px;
			line-height: 26px;
		}

		.input-row {
			flex-direction: column;

			button {
				width: 100%;
				margin-top: 24px;
			}
		}
	}
`

export const StyledFormControl = styled(FormControl)`
	flex: 1;
	margin-right: 24px;
	position: relative;
	box-shadow: 0px 5px 10px rgba(26, 47, 84, 0.3);

	.o-input {
		background: #fff;
		font-size: 16px;
		font-family: 'Source Sans Pro', sans-serif;
		border-radius: 6px;

		&.input-error fieldset {
			border-color: red;
			border-style: inset;

			& input {
				outline: none;
			}
		}
	}

	.zip-input {
		border-radius: 6px;

		svg {
			color: rgba(50, 50, 50, 0.6);
		}
	}

	span.error {
		position: absolute;
		color: #fff;
		top: 105%;
	}

	@media (max-width: 425px) {
		margin-right: 0;
	}
`

export const StyledIconSection = styled.div`
	padding: 120px 0;

	h2 {
		font-size: 60px;
		line-height: 70px;
		text-align: center;
		margin-bottom: 100px;
	}

	.icons-section {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -25px;
	}

	@media (max-width: 767px) {
		padding: 60px 2%;

		h2 {
			font-size: 48px;
			line-height: 58px;
			margin-bottom: 48px;
		}

		.icons-section {
			justify-content: space-around;
		}
	}

	@media (max-width: 425px) {
		h2 {
			font-size: 32px;
			line-height: 42px;
		}
	}
`

export const StyledBusinessOwnerSection = styled.div`
	background-image: url(${storeFront});
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: cover;

	.description-container {
		padding: 60px 2% 172px;

		.inner-container {
			width: 50%;
			min-width: 300px;
		}

		h3 {
			font-weight: 800;
			font-size: 48px;
			line-height: 58px;
			margin-bottom: 24px;
		}

		p {
			font-family: 'Source Sans Pro', sans-serif;
			font-size: 30px;
			line-height: 40px;
			margin-bottom: 48px;
		}

		a {
			box-shadow: 0px 5px 10px rgba(26, 47, 84, 0.3);
		}
	}

	.mobile-store-img-container {
		display: none;
		background-image: url(${storeFront});
		background-position: right bottom;
		background-repeat: no-repeat;
		background-size: cover;
	}

	@media (max-width: 1200px) {
		.description-container {
			padding-bottom: 50px;
		}
	}

	@media (max-width: 1000px) {
		background: none;

		.mobile-store-img-container {
			display: block;
			height: 250px;
			margin: 0 -2% -25px;
		}

		.description-container {
			padding: 25px 2%;

			.inner-container {
				width: 100%;
				margin-bottom: -75px;
				padding: 0 5%;
			}
		}
	}

	@media (max-width: 767px) {
		.description-container .inner-container {
			margin-bottom: -50px;
			h3 {
				font-size: 28px;
				line-height: 38px;
			}

			p {
				font-size: 18px;
				line-height: 28px;
				margin-bottom: 24px;
			}
		}
	}
`
