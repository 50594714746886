import React, { useState } from 'react';
import { Typography, Tabs, Tab, Paper, Backdrop } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/BusinessCenterOutlined';
import RepsIcon from '@material-ui/icons/FaceOutlined';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ProblemIcon from '@material-ui/icons/ReportProblemOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';

import { StyledApplicationSection } from '../styles';
import createBusiness from 'utils/api/createBusinessFromApplication';
import BusinessFields from '../components/BusinessFields';
import ContactFields from '../components/ContactFields';
import DefaultBtn from 'global/DefaultBtn';
import scrollToRef from 'utils/scrollToRef';
import BusinessPaymentModal from 'global/BusinessPaymentModal';

const ApplicationSection = ({ formRef }) => {
  const [fieldsDisplayed, setFieldsDisplayed] = useState(0);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [newBusiness, setNewBusiness] = useState(null);
  const [paymentModalOpen, togglePaymentModalOpen] = useState(false);

  const { control, register, errors, handleSubmit } = useForm({
    mode: 'onTouched',
  });

  const handleFieldChange = (e, newValue) => {
    setFieldsDisplayed(newValue);
    scrollToRef(formRef, 50);
  };

  const submit = data => {
    setSubmitStatus('pending');

    if (data.otherReferral) {
      data.referral = data.otherReferral;
      delete data.otherReferral;
    }

    createBusiness(data)
      .then(response => {
        if (response.status === 200) {
          setNewBusiness(response.data.business);
          togglePaymentModalOpen(true);
          setSubmitStatus('');
        } else {
          setSubmitStatus('failed');
        }
      })
      .catch(err => {
        setSubmitStatus('failed');
        console.log('error: ', err.response?.data);
      });
  };

  return (
    <StyledApplicationSection ref={formRef}>
      <div className="form-container">
        {submitStatus === 'success' ? (
          <div className="success-message">
            <Typography variant="h2">Success!</Typography>
            <Typography variant="body1">
              Thank you for your interest in the AMAC Discount Map. An AMAC
              representative will be reaching out shortly.
            </Typography>
          </div>
        ) : (
          <>
            <Paper>
              <Tabs
                value={fieldsDisplayed}
                onChange={handleFieldChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  icon={
                    Object.keys(errors).some(
                      k => k.indexOf('business') >= 0 || k === 'referral'
                    ) ? (
                      <ProblemIcon color="error" />
                    ) : (
                      <BusinessIcon />
                    )
                  }
                  label="Business Info"
                />
                <Tab
                  icon={
                    Object.keys(errors).some(k => k.indexOf('rep') >= 0) ? (
                      <ProblemIcon color="error" />
                    ) : (
                      <RepsIcon />
                    )
                  }
                  label="Contact Person's Info"
                />
              </Tabs>
            </Paper>

            <form onSubmit={handleSubmit(submit)}>
              <BusinessFields
                control={control}
                register={register}
                errors={errors}
                show={fieldsDisplayed === 0}
              />
              <ContactFields
                control={control}
                register={register}
                errors={errors}
                show={fieldsDisplayed === 1}
              />
              <div className="button-row">
                {fieldsDisplayed === 1 ? (
                  <>
                    <div>
                      <DefaultBtn
                        className="next-btn"
                        color="primary"
                        variant="outlined"
                        width="100px"
                        startIcon={<ChevronLeft />}
                        disabled={fieldsDisplayed === 0}
                        onClick={() =>
                          handleFieldChange('', fieldsDisplayed - 1)
                        }
                      >
                        Prev
                      </DefaultBtn>
                    </div>
                    {!newBusiness ? (
                      <DefaultBtn
                        className="submit-btn"
                        type="submit"
                        color="primary"
                        variant="contained"
                      >
                        <span className="app">Save &</span> Checkout
                      </DefaultBtn>
                    ) : (
                      <DefaultBtn
                        className="submit-btn"
                        color="primary"
                        variant="contained"
                        onClick={() => togglePaymentModalOpen(true)}
                      >
                        <span className="app">Save &</span> Checkout
                      </DefaultBtn>
                    )}
                    {submitStatus === 'pending' && (
                      <CircularProgress color="secondary" />
                    )}
                  </>
                ) : (
                  <>
                    <div />
                    <div />
                  </>
                )}
                {fieldsDisplayed === 0 ? (
                  <div>
                    <DefaultBtn
                      className="next-btn"
                      color="primary"
                      variant="outlined"
                      endIcon={<ChevronRight />}
                      disabled={fieldsDisplayed === 2}
                      onClick={() => handleFieldChange('', fieldsDisplayed + 1)}
                    >
                      Next
                    </DefaultBtn>
                  </div>
                ) : (
                  <div />
                )}
              </div>
              {submitStatus === 'failed' && (
                <div className="error-message">
                  <Typography variant="body1">
                    Sorry! There was a problem with your submission. Please try
                    again later.
                  </Typography>
                </div>
              )}
              {Object.keys(errors).length > 0 && (
                <div className="error-message">
                  <Typography variant="body1">
                    There was a problem with one or more of the form fields,
                    please check the form for error messages.
                  </Typography>
                </div>
              )}
            </form>
          </>
        )}
      </div>
      <Backdrop open={paymentModalOpen} style={{ zIndex: 1200 }}>
        {paymentModalOpen && (
          <BusinessPaymentModal
            closeModal={() => togglePaymentModalOpen(false)}
            business={newBusiness}
            onPaymentComplete={() => setSubmitStatus('success')}
            clientFacing
          />
        )}
      </Backdrop>
    </StyledApplicationSection>
  );
};

export default ApplicationSection;
