import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import { StyledSection } from "../styles";
import StoreFront from "images/storefront-small.png";
import MobileMap from "images/mobile-over-map.png";
import ImageRow from "global/ImageRow";
import DefaultBtn from "global/DefaultBtn";

const PointsSection = () => {
  return (
    <StyledSection>
      <ImageRow image={StoreFront}>
        <Typography variant="h4" color="primary">
          Special Rate for Our Member Business Owners
        </Typography>
        <Typography variant="body1">
          AMAC is committed to helping small businesses grow and prosper.  In
          these challenging times, it is especially important to recognize
          entrepreneurs as the core of our country’s economy.  That is why we
          are offering <u> direct-to-member advertising</u> to AMAC small
          business owners at a{" "}
          <b>
            low first year annual rate of only{" "}
            <span className="big-text">$99</span>.
          </b>
        </Typography>
        <Typography variant="h4" color="primary" className="second-header">
          Have an Online Business?
        </Typography>
        <Typography variant="body1">
          You can still get your business in front of over 2.3 million members
          by being listed on our{" "}
          <Link to="/online-businesses" className="inline-link">
            Online/National Businesses Page
          </Link>
          .
        </Typography>
      </ImageRow>
      <ImageRow image={MobileMap} reverse>
        <Typography variant="h3" color="primary">
          There's More...
        </Typography>
        <Typography variant="body1">
          Not only will you be reaching customers who WANT to buy from fellow
          AMAC members, you’ll also be represented in Washington and in the
          media by an organization who gives a voice to small business in our
          country - and will make sure you are not ignored!
        </Typography>
        <DefaultBtn
          variant="contained"
          color="secondary"
          component={Link}
          to="/faq"
        >
          Need more information?
        </DefaultBtn>
      </ImageRow>
    </StyledSection>
  );
};

export default PointsSection;
