import React from "react";
import Typography from "@material-ui/core/Typography";

const Instructions = ({ fieldsDisplayed }) => {
  return (
    <div className="instructions">
      {fieldsDisplayed === 0 && (
        <>
          <Typography variant="body2">
            The <b>BUSINESS</b> section is where you'll want your business name
            and "headquarters" mailing address (
            <i>will not be displayed on the map</i>) along with information that
            will show on your business's page including:{" "}
          </Typography>
          <ul>
            <li>Your logo</li>
            <li>Website URL</li>
            <li>Business description</li>
            <li>The categories your business fits into</li>
            <li>
              The discounts your business is offering. This will be a digital
              coupon file (jpg, png, or pdf) that will be downloadable. The text
              input will be what the link displays.
            </li>
          </ul>
        </>
      )}
      {fieldsDisplayed === 1 && (
        <>
          <Typography variant="body2">
            The <b>LOCATIONS</b> section is where you'll add your physical
            locations along with each location's information. Click the "Add
            Location" button to add locations. The location information will be
            displayed on the map and in the search results. The information that
            should be entered is:
          </Typography>
          <ul>
            <li>Location Name</li>
            <li>Location phone number</li>
            <li>Physical Address</li>
            <li>
              Location URL (use if each location has their own website, not
              required)
            </li>
            <li>Hours of Operation (leave empty to not show hours)</li>
          </ul>
        </>
      )}
      {fieldsDisplayed === 2 && (
        <>
          <Typography variant="body2">
            The <b>REPRESENTATIVES</b> section is where you'll add any
            additional people on your team that you'd like to give the ability
            to make changes to your listing or receive correspondence from us.
            Click the "Add Rep" button to add more representatives. We'll need
            their:
          </Typography>
          <ul>
            <li>Name</li>
            <li>Phone number</li>
            <li>Email Address</li>
          </ul>
        </>
      )}
    </div>
  );
};

export default Instructions;
