import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  IconButton,
} from "@material-ui/core";
import CenterIcon from "@material-ui/icons/CenterFocusStrongOutlined";
import StoreIcon from '@material-ui/icons/StorefrontOutlined';

import { StyledLocationItem } from "../styles";

const LocationSidebarItem = ({
  location,
  activeLocation,
  setActiveLocation,
  setMapCenter,
  style,
}) => {


  return (
		<StyledLocationItem
			style={{
				...style,
				top: style.top + 10,
				height: style.height - 10,
			}}
			id={`location-${location.id}`}
			className={`location-item ${
				activeLocation?.id === location.id ? 'active' : ''
			}`}
			key={location.id}
			onMouseEnter={() => setActiveLocation(location.id, 'sidebar')}
		>
			<div className="location-data-container">
				<Typography variant="body2" className="distance">
					<small>(Distance: {Math.round(location.distance)} Mi.)</small>
				</Typography>
				<Typography variant="h4">{location.business.name}</Typography>
				<Typography variant="body1">{location.locationName}</Typography>
				{location.streetAddress1 && (
					<Typography variant="body1">{location.streetAddress1}</Typography>
				)}
				{location.streetAddress2 && (
					<Typography variant="body2">{location.streetAddress2}</Typography>
				)}
				<Typography variant="body1">
					{location.city}, {location.state} {location.zip}
				</Typography>
			</div>

			<div className="icon-container">
				<IconButton
					className="center-map-btn"
					title="Center map on this location"
					onClick={() => setMapCenter(location, 15)}
				>
					<CenterIcon />
				</IconButton>
				<IconButton
					component={Link}
					title="Get business details and discounts"
					to={`/business/${location.business.id}/?lat=${location.lat}&lng=${location.lng}&locationId=${location.id}`}
				>
					<StoreIcon />
				</IconButton>
			</div>
		</StyledLocationItem>
	);
};

export default React.memo(LocationSidebarItem);
