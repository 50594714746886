import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import styled from 'styled-components';
import moment from 'moment';

import Container from 'styles/Container';
import SubscriptionData from 'global/SubscriptionData';
import TransactionsList from 'global/TransactionsList';
import BusinessPaymentModal from 'global/BusinessPaymentModal';
import DefaultBtn from 'global/DefaultBtn';
import LoadingSpinner from 'global/LoadingSpinner';

import { products } from '../../utils/products';
import QuickBusinessData from '../../global/QuickBusinessData';

const BusinessRenewPage = () => {
  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [invalid, setInvalid] = useState(false);
  const [paymentModalOpen, togglePaymentModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentProcessed, setPaymentProcessed] = useState(false);

  const { id, jwt } = useParams();
  const history = useHistory();

  const { businessId, lastPaymentAmount } = jwt_decode(jwt);

  const getBusiness = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/business/${businessId}`, {
        headers: { 'x-auth-token': jwt },
      })
      .then(business => {
        if (business) {
          setCurrentBusiness(business.data);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log('error: ', err);
        setInvalid(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (businessId && parseInt(id) === parseInt(businessId)) {
      getBusiness();
    } else {
      setInvalid(true);
    }
  }, [businessId, id]);

  const getProduct = lastPayment => {
    let productId = null;
    switch (lastPayment) {
      case '99':
        productId = 8;
        break;
      case '500':
      case '401':
        productId = 6;
        break;
      case '250':
      case '151':
        productId = 7;
        break;
      default:
        productId = null;
    }

    return products.find(product => product.id === productId);
  };

  return (
    <StyledContainer>
      {paymentProcessed ? (
        <div>
          <Typography variant="h3">Thank you for Renewing!</Typography>
          <Typography
            variant="h5"
            style={{ marginBottom: '50px', textAlign: 'center' }}
          >
            Your new expiration date is:{' '}
            {moment(currentBusiness.expirationDate)
              .add(1, 'year')
              .format('MM/DD/YY')}
          </Typography>
          <DefaultBtn
            size="large"
            color="primary"
            variant="contained"
            onClick={() => history.push(`/business/${id}`)}
            style={{ margin: 'auto' }}
          >
            Go to Business
          </DefaultBtn>
        </div>
      ) : (
        <>
          <LoadingSpinner loading={isLoading} />
          <Typography variant="h3">Renew Your Listing</Typography>
          {!!currentBusiness && (
            <>
              <QuickBusinessData currentBusiness={currentBusiness} />
              <SubscriptionData currentBusiness={currentBusiness} />
              {!!currentBusiness.transactions?.length && (
                <TransactionsList transactions={currentBusiness.transactions} />
              )}
              <DefaultBtn
                color="primary"
                variant="contained"
                onClick={() => togglePaymentModalOpen(true)}
                size="large"
              >
                Renew Now - ${getProduct(lastPaymentAmount).price}
              </DefaultBtn>
            </>
          )}
          <Backdrop open={paymentModalOpen} style={{ zIndex: 1200 }}>
            {paymentModalOpen && (
              <BusinessPaymentModal
                closeModal={() => togglePaymentModalOpen(false)}
                business={currentBusiness}
                onPaymentComplete={() => setPaymentProcessed(true)}
                product={getProduct(lastPaymentAmount)}
                clientFacing
                renewal
              />
            )}
          </Backdrop>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  padding: 50px 25px 100px;

  h3 {
    margin-bottom: 30px;
  }

  h6 {
    align-self: flex-start;
  }

  .MuiFormControl-root {
    margin-bottom: 10px;
  }

  .card-element-container {
    margin-bottom: 20px;
  }

  .expiration-date-cell {
    font-weight: bold;
    font-size: 18px;
    background-color: #e3193733;
  }
`;

export default BusinessRenewPage;
