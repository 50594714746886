import React, { useState, useContext, createContext } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import jwt_decode from 'jwt-decode'

import { useToastMessage } from 'global/context/ToastMessageContext'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
	const agent = useUserProvider()
	return <UserContext.Provider value={agent}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)

const useUserProvider = () => {
	const [cookies, setCookie, removeCookie] = useCookies(['jwt'])
	const [agent, setAgent] = useState(cookies.jwt ? jwt_decode(cookies.jwt) : null)
	const [agentLoading, setAgentLoading] = useState(false)
	const { toastMessage, showToastMessage } = useToastMessage()

	const [userLocation, setUserLocation] = useState(null)
	const [userDeniedLocation, setUserDeniedLocation] = useState(false)

	const history = useHistory()

	const login = credentials => {
		const { email, password, comingFrom } = credentials
		axios
			.post(`${process.env.REACT_APP_ENDPOINT}/agent/login`, { email, password })
			.then(res => {
				if (res.status === 200) {
					setAgent(jwt_decode(res.data.token))
					// set cookie that will last 30 days
					setCookie('jwt', res.data.token, { path: '/', maxAge: 86400 * 30 })
					showToastMessage({
						type: 'success',
						message: 'Login Successful!',
					})
					history.push(comingFrom ? comingFrom : '/agent/businesses')
				}
			})
			.catch(err => {
				console.log('error: ', err.response ? err.response.data : err)
				showToastMessage({
					type: 'error',
					message: err.response ? err.response.data : 'Something went wrong, please try again',
				})
			})
	}

	const logout = () => {
		setAgent(null)
		removeCookie('jwt', { path: '/' })
		showToastMessage({
			type: 'success',
			message: 'Successfully Logged out!',
		})
		history.push('/')
	}

	const createAgent = agentData => {
		axios
			.post(`${process.env.REACT_APP_ENDPOINT}/agent/create`, agentData, { headers: { 'x-auth-token': cookies?.jwt } })
			.then(res => {
				if (res.status === 200) {
					showToastMessage({
						type: 'success',
						message: `Success! ${agentData.firstName} will receive an email with a link to set up their account.`,
					})
				}
			})
			.catch(err => {
				console.log('error: ', err.response?.data)
				showToastMessage({
					type: 'error',
					message: err.response ? err.response.data : 'Something went wrong, please try again',
				})
			})
	}

	const changePassword = credentials => {
		axios
			.post(`${process.env.REACT_APP_ENDPOINT}/agent/update-password`, credentials)
			.then(res => {
				if (res.status === 200) {
					setAgent(jwt_decode(res.data.token))
					// set cookie that will last 30 days
					setCookie('jwt', res.data.token, { path: '/', maxAge: 86400 * 30 })
					showToastMessage({
						type: 'success',
						message: 'Password successfully updated!',
					})
					history.push('/')
				}
			})
			.catch(err => {
				console.log('error: ', err.response?.data)
				showToastMessage({
					type: 'error',
					message: err.response ? err.response.data : 'Something went wrong, please try again',
				})
			})
	}

	const forgotPassword = credentials => {
		axios
			.put(`${process.env.REACT_APP_ENDPOINT}/agent/forgot-password`, credentials)
			.then(res => {
				if (res.status === 200) {
					showToastMessage({
						type: 'success',
						message: `Success! Check your email for a password reset link.`,
					})
				}
			})
			.catch(err => {
				console.log('error: ', err.response?.data)
				showToastMessage({
					type: 'error',
					message: err.response ? err.response.data : 'Something went wrong, please try again',
				})
			})
	}

	const getUserLocation = force => {
		if (!userDeniedLocation || force) {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(setUserLocation, () => {
					return showToastMessage({
						type: 'error',
						message:
							'Unable to get location, location could be blocked by your browser, please enter zip code or update browser permissions.',
					})
				})
			}
		}
	}

	const removeUserLocation = () => {
		setUserLocation(null)
		setUserDeniedLocation(true)
	}

	// if user has previously
	const userAllowLocation = () => {
		setUserDeniedLocation(false)
		getUserLocation(true)
	}

	return {
		agent,
		agentLoading,
		login,
		logout,
		createAgent,
		changePassword,
		toastMessage,
		showToastMessage,
		forgotPassword,
		getUserLocation,
		userLocation,
		removeUserLocation,
		userAllowLocation,
	}
}
