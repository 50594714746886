import React, { useEffect } from "react";
import { TextField, Slider, Typography } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useForm } from "react-hook-form";

import { useUser } from "global/context/UserContext";
import { StyledSearchBar } from "../styles";
import { useGetTags } from "global/hooks/useGetTags";
import DefaultBtn from "global/DefaultBtn";

const SearchBar = ({
  search,
  radius,
  setRadius,
  selectedTags,
  setSelectedTags,
  searchTerms,
}) => {
  const { tags, loading: tagsLoading } = useGetTags([]);
  const { register, errors, handleSubmit, setValue } = useForm();

  const { userLocation, userAllowLocation, removeUserLocation } = useUser();

  useEffect(() => {
    if (searchTerms?.tags?.length && !tagsLoading && tags?.length) {
      const newTags = tags.filter((tag) =>
				searchTerms.tags.includes(tag.id.toString())
			);

      setSelectedTags(newTags)
    }

  }, [tags?.length, tagsLoading])


  const handleRemoveLocation = () => {
    removeUserLocation();
    setValue("businessZip", "");
  };

  return (
    <StyledSearchBar onSubmit={handleSubmit(search)}>
      <div className="name-zip-input-section">
        <TextField
          variant="outlined"
          className="name-zip-input"
          name="businessName"
          type="text"
          inputRef={register}
          placeholder="Business Name"
          size="small"
          defaultValue={searchTerms.name || ""}
        />
        <OutlinedInput
          startAdornment={
            <InputAdornment
              position="start"
              onClick={userAllowLocation}
              title="Use my location"
            >
              <LocationIcon />
            </InputAdornment>
          }
          endAdornment={
            userLocation?.coords && (
              <InputAdornment
                position="end"
                onClick={handleRemoveLocation}
                title="Remove my location"
              >
                <CloseIcon />
              </InputAdornment>
            )
          }
          variant="outlined"
          className={`name-zip-input zip-input ${
            errors.businessZip ? "error" : ""
          }`}
          name="businessZip"
          type="text"
          inputRef={register({ required: true })}
          placeholder="Zip Code"
          size="small"
          defaultValue={searchTerms.address || ""}
          value={userLocation?.coords ? "Current Location" : null}
          disabled={!!userLocation?.coords}
        />
        {errors.businessZip && (
          <span className="error">A valid zip code is required to search</span>
        )}
      </div>
      <div className="business-type-input-section">
        <Autocomplete
          multiple
          autoComplete
          autoHighlight
          id="tags-outlined"
          name="businessType"
          options={tags}
          getOptionLabel={(tag) => tag.title}
          filterSelectedOptions
          fullWidth
          loading={tagsLoading}
          onChange={(e, newValues) => setSelectedTags(newValues)}
          value={selectedTags}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Business Type"
              fullWidth
            />
          )}
        />
      </div>
      <div className="radius-input-section">
        <div className="radius-group">
          <Typography variant="h6">Radius</Typography>
          <Slider
            className="radius-input"
            defaultValue={25}
            value={radius}
            step={5}
            name="businessRadius"
            valueLabelDisplay="auto"
            min={10}
            max={100}
            onChange={(e, newValue) => setRadius(newValue)}
          />
          <Typography variant="body1">(miles)</Typography>
        </div>
        <DefaultBtn
          variant="contained"
          color="secondary"
          height="40px"
          type="submit"
        >
          Search
        </DefaultBtn>
      </div>
    </StyledSearchBar>
  );
};

export default SearchBar;
