import styled from "styled-components";

export const PageRow = styled.div`
  display: flex;
  padding: 60px 0;

  .admin-btn {
    position: fixed;
    top: 150px;
    right: 7%;
    z-index: 2;
    background: rgb(227, 25, 55);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background: rgb(156, 17, 38);
    }
  }

  .left {
    flex: 1;
    padding-right: 18px;

    .business-logo {
      max-height: 175px;
      max-width: 100%;
      margin-bottom: 35px;
    }

    h2 {
      font-size: 48px;
      line-height: 58px;
      font-weight: 800;
      margin-bottom: 24px;
    }

    .business-description {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 48px;
    }

    h4 {
      color: #e31937;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 12px;
    }

    .discount-section {
      margin-bottom: 48px;

      a {
        cursor: pointer;
      }
    }

    .business-information {
      display: flex;
      margin-bottom: 50px;

      .info-point {
        flex: 1;

        h5 {
          font-size: 14px;
          line-height: 24px;
          text-transform: uppercase;
          font-family: "Source Sans Pro", sans-serif;
        }
      }
    }
    .button-row {
      display: flex;

      a {
        margin-right: 30px;
      }
    }
  }

  .right {
    flex: 1;
    padding-left: 18px;

    .location-info {
      padding: 30px 0 0;
      background: #f4f7fe;

      .row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px 35px;
      }

      h5 {
        font-size: 18px;
        line-height: 28px;
        font-weight: 300;
      }

      h6 {
        font-size: 18px;
        line-height: 28px;
        font-weight: 800;
        padding-right: 40px;
      }

      .locations-list {
        display: flex;
        flex-wrap: wrap;
        min-height: 250px;
        max-height: 300px;
        overflow-y: scroll;
        padding: 0 35px;
        box-shadow: -2px -8px 2px -4px inset rgba(0, 0, 0, 0.1);

        .location-description {
          display: flex;
          justify-content: space-between;
          flex: 1;
          border-radius: 4px;
          padding: 15px;
          min-width: 230px;
          height: fit-content;
          transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          .location-description-text {
            flex: 1;
          }

          .location-description-links {
            display: flex;
            flex-direction: column;
          }

          &.active {
            background: #469ce8;
            color: #fff;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
              0px 2px 2px 0px rgba(0, 0, 0, 0.14),
              0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          }
        }

        .MuiTypography-body1,
        .MuiTypography-body2 {
          font-size: 14px;
          line-height: 16px;
          font-family: "Source Sans Pro", sans-serif;
        }
      }
    }
  }

  @media (max-width: 880px) {
    flex-direction: column;

    .left {
      padding-right: 0;

      h2 {
        font-size: 28px;
        line-height: 38px;
      }

      .business-description {
        font-size: 16px;
        line-height: 26px;
      }

      h4 {
        font-size: 20px;
        line-height: 30px;
      }

      .button-row {
        flex-direction: column;

        a {
          margin: 0 auto 24px;
        }
      }
    }

    .right {
      padding-left: 0;
    }
  }
`;
