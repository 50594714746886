import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import jwt_decode from 'jwt-decode'

import DefaultBtn from 'global/DefaultBtn'
import { useUser } from 'global/context/UserContext'

const NewPassword = () => {
	const { changePassword } = useUser()
	const { errors, register, watch, handleSubmit } = useForm({
		mode: 'onTouched',
	})

	const { jwt } = useParams()

	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)

	const handleChangePassword = formData => {
		const { email = '', tempPassword = '' } = jwt_decode(jwt)
		const credentials = { email, tempPassword, password: formData.password }
		changePassword(credentials)
	}

	//check if jwt has expired (10 min expiration)
	if (Date.now() > jwt_decode(jwt).expires) {
		return (
			<>
				<Typography variant='h3'>Password Reset Link Expired</Typography>
				<DefaultBtn
					className='reset-expired-btn'
					variant='contained'
					component={Link}
					color='primary'
					to='/agent-access/forgot-password'
				>
					Try Again
				</DefaultBtn>
			</>
		)
	}

	return (
		<Paper className='form-container'>
			<Typography variant='h3'>New Password</Typography>
			<form onSubmit={handleSubmit(handleChangePassword)}>
				<TextField
					className='login-input'
					variant='outlined'
					fullWidth
					label='New Password'
					type={showPassword ? 'text' : 'password'}
					inputRef={register({ required: true })}
					name='password'
					defaultValue=''
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={() => setShowPassword(prevState => !prevState)}
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						),
					}}
					helperText={errors.password ? 'This is a required field' : null}
					autoFocus
				/>
				<TextField
					className='login-input'
					variant='outlined'
					fullWidth
					label='Confirm New Password'
					type={showPassword ? 'text' : 'password'}
					inputRef={register({ required: true, validate: value => value === watch('password') })}
					name='confirmPassword'
					defaultValue=''
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={() => setShowConfirmPassword(prevState => !prevState)}
								>
									{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						),
					}}
					helperText={errors.confirmPassword ? 'This is a required field and must match the password above.' : null}
				/>
				<DefaultBtn type='submit' variant='contained' color='secondary'>
					Save Password
				</DefaultBtn>
			</form>
		</Paper>
	)
}

export default NewPassword
