import React, { useState } from "react";
import styled from "styled-components";
import {
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  FormGroup,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

import states from "utils/stateAbbr";
import referralOptions from "utils/referralOptions";

const BusinessFields = ( { control, register, errors, show } ) => {

  const [otherReferral, setOtherReferral] = useState( false )

  const handleReferral = e => {
    if ( e.target.value === 'other' || otherReferral ) {
      setOtherReferral(e.target.value === 'other')
    }
  }

  return (
    <StyledBusinessFields
      className="business-information fields-group"
      show={show}
    >
      <Typography variant="h5">BUSINESS INFORMATION</Typography>
      <FormGroup className="outer-form-group">
        <TextField
          className={errors.businessName && 'form-error'}
          variant="outlined"
          label="Business Name *"
          name="businessName"
          inputRef={register({ required: true, minLength: 4, maxLength: 100 })}
          helperText={
            errors.businessName?.type === 'required'
              ? 'This is a required field'
              : errors.businessName &&
                'Business Name must be between 4 and 100 characters'
          }
          size="small"
        />
        <TextField
          variant="outlined"
          label="Business Description"
          multiline
          rows={2}
          rowsMax={6}
          name="businessDescription"
          inputRef={register({ maxLength: 512 })}
          helperText={
            errors.businessDescription &&
            'Description must be less than 512 characters'
          }
          size="small"
        />
        <TextField
          variant="outlined"
          label="Website"
          name="website"
          inputRef={register({ maxLength: 512 })}
          type="text"
          helperText={
            errors.website && 'Website URL must be less than 512 characters'
          }
          size="small"
        />
      </FormGroup>

      <Typography variant="h5">ARE YOU AN AMAC MEMBER?</Typography>
      <FormGroup className="outer-form-group">
        <TextField
          variant="outlined"
          label="AMAC Member ID"
          name="amacMemberId"
          inputRef={register}
          size="small"
        />
      </FormGroup>

      <Typography variant="h5">HOW DID YOU HEAR ABOUT US? <sup>*</sup></Typography>
      <FormGroup className="outer-form-group">
        <FormControl
          variant="outlined"
          className="referral"
          error={!!errors.referral}
        >
          <Controller
            control={control}
            name="referral"
            defaultValue=""
            render={() => (
              <Select
                inputRef={register({
                  required: true
                } )}
                name="referral"
                onChange={handleReferral}
                native
              >
                <option value=""></option>
                {referralOptions &&
                  referralOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                <option value="other">Other...</option>
              </Select>
            )}
          />
        </FormControl>

        {otherReferral && (
          <TextField
            className={errors.otherReferral && 'form-error'}
            variant="outlined"
            label="Tell us how you heard about us *"
            name="otherReferral"
            inputRef={register({
              required: true,
              maxLength: 255,
              minLength: 5,
            })}
            helperText={
              errors.otherReferral?.type === 'required'
                ? 'This is a required field'
                : errors.otherReferral &&
                  'Referral must be between 5 and 255 characters'
            }
            size="small"
          />
        )}
      </FormGroup>

      <Typography variant="h5">BUSINESS MAILING ADDRESS</Typography>
      <FormGroup className="outer-form-group">
        <TextField
          className={errors.businessAddress && 'form-error'}
          variant="outlined"
          label="Address *"
          name="businessAddress"
          inputRef={register({ required: true, maxLength: 255, minLength: 10 })}
          helperText={
            errors.businessAddress?.type === 'required'
              ? 'This is a required field'
              : errors.businessAddress &&
                'Address field must be between 10 and 255 characters'
          }
          size="small"
        />
        <TextField
          variant="outlined"
          label="Address line 2 (optional)"
          name="businessAddress2"
          inputRef={register({ maxLength: 255 })}
          helperText={
            errors.businessAddress2 &&
            'Address 2 field must be less than 255 characters'
          }
          size="small"
        />
        <div className="field-row">
          <TextField
            className={`city ${errors.businessCity && 'form-error'}`}
            variant="outlined"
            label="City *"
            name="businessCity"
            inputRef={register({
              required: true,
              maxLength: 255,
              minLength: 3,
            })}
            helperText={
              errors.businessCity?.type === 'required'
                ? 'This is a required field'
                : errors.businessCity &&
                  'City must be between 3 and 255 characters'
            }
            size="small"
          />
          <FormControl
            variant="outlined"
            className="state"
            error={!!errors.businessState}
          >
            <InputLabel htmlFor="state-select">State *</InputLabel>
            <Controller
              as={Select}
              control={control}
              label="State *"
              name="businessState"
              rules={{ required: true }}
              defaultValue=""
              native
            >
              <option value=""></option>
              {states &&
                states.map(state => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
            </Controller>
            {errors.businessState && (
              <FormHelperText>This is a reuired field</FormHelperText>
            )}
          </FormControl>
          <TextField
            className={`zip ${errors.businessZip && 'form-error'}`}
            variant="outlined"
            label="Zip Code *"
            name="businessZip"
            inputRef={register({ required: true, maxLength: 10, minLength: 5 })}
            helperText={
              errors.businessZip?.type === 'required'
                ? 'This is a required field'
                : errors.businessZip &&
                  'Zip Code must be between 5 and 10 characters'
            }
            size="small"
          />
        </div>
      </FormGroup>
    </StyledBusinessFields>
  );
};

const StyledBusinessFields = styled.div`
  overflow: hidden;
  height: ${props => (props.show ? 'auto' : 0)};
  margin: 0 10%;
  margin-top: ${props => (props.show ? 'inherit' : '0 !important')};

  select {
    padding: 10.5px 14px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 12px);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

export default BusinessFields;
