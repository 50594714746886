import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import generateRandomString from "utils/generateRandomString";
import pluralize from "pluralize";

import { useToastMessage } from "global/context/ToastMessageContext";
import { getCoords } from "utils/getCoords";
import daysOfWeek from "utils/daysOfWeek";

export const useBusinessObjectsCrud = ({
  currentData,
  setCurrentData,
  newData,
  setNewData,
  defaultObject,
  jwt,
  callback,
}) => {
  const { showToastMessage } = useToastMessage();

  const addNew = () => {
    defaultObject.key = generateRandomString(10);
    const newArrayCopy = [...newData, defaultObject];
    setNewData(newArrayCopy);
  };

  const addOrUpdateLocation = async (location, businessId) => {
    let result = null;
    const payloadLocation = {
      id: location.id ? location.id : null,
      status: "active",
      locationName: location.locationName,
      streetAddress1: location.streetAddress1,
      streetAddress2: location.streetAddress2,
      city: location.city,
      state: location.state,
      zip: location.zip,
      phone: location.phone,
      locationUrl: location.locationUrl,
      businessId,
    };

    // check if all hours inputs are empty, if so
    let hours = daysOfWeek.filter((day) => location[`${day}-hours-in`]);

    if (hours.length) {
      hours = daysOfWeek
        .map((day) => {
          const timeIn = location[`${day}-hours-in`]
            ? location[`${day}-hours-in`]
            : "Closed";
          const timeOut = location[`${day}-hours-out`]
            ? location[`${day}-hours-out`]
            : "Closed";

          return `${day}-${timeIn}-${timeOut}`;
        })
        .join();
    }

    payloadLocation.hours = hours.length ? hours : null;

    const encodedAddress = encodeURI(
      `${payloadLocation.streetAddress1}, ${payloadLocation.streetAddress2}, ${payloadLocation.city}, ${payloadLocation.state} ${payloadLocation.zip}`
    );

    const coords = await getCoords(encodedAddress);

    payloadLocation.lat = coords.lat;
    payloadLocation.lng = coords.lng;

    if (location.id) {
      await axios
        .put(
          `${process.env.REACT_APP_ENDPOINT}/location/update`,
          payloadLocation,
          {
            headers: { "x-auth-token": jwt },
          }
        )
        .then((res) => {
          showToastMessage({
            type: "success",
            message: "Location updated successfully!",
          });
          result = "success";
        })
        .catch((err) => {
          showToastMessage({
            type: "error",
            message: `Error! ${err.response?.data}`,
          });

          result = "failure";
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_ENDPOINT}/location/create`,
          payloadLocation,
          {
            headers: { "x-auth-token": jwt },
          }
        )
        .then((res) => {
          showToastMessage({
            type: "success",
            message: "Location created successfully!",
          });
          result = "success";
        })
        .catch((err) => {
          showToastMessage({
            type: "error",
            message: `Error! ${err.response?.data}`,
          });

          result = "failure";
        });
    }

    return result;
  };

  const editCurrent = (index) => {
    const pluralizedType = pluralize(defaultObject.type);
    const currentDataCopy = cloneDeep(currentData);
    const dataMoving = currentDataCopy[pluralizedType].splice(index, 1);

    setCurrentData(currentDataCopy);
    setNewData([...newData, dataMoving[0]]);
  };

  const removeNew = (index) => {
    const newArrayCopy = [...newData];
    newArrayCopy.splice(index, 1);

    setNewData(newArrayCopy);
  };

  const deleteCurrent = (type, id) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: `Are you sure you want to delete this ${type}?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let currentDataCopy = {};
            if (type === "discount") {
              const pluralizedType = pluralize(type);
              currentDataCopy = cloneDeep(currentData);
              const index = currentDataCopy[pluralizedType].findIndex(
                (obj) => obj.id == id
              );
              let deletedData;

              deletedData = currentDataCopy[pluralizedType][index];
              currentDataCopy.discounts.splice(index, 1);
            }

            await axios
              .delete(
                `${process.env.REACT_APP_ENDPOINT}/${type}/delete/${id}`,
                {
                  headers: { "x-auth-token": jwt },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  showToastMessage({
                    type: "success",
                    message: `${type} deleted successfully!`,
                  });
                  if (callback) callback();
                  if (currentData) {
                    setCurrentData(currentDataCopy);
                  }
                }
              })
              .catch((err) => {
                console.log("Error deleting location: ", err.message);
                showToastMessage({
                  type: "error",
                  message: `Error! ${type} not deleted`,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addOrUpdateRep = async (rep, businessId) => {
    let result = null;
    const payloadRep = {
      id: rep.id ? rep.id : null,
      firstName: rep.firstName,
      lastName: rep.lastName,
      status: rep.status ? "active" : "deleted",
      email: rep.email,
      phone: rep.phone,
      businessId,
    };

    console.log("payload: ", payloadRep);

    if (rep.id) {
      await axios
        .put(
          `${process.env.REACT_APP_ENDPOINT}/representative/update`,
          payloadRep,
          {
            headers: { "x-auth-token": jwt },
          }
        )
        .then((res) => {
          showToastMessage({
            type: "success",
            message: "Representative updated successfully!",
          });
          result = "success";
        })
        .catch((err) => {
          showToastMessage({
            type: "error",
            message: `Error! ${err.response?.data}`,
          });

          result = "failure";
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_ENDPOINT}/representative/create`,
          payloadRep,
          {
            headers: { "x-auth-token": jwt },
          }
        )
        .then((res) => {
          showToastMessage({
            type: "success",
            message: "Location created successfully!",
          });
          result = "success";
        })
        .catch((err) => {
          showToastMessage({
            type: "error",
            message: `Error! ${err.response?.data}`,
          });

          result = "failure";
        });
    }

    return result;
  };

  return {
    addNew,
    editCurrent,
    removeNew,
    deleteCurrent,
    addOrUpdateLocation,
    addOrUpdateRep,
  };
};
