import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import StoreIcon from "@material-ui/icons/StoreOutlined";
import StarIcon from "@material-ui/icons/StarOutlined";

import Container from "styles/Container";
import logo from "images/logo-regular.png";
import DefaultBtn from "./DefaultBtn";

const showCurrentYear = () => new Date().getFullYear();

const Footer = () => {
  return (
    <StyledFooter>
      <div className="main-footer">
        <Container className="footer-container">
          <div className="left">
            <img src={logo} alt="AMAC Inc" />
            <div className="hide-on-mobile">
              <Typography variant="body1" color="primary">
                Need more info? We're here to help.
              </Typography>
              <DefaultBtn
                href="mailto:map@amac.us"
                variant="contained"
                color="primary"
              >
                Send Us a Message
              </DefaultBtn>
              <Typography variant="h6" color="primary">
                Toll Free:{" "}
                <Link href="tel:8008766196">
                  <b>800-876-6196</b>
                </Link>
              </Typography>
            </div>
          </div>
          <div className="right">
            <div className="top">
              <DefaultBtn
                href="https://amac.us/join/?campaign=AMAC%20Map"
                variant="outlined"
                color="secondary"
                target="_blank"
                endIcon={<StarIcon />}
              >
                Join AMAC Today
              </DefaultBtn>
            </div>
            {/* <div className="bottom">
              <DefaultBtn
                to="/business"
                component={RouterLink}
                variant="outlined"
                color="secondary"
                endIcon={<StoreIcon />}
              >
                Business Sign Up
              </DefaultBtn>
            </div> */}
          </div>
          <div className="show-on-mobile">
            <Typography variant="body1" color="primary">
              Need more info? We're here to help.
            </Typography>
            <DefaultBtn
              href="mailto:map@amac.us"
              variant="contained"
              color="primary"
            >
              Send Us a Message
            </DefaultBtn>
            <Typography variant="h6" color="primary">
              Toll Free:{" "}
              <Link href="tel:8008766196">
                <b>800-876-6196</b>
              </Link>
            </Typography>
          </div>
        </Container>
      </div>
      <div className="sub-footer">
        <Container className="sub-footer-container">
          <div className="left">
            <ButtonGroup variant="text" size="small">
              <Button to="/terms" component={RouterLink}>
                Terms and Conditions
              </Button>
            </ButtonGroup>
          </div>
          <div className="right">
            <Typography variant="body2">
              Copyright &copy; {showCurrentYear()} AMAC, Inc. All rights
              reserved.
            </Typography>
          </div>
        </Container>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  .show-on-mobile {
    display: none;
  }

  .main-footer {
    background: #f4f7fe;
    padding: 80px 2%;

    .footer-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      margin-bottom: 36px;
      max-width: 300px;
    }

    p {
      margin-bottom: 13px;
      font-size: 16px;
    }

    .MuiButton-contained {
      padding: 6px 36px;
      margin-bottom: 36px;
    }

    .MuiButton-outlined {
      font-weight: 800;
      background-color: #fff;
    }

    .right {
      text-align: center;

      a {
        font-size: 27px;
        margin-top: 13px;
      }
    }

    .top {
      margin-bottom: 84px;
    }
  }

  .sub-footer {
    background: #e0e7fd;
    padding: 30px 2%;

    .sub-footer-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right {
        margin-left: auto;
      }
    }
  }

  @media (max-width: 660px) {
    .show-on-mobile {
      display: block;
    }

    .hide-on-mobile {
      display: none;
    }

    .main-footer {
      .footer-container {
        flex-direction: column;
      }

      .top,
      .bottom {
        margin-bottom: 36px;
      }
    }
  }
`;

export default Footer;
