import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import DefaultBtn from 'global/DefaultBtn'
import { useUser } from 'global/context/UserContext'

const Login = () => {
	const { login } = useUser()
	const [showPassword, setShowPassword] = useState(false)
	const { errors, register, handleSubmit } = useForm()

	const { state } = useLocation()

	return (
		<>
			<Paper className='form-container'>
				<Typography variant='h2'>Agent Login</Typography>
				<form onSubmit={handleSubmit(login)}>
					<TextField
						className='login-input'
						variant='outlined'
						fullWidth
						label='Email'
						type='email'
						inputRef={register({ required: true })}
						name='email'
						defaultValue=''
						helperText={errors.email ? 'This is a required field' : null}
						autoFocus
					/>
					<TextField
						className='login-input'
						variant='outlined'
						fullWidth
						label='Password'
						type={showPassword ? 'text' : 'password'}
						inputRef={register({ required: true })}
						name='password'
						defaultValue=''
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => setShowPassword(prevState => !prevState)}
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
						helperText={errors.password ? 'This is a required field' : null}
					/>
					<TextField type='hidden' name='comingFrom' value={state?.from?.pathname} inputRef={register} />
					<div className='button-row'>
						<DefaultBtn type='submit' variant='contained' color='secondary'>
							Login
						</DefaultBtn>
						<DefaultBtn
							className='forgot-password-btn'
							variant='text'
							component={Link}
							to='/agent-access/forgot-password'
						>
							Forgot Password
						</DefaultBtn>
					</div>
				</form>
			</Paper>
		</>
	)
}

export default Login
