import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

export const StyledFormPage = styled.div`
  padding: 60px 0;
  background: #f4f7fe;
  min-height: 100vh;
  border-bottom: 1px solid rgba(50, 50, 50, 0.2);

  .admin-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 25px;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .button-row {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 43px;
    line-height: 53px;
    margin-bottom: 48px;
  }

  h5 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 22px;
  }

  .fields-group {
    margin-top: 48px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -4%;

    .left,
    .right {
      flex: 1;
      padding: 0 4% 5px;
    }
  }

  .field-row {
    display: flex;
    justify-content: space-between;
  }

  .MuiFormControl-root {
    margin-bottom: 24px;

    &.outer-form-group {
      width: 100%;
    }
  }

  #logo-input {
    padding-left: 60px;
  }

  .logo-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;

    .current-logo,
    .new-logo {
      flex: 1;
    }

    .logo-thumbnail {
      max-width: 100%;
      max-height: 200px;
      padding: 0 15px;
    }
  }

  .clear-picture {
    position: absolute;
    top: 40px;
    right: 25px;
    background: #fff;
    cursor: pointer;
    padding: 6px;
    min-width: 25px;

    &:hover {
      background: #f4f7fe;
    }
  }

  .category-container {
    flex-wrap: nowrap;
  }

  input,
  .MuiInputBase-multiline,
  .MuiSelect-root,
  .MuiOutlinedInput-adornedEnd {
    background: #fff;
  }

  .MuiOutlinedInput-adornedEnd svg {
    cursor: pointer;

    &:hover {
      background: #f4f7fe;
    }
  }

  .MuiFormHelperText-root {
    color: #e31937;
  }

  .city {
    width: 48%;
  }

  .state,
  .zip {
    width: 24%;
  }

  .current-discounts {
    padding-left: 0;
    list-style: none;
    border-radius: 4px;

    .current-discount-item {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      border-bottom: 1px solid rgba(50, 50, 50, 0.2);
      padding: 10px 15px;
      border-radius: 4px;

      &:last-child {
        border-bottom: none;
      }

      .left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;

        svg {
          position: absolute;
          top: 5px;
          left: 5px;
          opacity: 0;
          transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        img {
          width: 100%;
        }

        .coupon-image {
          width: 100%;
          max-width: 100px;
          max-height: 100px;
          margin-right: 15px;
          cursor: pointer;

          &:hover {
            .zoom-in {
              opacity: 1;
            }
          }
        }

        &.magnify {
          .coupon-image {
            max-width: 500px;
            max-height: 500px;
          }

          .coupon-image:hover {
            .zoom-in {
              opacity: 0;
            }

            .zoom-out {
              opacity: 1;
            }
          }
        }
      }

      .right {
        flex: unset;
      }
    }
  }

  .discount-item {
    padding: 10px 20px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    .discount-left {
      flex: 2;
    }

    .discount-right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        max-width: 100px;
        max-height: 100px;
      }
    }

    .discount-file-input {
      padding-left: 80px;
    }

    .discount-text {
      width: 100%;
      margin-bottom: 0;
      margin-top: -14px;
    }
  }

  .remove-discount-item {
    height: 50px;
    width: 50px;
  }
`;

export const StyledFields = styled.div`
  overflow: hidden;
  height: ${(props) => (props.show ? "auto" : 0)};
  margin-top: ${(props) => (props.show ? "inherit" : "0 !important")};

  .header-with-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledLocations = styled(StyledFields)`
  .title-row {
    display: flex;
    justify-content: space-between;
  }

  .search-row form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35%;
    min-width: 250px;
    margin-bottom: 24px;

    .MuiFormControl-root {
      margin-bottom: 0;
      margin-right: 15px;
      flex: 1;
    }
  }

  .form-control {
    width: 100%;
  }

  .current-locations {
    margin-bottom: 50px;

    ul {
      padding-left: 0;
    }

    .current-location-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 10px 25px;
      border-bottom: 1px solid rgba(50, 50, 50, 0.2);

      h6 {
        font-weight: bold;
      }

      p {
        opacity: 0.8;
      }

      &:last-child {
        border-bottom: none;
      }

      .left {
        flex: 1;
      }

      .middle {
        width: 40%;
        min-width: 275px;
      }

      .right {
        width: 100px;
      }
    }
  }

  .locations-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -2%;

    .remove-item-btn {
      margin-top: -14px;
    }
  }

  .phone-row {
    width: 275px;
    margin-bottom: 24px;

    .react-tel-input {
      input {
        color: #444;
      }

      .flag-dropdown::before {
        display: none;
      }

      .special-label,
      .form-control + div:before {
        content: "Phone";
        background: linear-gradient(180deg, transparent 35%, #fff 35%);
        color: rgba(0, 0, 0, 0.54);
        left: 10px;
      }

      .form-control:focus {
        border-color: #063b86;
        box-shadow: 0 0 0 1px #063b86;
      }

      .selected-flag {
        cursor: default;
        display: flex;
        justify-content: center;
      }
    }
  }

  .hours-row {
    align-items: center;

    input {
      padding-right: 14px;
      padding-left: 14px;
    }

    p {
      margin: 0 24px 24px;
    }
  }

  .MuiPagination-ul {
    justify-content: flex-end;
  }
`;

export const SingleItem = styled(Paper)`
  background: transparent;
  padding: 24px;
  width: 46%;
  margin: 0 2% 24px;
`;

export const StyledReps = styled(StyledFields)`
  .form-control {
    width: 100%;
  }

  h5 {
    display: flex;
    justify-content: space-between;
  }

  .current-reps {
    margin-bottom: 50px;

    ul {
      padding-left: 0;
    }

    .current-rep-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 10px 25px;
      border-bottom: 1px solid rgba(50, 50, 50, 0.2);

      h6 {
        font-weight: bold;
      }

      p,
      a {
        color: #1a2f54;
        opacity: 0.8;
      }

      &:last-child {
        border-bottom: none;
      }

      .left {
        flex: 1;
      }

      .middle {
        width: 40%;
        min-width: 275px;
      }

      .right {
        width: 100px;
      }
    }
  }

  .reps-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -2%;

    .remove-item-btn {
      margin-top: -14px;
    }
  }

  .field-row {
    width: 100%;

    .MuiFormControl-root {
      width: 46%;
    }
  }

  .react-tel-input {
    width: 100%;
    margin-bottom: 24px;

    input {
      color: #444;
    }

    .flag-dropdown::before {
      display: none;
    }

    .special-label,
    .form-control + div:before {
      content: "Phone";
      background: linear-gradient(180deg, transparent 35%, #fff 35%);
      color: rgba(0, 0, 0, 0.54);
      left: 10px;
    }

    .form-control:focus {
      border-color: #063b86;
      box-shadow: 0 0 0 1px #063b86;
    }

    .selected-flag {
      cursor: default;
      display: flex;
      justify-content: center;
    }
  }
`;
