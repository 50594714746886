import React, { useState, useEffect, useCallback, useRef } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import styled from "styled-components";

import MapSearch from "./MapSearch";
import MapMarker from "./MapMarker";

const defaultCenter = {
  coords: {
    lat: 39.828378,
    lng: -98.579474,
  },
  zoom: 4,
};

const Marker = ({ children }) => <div>{children}</div>;

const MapContainer = (props) => {
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(props.zoom || defaultCenter.zoom);
  const [center, setCenter] = useState(defaultCenter.coords);
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    if (props.zoom) {
      setZoom(props.zoom);
    }
  }, [props.zoom, props.coords?.lat, props.coords?.lng]);

  const points = props.markers.map((point) => ({
    type: "Feature",
    properties: {
      isCluster: false,
      locationId: point.id,
      location: point,
    },
    geometry: {
      type: "Point",
      coordinates: [parseFloat(point.lng), parseFloat(point.lat)],
    },
  }));

  useEffect(() => {
    if (props.coords?.lat && props.coords?.lng) {
      setCenter(props.coords);
    }
  }, [props.coords?.lat, props.coords?.lng]);

  const centerOnLocation = useCallback((location) => {
    setCenter({ lat: location.lat, lng: location.lng });
    props.setActiveLocation(location.id);
  });

  const getAppropriateRadius = (numLocations) => {
    if (numLocations < 25) {
      return 0;
    } else if (numLocations < 50) {
      return 10;
    } else if (numLocations < 100) {
      return 25;
    } else if (numLocations < 250) {
      return 50;
    } else {
      return 75;
    }
  };

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: getAppropriateRadius(props.markers?.length),
      maxZoom: 20,
    },
  });

  // When there are over 50 locations only list the ones that are not clusterec
  useEffect(() => {
    if (props.markers?.length < 50 && props.setLocationsDisplayed) {
      props.setLocationsDisplayed(props.markers);
      return;
    }
    if (clusters && props.setLocationsDisplayed) {
      const locationsDisplayed = clusters
        .filter((cluster) => cluster.properties.isCluster === false)
        .map((clust) => clust.properties.location);
      props.setLocationsDisplayed(locationsDisplayed);
    }
  }, [clusters]);

  const setMapCenter = (coords, zoom) => {
    mapRef.current.setZoom(zoom || 10);
    mapRef.current.panTo(coords);
  };

  return (
    <Container>
      {!props.hideSearch && <MapSearch setMapCenter={setMapCenter} />}
      <GoogleMapReact
        zoom={zoom}
        center={center}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
            locationId,
          } = cluster.properties;

          if (isCluster) {
            return (
              <Marker key={cluster.id} lat={latitude} lng={longitude}>
                <div
                  className="cluster-marker"
                  style={{
                    width: `${40 + (pointCount / points.length) * 100}px`,
                    height: `${40 + (pointCount / points.length) * 100}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    setZoom(expansionZoom);
                    setCenter({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          return (
            <Marker key={locationId} lat={latitude} lng={longitude}>
              <MapMarker
                id={locationId}
                isActive={props.activeLocation?.id === locationId}
                setActiveLocation={props.setActiveLocation}
                centerOnLocation={() => {
                  centerOnLocation({ lat: latitude, lng: longitude });
                  setZoom(15);
                }}
                scrollToItem={props.scrollToItem}
                listRef={props.listRef}
              />
            </Marker>
          );
        })}
      </GoogleMapReact>
    </Container>
  );
};

const MemoizedMap = React.memo(MapContainer);

export default MemoizedMap;

const Container = styled.div`
  height: ${(props) => (props.height ? props.height : "500px")};
  width: ${(props) => (props.width ? props.width : "auto")};
  position: relative;

  .cluster-marker {
    background: #469ce8dd;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #469ce8;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      background: #469ce8;
    }
  }

  .map-marker-container {
    width: 30px;
    transform: translate(-50%, -100%);
    pointer-events: all !important;
    position: relative;
    z-index: 1;

    &.active {
      z-index: 2;
    }

    /* positioning of map-marker-container parents is offset, so to avoid hover state on empty spot... */
    div:first-child div:nth-child(3) div div:first-child {
      pointer-events: none;
    }

    img {
      width: 30px;
      position: relative;
    }

    img.marker-border {
      position: absolute;
      transform: scale(1) translateY(2px);
      transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    img.marker-border.active {
      transform: scale(1.7) translateY(2px);
    }

    &:hover {
      img.marker-border {
        transform: scale(1.7) translateY(2px);
      }
    }
  }
`;
