import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import DefaultBtn from 'global/DefaultBtn'
import { useUser } from 'global/context/UserContext'
import { useToastMessage } from 'global/context/ToastMessageContext'

const CreateAgent = () => {
	const { createAgent } = useUser()
	const { toastMessage } = useToastMessage()
	const { errors, register, handleSubmit, reset } = useForm({
		mode: 'onTouched',
	})

	useEffect(() => {
		if (toastMessage.type === 'success' && toastMessage.show) {
			reset()
		}
	}, [toastMessage.type, toastMessage.show, reset])

	return (
		<>
			<Paper className='form-container'>
				<Typography variant='h2'>Create Agent</Typography>
				<form onSubmit={handleSubmit(createAgent)}>
					<div className='input-row'>
						<TextField
							className='login-input'
							variant='outlined'
							fullWidth
							label='First Name'
							type='text'
							inputRef={register({ required: true })}
							name='firstName'
							defaultValue=''
							helperText={errors.firstName ? 'This is a required field' : null}
							autoFocus
						/>
						<TextField
							className='login-input'
							variant='outlined'
							fullWidth
							label='Last Name'
							type='text'
							inputRef={register({ required: true })}
							name='lastName'
							defaultValue=''
							helperText={errors.lastName ? 'This is a required field' : null}
						/>
					</div>
					<TextField
						className='login-input'
						variant='outlined'
						fullWidth
						label='Email'
						type='email'
						inputRef={register({ required: true })}
						name='email'
						defaultValue=''
						helperText={errors.email ? 'This is a required field' : null}
					/>
					<DefaultBtn type='submit' variant='contained' color='secondary'>
						Create Agent
					</DefaultBtn>
				</form>
			</Paper>
		</>
	)
}

export default CreateAgent
