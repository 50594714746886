import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { useCookies } from 'react-cookie';

import { useToastMessage } from 'global/context/ToastMessageContext';
import DefaultBtn from 'global/DefaultBtn';

const ActivateBusinessModal = ({ businessId, getBusiness, closeModal }) => {
  const [isActivating, setIsActivating] = useState(false);
  const [cookies] = useCookies(['jwt']);

  const { showToastMessage } = useToastMessage();

  const handleActivate = async () => {
    setIsActivating(true);
    const payload = {
      business: {
        id: businessId,
        status: 'active',
        activationDate: new Date(),
      },
    };

    axios
      .put(`${process.env.REACT_APP_ENDPOINT}/business/update`, payload, {
        headers: { 'x-auth-token': cookies?.jwt },
      })
      .then(res => {
        if (res.status === 200) {
          getBusiness();
          showToastMessage({
            type: 'success',
            message: 'Business successfully activated.',
          });
          closeModal();
        }
      })
      .catch(err => {
        showToastMessage({
          type: 'error',
          message: 'Error! Please try again.',
        });
        setIsActivating(false);
      });
  };

  return (
    <StyledModal>
      <IconButton className="close-btn" onClick={closeModal}>
        <CloseIcon />
      </IconButton>
      <Typography variant="h3">Activate Business</Typography>
      <Typography variant="body1">
        Are you sure you want to activate this business? Once activated, they
        will be listed on the map and/or online businesses page.
      </Typography>
      <div className="button-row">
        <DefaultBtn
          variant="contained"
          color="primary"
          disabled={isActivating}
          onClick={handleActivate}
        >
          {isActivating ? 'Activating...' : 'Activate'}
        </DefaultBtn>
        <DefaultBtn variant="outlined" color="secondary" onClick={closeModal}>
          Cancel
        </DefaultBtn>
      </div>
    </StyledModal>
  );
};

const StyledModal = styled(Paper)`
  padding: 40px 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  max-width: 675px;

  h3 {
    margin-bottom: 40px;
    text-align: center;
  }

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export default ActivateBusinessModal;
