import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/MenuOpenOutlined'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import MapIcon from '@material-ui/icons/MapOutlined'
import StarIcon from '@material-ui/icons/StarsOutlined'
import StoreIcon from '@material-ui/icons/StorefrontOutlined'
import AgentIcon from '@material-ui/icons/AccountCircleOutlined'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import KeyIcon from '@material-ui/icons/VpnKeyOutlined'
import AddAgentIcon from '@material-ui/icons/PersonAddOutlined'
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined'
import BusinessesIcon from '@material-ui/icons/ExitToAppOutlined'

import { useUser } from 'global/context/UserContext'

const MobileNav = () => {
	const [isOpen, toggleOpen] = useState(false)

	const { agent, logout, forgotPassword } = useUser()

	return (
		<StyledMobileNav key='right'>
			<IconButton className='mobile-nav-toggle' onClick={() => toggleOpen(true)} color='primary'>
				<MenuIcon />
			</IconButton>
			<StyledDrawer anchor='right' open={isOpen} onClose={() => toggleOpen(false)}>
				<IconButton onClick={() => toggleOpen(false)} className='close-btn'>
					<CloseIcon />
				</IconButton>
				<List className='mobile-nav-list'>
					<ListItem button component={Link} to='/map' onClick={() => toggleOpen(false)}>
						<ListItemIcon className='list-icon'>
							<MapIcon />
						</ListItemIcon>
						<ListItemText primary='AMAC Map' />
					</ListItem>
					<ListItem button component='a' href='https://amac.us/join/?campaign=AMAC%20Map' target='_blank'>
						<ListItemIcon className='list-icon'>
							<StarIcon />
						</ListItemIcon>
						<ListItemText primary='Join AMAC' />
					</ListItem>
					{/* <ListItem button component={Link} to='/business' onClick={() => toggleOpen(false)}>
						<ListItemIcon className='list-icon'>
							<StoreIcon />
						</ListItemIcon>
						<ListItemText primary='Business Sign Up' />
					</ListItem> */}
					{agent?.status === 'active' && (
						<>
							<Accordion className='accordion' square>
								<AccordionSummary expandIcon={<ExpandIcon />} aria-controls='agent-content' id='agent-header'>
									<ListItem button>
										<ListItemIcon className='list-icon'>
											<AgentIcon />
										</ListItemIcon>
										<ListItemText primary='Agent' />
									</ListItem>
								</AccordionSummary>
								<AccordionDetails>
									<List>
										<ListItem button onClick={() => forgotPassword({ email: agent?.email })}>
											<ListItemIcon className='list-icon'>
												<KeyIcon />
											</ListItemIcon>
											<ListItemText primary='Change Password' />
										</ListItem>
										<ListItem button component={Link} to='/agent-access/create-agent' onClick={() => toggleOpen(false)}>
											<ListItemIcon className='list-icon'>
												<AddAgentIcon />
											</ListItemIcon>
											<ListItemText primary='Add Agent' />
										</ListItem>
										<ListItem button component={Link} to='/agent/businesses' onClick={() => toggleOpen(false)}>
											<ListItemIcon className='list-icon'>
												<BusinessesIcon />
											</ListItemIcon>
											<ListItemText primary='All Businesses' />
										</ListItem>
										<ListItem
											button
											onClick={() => {
												logout()
												toggleOpen(false)
											}}
										>
											<ListItemIcon className='list-icon'>
												<LogoutIcon />
											</ListItemIcon>
											<ListItemText primary='Logout' />
										</ListItem>
									</List>
								</AccordionDetails>
							</Accordion>
						</>
					)}
				</List>
			</StyledDrawer>
		</StyledMobileNav>
	)
}

const StyledMobileNav = styled.div`
	margin-left: auto;

	.mobile-nav-toggle {
		svg {
			font-size: 2.5rem;
		}
	}
`

const StyledDrawer = styled(Drawer)`
	.close-btn {
		width: 50px;
	}

	.list-icon {
		min-width: 45px;
	}

	.mobile-nav-list a,
	.mobile-nav-list .MuiListItem-button {
		padding-right: 30px;
		padding-left: 30px;
	}

	#agent-header {
		padding: 0 16px 0 0;
	}
`

export default MobileNav
