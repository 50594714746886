import React from "react";
import styled from "styled-components";
import {
  Typography,
  TextField,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const ContactFields = ({ control, register, errors, show }) => {
  return (
    <StyledContactFields
      className="business-information fields-group"
      show={show}
    >
      <Typography variant="h5">CONTACT INFORMATION</Typography>
      <FormGroup className="outer-form-group">
        <TextField
          className={errors.repFirstName && "form-error"}
          variant="outlined"
          label="First Name *"
          name="repFirstName"
          inputRef={register({ required: true, maxLength: 100, minLength: 3 })}
          helperText={
            errors.repFirstName?.type === "required"
              ? "This is a required field"
              : errors.repFirstName &&
                "First Name must be between 3 and 100 characters"
          }
          size="small"
        />
        <TextField
          className={errors.repLastName && "form-error"}
          variant="outlined"
          label="Last Name *"
          name="repLastName"
          inputRef={register({ required: true, maxLength: 100, minLenght: 3 })}
          helperText={
            errors.repLastName?.type === "required"
              ? "This is a required field"
              : errors.repLastName &&
                "Last Name must be between 3 and 100 characters"
          }
          size="small"
        />
        <Controller
          containerClass={errors.repPhone && "form-error"}
          as={<PhoneInput />}
          control={control}
          country="us"
          onlyCountries={["us"]}
          countryCodeEditable={false}
          disableDropdown
          variant="outlined"
          label="Phone"
          name="repPhone"
          rules={{ required: true }}
          masks={{ us: "(...) ...-...." }}
          defaultValue=""
        />
        {errors.repPhone && (
          <FormHelperText className="phone-error">
            This is a required field
          </FormHelperText>
        )}
        <TextField
          className={errors.repEmail && "form-error"}
          variant="outlined"
          label="Email *"
          type="email"
          inputRef={register({ required: true, maxLength: 100, minLength: 5 })}
          name="repEmail"
          helperText={
            errors.repEmail?.type === "required"
              ? "This is a required field"
              : errors.repEmail &&
                "Email address must be between 5 and 100 characters"
          }
          size="small"
        />
      </FormGroup>
    </StyledContactFields>
  );
};

const StyledContactFields = styled.div`
  overflow: hidden;
  height: ${(props) => (props.show ? "auto" : 0)};
  margin: 0 10%;
  margin-top: ${(props) => (props.show ? "inherit" : "0 !important")};

  .outer-form-group {
    .react-tel-input {
      margin-bottom: 24px;

      .form-control {
        padding: 10.5px 14px 10.5px 58px;
      }

    .phone-error {
      margin: -20px 0 20px 14px;
    }
  }
`;

export default ContactFields;
