import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

const TransactionsList = ({ transactions }) => {
  return (
    <>
      <Typography variant="h6">Transactions</Typography>

      <StyledTable component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Refunded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell component="th" scope="row">
                  {moment(transaction.createdAt).format("MM/DD/YY h:mm a")}
                </TableCell>
                <TableCell>{transaction.product}</TableCell>
                <TableCell>
                  {transaction.type === "stripe"
                    ? "Credit Card - Stripe"
                    : transaction.type}
                </TableCell>
                <TableCell align="right">${transaction.amount}</TableCell>
                <TableCell align="right">
                  {transaction.refundedAt &&
                    moment(transaction.refundedAt).format("MM/DD/YY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTable>
    </>
  );
};

const StyledTable = styled(TableContainer)`
  margin-bottom: 50px;
  max-height: 168px;

  .MuiTableCell-head {
    font-weight: bold;
  }
`;

export default TransactionsList;
