import React from 'react'
import Typography from '@material-ui/core/Typography'

import { StyledHeroSection } from '../styles'
import Hero from 'global/Hero'
import DefaultBtn from 'global/DefaultBtn'
import Container from 'styles/Container'
import HeroBG from 'images/business-hero-bg.png'

const HeroSection = ({ scrollToForm }) => {
	return (
		<StyledHeroSection>
			<Hero background={HeroBG} noOverlay>
				<Container className='hero-content'>
					<Typography variant='h5' color='primary'>
						Welcome Business Owners to the
					</Typography>
					<Typography variant='h1' color='primary'>
						AMAC Discount Map
					</Typography>
					<Typography variant='body1'>
						AMAC is proud to offer our member/business owners the unique opportunity to showcase their businesses to
						AMAC’s over 2 million members!
					</Typography>
					<DefaultBtn variant='contained' color='secondary' onClick={scrollToForm}>
						Register Your Business Today
					</DefaultBtn>
				</Container>
			</Hero>
		</StyledHeroSection>
	)
}

export default HeroSection
