import React, { useState, useRef, useEffect, useCallback } from "react";
import { useParams, Link as RouteLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/EditOutlined";

import axios from "axios";
import pluralize from "pluralize";
import { useCookies } from "react-cookie";

import { PageRow } from "./styles";
import Map from "global/Map";
import Container from "styles/Container";
import DefaultBtn from "global/DefaultBtn";
import LoadingSpinner from "global/LoadingSpinner";
import { useUser } from "global/context/UserContext";
import LocationDetail from "./components/LocationDetail";
import useUrlParams from '../../utils/hooks/useUrlParams'

const BusinessDetail = () => {
  const [cookies] = useCookies(["jwt"]);
  const { businessId } = useParams();
  const urlParams = useUrlParams()

  const { agent } = useUser();

  const listRef = useRef(null)

  const [loadingBusiness, setLoadingBusiness] = useState(false);
  const [business, setBusiness] = useState({});
  const [activeLocation, setActiveLocation] = useState(null);
  const [coords, setCoords] = useState({});
  const [zoom, setZoom] = useState(4)

  const [locationsDisplayed, setLocationsDisplayed] = useState([]);

  useEffect(() => {
    if (urlParams.get('locationId')) {
      setActiveLocation({id: parseInt(urlParams.get('locationId')), setBy: 'detail'});
      if (listRef?.current) {
				const locationTarget = document.getElementById(
					`location-${urlParams.get('locationId')}`
				);
        if (locationTarget) {
          listRef.current.scrollTop =
            locationTarget.offsetTop - listRef.current.offsetTop;
        }
			}
    }
    if (urlParams.get('lat') && urlParams.get('lng')) {
      setCoords({ lat: parseFloat(urlParams.get('lat')), lng: parseFloat(urlParams.get('lng'))})
      setZoom(15)
    }
  }, [urlParams.get('locationId'), urlParams.get('lat'), urlParams.get('lng'), listRef.current])

  useEffect(() => {
    if (businessId) {
      setLoadingBusiness(true);
      axios
        .get(`${process.env.REACT_APP_ENDPOINT}/business/${businessId}`, {
          headers: { "x-auth-token": cookies?.jwt },
        })
        .then((res) => {
          if (res.status === 200) {
            setBusiness(res.data);
          } else {
            throw new Error(
              `There was an error fetching the business. Error code: ${res.status}`
            );
          }
          setLoadingBusiness(false);
        })
        .catch((err) => {
          console.log("error: ", err.response?.data);
          setLoadingBusiness(false);
        });
    }
  }, [businessId]);

  const setMapCenter = (location) => {
    setCoords({ lat: location.lat, lng: location.lng });
    setZoom(15)
  };

  const setActiveLocationId = useCallback(
    (id, setBy) => {
      setActiveLocation({id, setBy});
    },
    [setActiveLocation]
  );

  const displayDiscount = discount => {

    let discountUrl = null;

    if (discount.file) {
      discountUrl = discount.file.substring(0,4) === 'http' ? discount.file : `${process.env.REACT_APP_S3_URL}${discount.file}`
    }

    if (!discountUrl) {
      return <li key={discount.id}>{discount.text}</li>;
    }
    return (
      <li key={discount.id}>
        <Link href={discountUrl} target="_blank">
          {discount.text}
        </Link>
      </li>
    );
  }

  return (
    <Container>
      <LoadingSpinner loading={loadingBusiness} />
      {business.id && (
        <PageRow>
          {agent?.status === "active" && (
            <Fab
              className="admin-btn"
              color="primary"
              component={RouteLink}
              to={`/agent/business/${business.id}`}
            >
              <EditIcon />
            </Fab>
          )}
          <div className="left">
            {business.logo && (
              <img
                className="business-logo"
                src={`${process.env.REACT_APP_S3_URL}${business.logo}`}
                alt={`${business.name} logo`}
              />
            )}

            <Typography variant="h2">{business.name}</Typography>
            <Typography variant="body1" className="business-description">
              {business.description}
            </Typography>
            {!!business.discounts?.length && (
              <>
                <Typography variant="h4">
                  Special Offers & Discounts For AMAC Members:
                </Typography>
                <div className="discount-section">
                  <Typography variant="body1">
                    Checkout out our discount(s) below! If the discount is
                    clickable, you can click to view or download the coupon.
                    Otherwise, show us your AMAC Member ID card or mention this
                    discount to receive it.
                  </Typography>
                  <ul className="discount-list">
                    {!!business.discounts?.length &&
                      business.discounts.map(displayDiscount)}
                  </ul>
                </div>
              </>
            )}
            <Typography variant="h4">Business Information:</Typography>
            <div className="business-information">
              {business.website && (
                <div className="info-point">
                  <Typography variant="h5">WEBSITE</Typography>
                  <Link
                    href={
                      business.website.indexOf("http") !== -1
                        ? business.website
                        : `http://${business.website}`
                    }
                  >
                    {business.website}
                  </Link>
                </div>
              )}
              <div className="info-point">
                <Typography variant="h5">AMAC BUSINESS MEMBER ID</Typography>
                <Typography variant="body2">{business.id}</Typography>
              </div>
            </div>
            <div className="button-row">
              <DefaultBtn
                variant="contained"
                color="secondary"
                href="https://amac.us/join/?campaign=AMAC%20Map"
                target="_blank"
                rel="noreferrer noopener"
              >
                Become an AMAC Member
              </DefaultBtn>
              {/* <DefaultBtn
                variant="contained"
                color="secondary"
                href="/business"
              >
                Business Sign Up
              </DefaultBtn> */}
            </div>
          </div>
          <div className="right">
            <Map
              markers={business.locations}
              height="500px"
              width="100%"
              activeLocation={activeLocation}
              setActiveLocation={setActiveLocationId}
              coords={coords}
              zoom={zoom}
              setLocationsDisplayed={setLocationsDisplayed}
              listRef={listRef}
            />
            <div className="location-info">
              <div className="row">
                <Typography variant="h5">
                  {business.locations.length}{" "}
                  {pluralize("Location", business.locations.length)}
                </Typography>
                <Typography variant="caption"><i>(Zoom out to see more locations)</i></Typography>
              </div>

              <div className="locations-list" ref={listRef}>
                {locationsDisplayed.map((location) => {
                  return (
                    <LocationDetail
                      key={location.id}
                      location={location}
                      activeLocation={activeLocation}
                      setActiveLocation={setActiveLocationId}
                      setMapCenter={setMapCenter}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </PageRow>
      )}
    </Container>
  );
};

export default BusinessDetail;
