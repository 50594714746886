import React, { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import AccountIcon from "@material-ui/icons/AccountCircleOutlined";
import styled from "styled-components";

import MobileNav from "./MobileNav";
import logo from "images/logo.png";
import ElevateOnScroll from "./ElevateOnScroll";
import Container from "styles/Container";
import { useUser } from "global/context/UserContext";
import { useWindowResize } from "utils/hooks/useWindowResize";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { agent, logout, forgotPassword } = useUser();

  const [width] = useWindowResize();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <ElevateOnScroll>
      <StyledAppBar elevation={4}>
        <Container className="menu-container">
          <Link to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
          {width > 767 ? (
            <div className="nav">
              <ButtonGroup size="large" variant="text" color="primary">
                <Button to="/map" component={Link}>
                  AMAC Map
                </Button>
                <Button to="/businesses" component={Link}>
                  All Businesses
                </Button>
                <Button to="/online-businesses" component={Link}>
                  Online/National Businesses
                </Button>
                {/* <Button to="/business" component={Link}>
                  Business Sign Up
                </Button> */}
                {agent?.status === "active" && (
                  <IconButton
                    onClick={handleOpenMenu}
                    component={Button}
                    size="medium"
                  >
                    <AccountIcon />
                  </IconButton>
                )}
              </ButtonGroup>
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    forgotPassword({ email: agent?.email });
                    setAnchorEl(null);
                  }}
                >
                  Change Password
                </MenuItem>
                {agent?.isAdmin && (
                  <MenuItem
                    component={Link}
                    to="/agent-access/create-agent"
                    onClick={() => setAnchorEl(null)}
                  >
                    Create Agent
                  </MenuItem>
                )}
                <MenuItem
                  component={Link}
                  to="/agent/businesses"
                  onClick={() => setAnchorEl(null)}
                >
                  All Businesses
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/agent/business"
                  onClick={() => setAnchorEl(null)}
                >
                  Create Business
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    logout();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <MobileNav />
          )}
        </Container>
      </StyledAppBar>
    </ElevateOnScroll>
  );
};

const StyledAppBar = styled(AppBar)`
  padding: 17px 27px;
  background: #fff;
  overflow: visible;

  .logo {
    height: 82px;
    width: auto;
    transition: all 0.2s ease-in-out;

    @media (max-width: 675px) {
      height: 65px;
      width: auto;
    }
  }

  .menu-container {
    display: flex;
    align-items: center;
  }

  .nav {
    flex: 1;
    text-align: right;

    a,
    button {
      border-right: none;
      border-radius: 6px;
      padding: 16px 30px;
      font-weight: bold;
    }
  }

  &.scrolling {
    .logo {
      height: 50px;
      width: auto;

      @media (max-width: 675px) {
        height: 50px;
      }
    }
  }
`;

export default Header;
