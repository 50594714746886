import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route, useLocation } from 'react-router-dom';
import Toast from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import { AnimatePresence } from 'framer-motion';
import ReactGA from 'react-ga';

import Header from 'global/Header';
import Footer from 'global/Footer';
import AgentRoute from './AgentRouteController';
import BusinessAdmin from 'pages/BusinessAdmin/BusinessAdminPage';
import BusinessDetail from 'pages/BusinessDetail/BusinessDetailPage';
import AllBusinesses from 'pages/AllBusinesses/AllBusinessesPage';
import LocationDetail from 'pages/LocationDetail/LocationDetailPage';
import UserLanding from 'pages/UserLanding/UserLandingPage';
import BusinessLanding from 'pages/BusinessLanding/BusinessLandingPage';
import ClientCompleteListing from 'pages/ClientCompleteListing/ClientCompleteListingPage';
import Map from 'pages/Map/MapPage';
import Agent from 'pages/Agent/AgentPage';
import Faq from 'pages/Faq/FaqPage';
import Terms from 'pages/Terms/TermsPage';
import TransitionRoute from 'global/TransitionRoute';
import { useToastMessage } from 'global/context/ToastMessageContext';
import ClientBusinessSearch from 'pages/ClientBusinessSearch/ClientBusinessSearchPage';
import BusinessRenewPage from './pages/BusinessRenew/BusinessRenewPage';

const Routes = () => {
  useEffect(() => {
    ReactGA.initialize('UA-190475204-1');

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname, window.location.search]);

  const location = useLocation();
  const { toastMessage, setToastMessage } = useToastMessage();

  const handleCloseToastMessage = (event, reason) => {
    setToastMessage(prevState => ({ ...prevState, show: false }));
  };
  return (
    <Content>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <div className="content-container">
          <Switch location={location} key={location.pathname}>
            <TransitionRoute path="/" exact>
              <UserLanding />
            </TransitionRoute>
            <TransitionRoute path="/business" exact>
              <BusinessLanding />
            </TransitionRoute>

            <TransitionRoute path="/business/:businessId" exact>
              <BusinessDetail />
            </TransitionRoute>
            <TransitionRoute
              path="/businesses/:businessId/locations/:locationId"
              exact
            >
              <LocationDetail />
            </TransitionRoute>

            <TransitionRoute path="/map">
              <Map />
            </TransitionRoute>
            <TransitionRoute path="/agent-access/:form?/:jwt?">
              <Agent />
            </TransitionRoute>

            <TransitionRoute path="/faq">
              <Faq />
            </TransitionRoute>

            <TransitionRoute path="/terms" exact>
              <Terms />
            </TransitionRoute>

            <TransitionRoute path="/complete/business/:id/:jwt" exact>
              <ClientCompleteListing />
            </TransitionRoute>

            <TransitionRoute path="/renew/business/:id/:jwt" exact>
              <BusinessRenewPage />
            </TransitionRoute>

            <TransitionRoute path="/online-businesses" exact>
              <ClientBusinessSearch onlineBusinessesOnly />
            </TransitionRoute>

            <TransitionRoute path="/businesses" exact>
              <ClientBusinessSearch />
            </TransitionRoute>

            <AgentRoute
              path="/agent/business/:businessId?"
              component={BusinessAdmin}
            />
            <AgentRoute
              path="/agent/new-business"
              component={BusinessAdmin}
              exact
            />
            <AgentRoute
              path="/agent/businesses"
              component={AllBusinesses}
              exact
            />
          </Switch>
        </div>
      </AnimatePresence>

      <Toast
        open={toastMessage.show}
        autoHideDuration={6000}
        onClose={handleCloseToastMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={props => <Slide {...props} direction="left" />}
        key="toast-slide"
      >
        <Alert
          elavation={6}
          variant="filled"
          onClose={handleCloseToastMessage}
          severity={toastMessage.type}
        >
          {toastMessage.message}
        </Alert>
      </Toast>
      {location && !location.pathname.includes('/map') && <Footer />}
    </Content>
  );
};

const Content = styled.div`
  margin-top: 121px;
  overflow-x: hidden;

  .content-container {
    min-height: calc(100vh - 567px - 92px);
  }

  @media (max-width: 675px) {
    margin-top: 104px;
  }
`;

export default Routes;
