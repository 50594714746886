import React, { useState, useRef } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Link as RouterLink } from "react-router-dom";

import DefaultBtn from "global/DefaultBtn";

const BusinessListItem = ({ business }) => {
  const descriptionAnchor = useRef(null);
  const discountAnchor = useRef(null);
  const [showDescription, setShowDescription] = useState(false);
  const [showDiscounts, setShowDiscounts] = useState(false);

  return (
    <StyledItem>
      <div className="logo-section">
        {business.logo && (
          <RouterLink to={`/business/${business.id}`}>
            <img
              src={`${process.env.REACT_APP_S3_URL}${business.logo}`}
              alt={`${business.name} Logo`}
            />
          </RouterLink>
        )}
      </div>
      <div className="title-section">
        <RouterLink to={`/business/${business.id}`} className="business-link">
          <Typography variant="h6">{business.name}</Typography>
        </RouterLink>
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href={
            business.website.indexOf("http") !== -1
              ? business.website
              : `http://${business.website}`
          }
        >
          {business.website}
        </Link>
      </div>
      <div className="discount-section">
        <ul>
          {!!business.discounts.length &&
            business.discounts.slice(0, 3).map((discount) => {
              const discountUrl = discount.file
                ? `${process.env.REACT_APP_S3_URL}${discount.file}`
                : null;

              if (!discountUrl) {
                return <li key={discount.id}>{discount.text}</li>;
              }
              return (
                <li key={discount.id}>
                  <Link href={discountUrl} target="_blank">
                    {discount.text}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="buttons-section">
        {business.description && (
          <ClickAwayListener onClickAway={() => setShowDescription(false)}>
            <div className="description-section" ref={descriptionAnchor}>
              <DefaultBtn
                variant="outlined"
                color="secondary"
                onClick={() => setShowDescription((prev) => !prev)}
              >
                Description
              </DefaultBtn>
              <Popper
                open={showDescription}
                anchorEl={descriptionAnchor.current}
                placement="bottom"
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      className="business-description"
                      style={{ maxWidth: 350, padding: 10 }}
                    >
                      <Typography variant="body1">
                        {business.description}
                      </Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          </ClickAwayListener>
        )}
        {!!business.discounts.length && (
          <ClickAwayListener onClickAway={() => setShowDiscounts(false)}>
            <div className="discount-mobile-section" ref={discountAnchor}>
              <DefaultBtn
                variant="outlined"
                color="secondary"
                onClick={() => setShowDiscounts((prev) => !prev)}
              >
                Discounts
              </DefaultBtn>
              <Popper
                open={showDiscounts}
                anchorEl={discountAnchor.current}
                placement="bottom"
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      className="discounts-mobile"
                      style={{ maxWidth: 350, padding: 10 }}
                    >
                      <ul style={{ paddingLeft: 15 }}>
                        {business.discounts.slice(0, 3).map((discount) => {
                          const discountUrl = discount.file
                            ? `${process.env.REACT_APP_S3_URL}${discount.file}`
                            : discount.url;
                          return (
                            <li key={discount.id}>
                              <Link href={discountUrl} target="_blank">
                                {discount.text}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </StyledItem>
  );
};

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(50, 50, 50, 0.1);
  min-height: 100px;

  .logo-section {
    width: 250px;
    padding: 30px;

    img {
      width: 100%;
    }

    @media (max-width: 1200px) {
      width: 200px;
      padding: 15px;
    }

    @media (max-width: 990px) {
      width: 175px;
    }

    @media (max-width: 990px) {
      width: 175px;
    }
  }

  .title-section {
    flex: 2;
    padding: 10px;
    word-break: break-word;

    .business-link {
      color: #00539b;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: #469ce8;
      }
    }

    h6 {
      font-weight: bold;
    }
  }

  .discount-section {
    flex: 3;

    ul {
      padding: 0 0 0 25px !important;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .buttons-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }

  .description-section,
  .discount-mobile-section {
    padding-right: 15px;
    display: flex;

    button {
      font-size: 16px;
      margin: auto;
    }
  }

  .discount-mobile-section {
    display: none;

    @media (max-width: 1200px) {
      display: inline-block;
    }
  }
`;

export default BusinessListItem;
