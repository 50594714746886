import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const LoadingSpinner = ({ loading, wait = "500ms" }) => {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? wait : "0ms",
      }}
      unmountOnExit
    >
      <Backdrop style={{ zIndex: 5 }}>
        <CircularProgress />
      </Backdrop>
    </Fade>
  );
};

export default LoadingSpinner;
