import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import {
  Paper,
  Typography,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  Switch,
  InputAdornment,
  Checkbox,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import DollarIcon from '@material-ui/icons/AttachMoneyOutlined';

import DefaultBtn from 'global/DefaultBtn';
import { useToastMessage } from 'global/context/ToastMessageContext';

import { products } from 'utils/products';

const BusinessPaymentModal = ({
  business,
  closeModal,
  onPaymentComplete,
  product: passedProduct,
  clientFacing,
  renewal,
  jwt,
}) => {
  const [product, setProduct] = useState(passedProduct || products[0]);

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const { showToastMessage } = useToastMessage();
  const [cookies] = useCookies(['jwt']);

  const { register, errors, reset, handleSubmit } = useForm();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    console.log('passedProduct', passedProduct);
    if (passedProduct) {
      setProduct(passedProduct);
    }
  }, [passedProduct]);

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const onSuccessfulCheckout = async (type, paymentObj) => {
    const transaction = {
      businessId: business.id,
      type,
      product: product.title,
      paymentObj: paymentObj.paymentIntent,
    };

    try {
      const today = new Date();
      const currentExpiryDate = business?.expirationDate
        ? new Date(business.expirationDate)
        : null;

      let newExpirationDate =
        currentExpiryDate && currentExpiryDate > today
          ? currentExpiryDate
          : today;
      newExpirationDate.setFullYear(
        newExpirationDate.getFullYear() + product.years
      );

      let headerJwt;

      if (jwt || cookies?.jwt) {
        headerJwt = jwt
          ? { headers: { 'x-auth-token': jwt } }
          : { headers: { 'x-auth-token': cookies.jwt } };
      }

      const { data } = await axios.all([
        axios.put(
          `${process.env.REACT_APP_ENDPOINT}/business/update`,
          {
            business: {
              id: business.id,
              expirationDate: newExpirationDate,
              status: currentExpiryDate ? 'active' : business.status,
            },
          },
          headerJwt
        ),
        axios.post(
          `${process.env.REACT_APP_ENDPOINT}/transaction/create`,
          transaction,
          headerJwt
        ),
      ]);

      if (onPaymentComplete) {
        onPaymentComplete('active');
      }

      setIsProcessing(false);
      showToastMessage({
        type: 'success',
        message: 'Payment processed successfully!',
      });
      reset();
      closeModal();
    } catch (err) {
      console.log('error: ', err.message);
      setCheckoutError(err.message);
      showToastMessage({
        type: 'error',
        message:
          'Payment Successfully Processed, but there was an error with the form. Please reach out to us to complete your application.',
      });
      setIsProcessing(false);
    }
  };

  const submitPayment = async data => {
    setCheckoutError(false);
    setIsProcessing(true);

    if (isCheck) {
      const checkPaymentObj = {
        paymentIntent: {
          amount: data.checkAmount * 100,
          id: data.checkNumber,
          status: 'succeeded',
        },
      };

      return onSuccessfulCheckout('check', checkPaymentObj);
    }

    const billingDetails = {
      name: data.name,
    };

    const cardElement = elements.getElement('card');

    try {
      const { data: clientSecret } = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/stripe/payment-intents`,
        {
          // stripe wants amount in cents
          amount: product.price * 100,
        },
        {
          headers: { 'x-auth-token': cookies?.jwt ? cookies.jwt : jwt },
        }
      );

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setIsProcessing(false);
        return;
      }

      const paymentResults = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (paymentResults.error) {
        setCheckoutError(paymentResults.error.message);
        setIsProcessing(false);
        return;
      }

      if (checkoutError) {
        showToastMessage({
          type: 'error',
          message: checkoutError,
        });
      } else {
        onSuccessfulCheckout('stripe', paymentResults);
      }

      // Do something (like update transactions table) on successful checkout here...
      // onSuccessfulCheckout();
    } catch (err) {
      console.log('error: ', err.message);
      setCheckoutError(err.message);
      showToastMessage({
        type: 'error',
        message: err.message,
      });
    }
  };

  const iframeStyles = {
    base: {
      color: 'rgb(26,47,84)',
      fontSize: '16px',
      iconColor: 'rgb(26, 47, 84)',
    },
  };

  const cardElementOpts = {
    style: iframeStyles,
  };

  const handlePriceChange = ({ target }) => {
    const selectedProduct = products.filter(
      obj => obj.id === parseInt(target.value)
    );
    setProduct(selectedProduct[0]);
  };
  console.log('product: ', product);

  return (
    <StyledModal>
      <IconButton className="close-btn" onClick={closeModal}>
        <CloseIcon />
      </IconButton>
      <Typography variant="h3">Business Listing Payment</Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend">Product</FormLabel>
        <RadioGroup
          aria-label="product"
          name="product"
          inputRef={register({ required: true })}
          onChange={handlePriceChange}
          value={product.id || ''}
        >
          {clientFacing && (
            <FormControlLabel
              value={product.id}
              control={<Radio />}
              label={`$${product.price} - ${product.label}`}
            />
          )}
          {!clientFacing &&
            products.map(option => {
              if (option.type === 'renewal') return null;
              return (
                <FormControlLabel
                  value={option.id}
                  control={<Radio />}
                  label={`$${option.price} - ${option.label}`}
                />
              );
            })}
        </RadioGroup>
      </FormControl>
      {!clientFacing && (
        <>
          <FormLabel component="legend">Payment Type</FormLabel>
          <FormControl className="payment-type-toggle">
            <FormLabel>Credit Card</FormLabel>
            <Switch onChange={() => setIsCheck(prev => !prev)} />
            <FormLabel>Check</FormLabel>
          </FormControl>
        </>
      )}
      <form onSubmit={handleSubmit(submitPayment)}>
        <FormGroup>
          {isCheck ? (
            <>
              <TextField
                variant="outlined"
                label="Amount"
                name="checkAmount"
                defaultValue=""
                inputRef={register({ required: true })}
                helperText={
                  errors.checkAmount ? 'This is a required field' : null
                }
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DollarIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                label="Check Number"
                name="checkNumber"
                defaultValue=""
                inputRef={register({ required: true })}
                helperText={
                  errors.checkNumber ? 'This is a required field' : null
                }
                size="small"
              />
            </>
          ) : (
            <>
              <TextField
                variant="outlined"
                label="Name on Card"
                name="name"
                defaultValue=""
                inputRef={register({ required: true })}
                helperText={errors.name ? 'This is a required field' : null}
                size="small"
              />
              <div className="card-element-container">
                <CardElement
                  options={cardElementOpts}
                  onChange={handleCardDetailsChange}
                />
              </div>
            </>
          )}
          {clientFacing && (
            <FormControl>
              <FormLabel>
                <Checkbox
                  checked={agreedToTerms}
                  onChange={e => setAgreedToTerms(e.target.checked)}
                />
                I agree to the{' '}
                <Link to="/terms" target="_blank">
                  Terms and Conditions
                </Link>
              </FormLabel>
            </FormControl>
          )}
        </FormGroup>
        <DefaultBtn
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            isProcessing ||
            !stripe ||
            !product.price ||
            (clientFacing && !agreedToTerms)
          }
        >
          {isProcessing
            ? 'Processing...'
            : product.price
            ? `Pay $${product.price} ${clientFacing ? '& Submit' : ''}`
            : 'Select a product'}
        </DefaultBtn>
        {!renewal && (
          <Typography variant="body1">
            *submitting payment will save all business data entered in forms and
            activate business if not active already
          </Typography>
        )}
      </form>
    </StyledModal>
  );
};

const StyledModal = styled(Paper)`
  padding: 40px 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  max-width: 675px;

  h3 {
    margin-bottom: 40px;
    text-align: center;
  }

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .payment-type-toggle {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .card-element-container {
    background: #fff;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin-bottom: 40px;
  }

  p {
    margin-top: 12px;
  }
`;

export default BusinessPaymentModal;
