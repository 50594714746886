import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/PhoneIphoneOutlined";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import axios from "axios";

import Container from "styles/Container";
import DefaultBtn from "global/DefaultBtn";
import ClientCompleteListingForm from "./components/ClientCompleteListingForm";

const ClientCompleteListingPage = () => {
  const { id, jwt } = useParams();

  const { businessId, business, expiration } = jwt_decode(jwt);

  const [currentBusiness, setCurrentBusiness] = useState({});
  const [invalid, setInvalid] = useState(false);

  const getBusiness = () => {
    const busId = businessId ? businessId : business.id;
    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/business/${busId}`, {
        params: { noLocations: true },
        headers: { "x-auth-token": jwt },
      })
      .then((business) => {
        if (business) {
          setCurrentBusiness(business.data);
        }
      })
      .catch((err) => console.log("error: ", err.response?.data));
  };

  useEffect(() => {
    if (
      (businessId && parseInt(id) === parseInt(businessId)) ||
      (business && parseInt(id) === parseInt(business.id))
    ) {
      getBusiness();
    } else {
      setInvalid(true);
    }
  }, [businessId, id]);

  // get dates in same format
  const today = new Date();
  const expirationDate = new Date(expiration);

  if (
    today > expirationDate ||
    currentBusiness.status === "active" ||
    invalid
  ) {
    return (
      <StyledSorryContainer>
        <div className="content">
          <Typography variant="h3">
            <i>Sorry!</i>
          </Typography>
          {invalid && <Typography variant="h5">Invalid URL</Typography>}
          {currentBusiness.status === "active" && (
            <Typography variant="h5">
              Looks like your listing is already active. Please reach out to us
              if you need assistance updating your listing.
            </Typography>
          )}
          {today > expirationDate && (
            <Typography variant="h5">
              Looks like your link has expired. Please reach out to us if you
              need assistance completing your listing.
            </Typography>
          )}
          <div className="button-row">
            <DefaultBtn
              variant="outlined"
              color="primary"
              startIcon={<PhoneIcon />}
              href="tel:800-876-6196"
            >
              800-876-6196
            </DefaultBtn>
            <DefaultBtn
              variant="outlined"
              color="primary"
              startIcon={<EmailIcon />}
              href="mailto:map@amac.us"
            >
              map@amac.us
            </DefaultBtn>
          </div>
        </div>
      </StyledSorryContainer>
    );
  }

  return (
    <ClientCompleteListingForm
      currentBusiness={currentBusiness}
      setCurrentBusiness={setCurrentBusiness}
      getBusiness={getBusiness}
      jwt={jwt}
    />
  );
};

const StyledSorryContainer = styled(Container)`
  min-height: 60vh;
  display: flex;
  align-items: center;

  .content {
    margin: auto;
    width: 100%;
    max-width: 800px;

    h3,
    h5 {
      margin-bottom: 25px;
      text-align: center;
    }

    .button-row {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        margin: 0 10px;
      }
    }
  }
`;

export default ClientCompleteListingPage;
