import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Typography, Tabs, Tab, Paper, Backdrop } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/BusinessCenterOutlined";
import LocationIcon from "@material-ui/icons/PinDropOutlined";
import RepsIcon from "@material-ui/icons/FaceOutlined";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { StyledFormPage } from "styles/FormStyles";
import Container from "styles/Container";
import BusinessSection from "pages/BusinessAdmin/components/BusinessSection";
import LocationsSection from "pages/BusinessAdmin/components/LocationsSection";
import RepsSection from "pages/BusinessAdmin/components/RepsSection";
import DefaultBtn from "global/DefaultBtn";
import BusinessPaymentModal from "global/BusinessPaymentModal";
import Instructions from "./Instructions";
import LoadingSpinner from "global/LoadingSpinner";

const CllientCompleteListingForm = ({
  currentBusiness,
  setCurrentBusiness,
  getBusiness,
  jwt,
}) => {
  const businessFormRef = useRef();
  const [loading, setLoading] = useState(false);
  const [fieldsDisplayed, setFieldsDisplayed] = useState(0);
  const [paymentModalOpen, togglePaymentModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [fieldsDisplayed]);

  const handleFieldChange = (e, newValue) => {
    setFieldsDisplayed(newValue);
  };

  const closeModal = () => {
    togglePaymentModal(false);
  };

  const handleBusinessSubmit = (paid) => {
    if (!currentBusiness?.transactions?.length && paid !== true) {
      togglePaymentModal(true);
    } else if (businessFormRef.current) {
      businessFormRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <StyledClientForm>
      <LoadingSpinner loading={loading} />
      <Container className="page-container">
        <Typography variant="h2">Complete Listing</Typography>
        <Typography variant="body1" className="thank-you-msg">
          Thanks for your interest in our Discount Map! Please see the brief
          instructions at the top of each section for help filling out the form.
          If you have any further questions please don't hesitate to reach out
          to us at <a href="mailto:map@amac.us">map@amac.us</a> or{" "}
          <a href="tel:800-876-6196">800-876-6196</a>
        </Typography>

        <Paper>
          <Tabs
            value={fieldsDisplayed}
            onChange={handleFieldChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab icon={<BusinessIcon />} label="Business" />
            <Tab icon={<LocationIcon />} label="Locations" />
            <Tab icon={<RepsIcon />} label="Representatives" />
          </Tabs>
        </Paper>

        <Instructions fieldsDisplayed={fieldsDisplayed} />

        <BusinessSection
          key={currentBusiness.id} // needed to rerender form fields once default vals populate
          getBusiness={getBusiness}
          setLoading={setLoading}
          show={fieldsDisplayed === 0}
          currentBusiness={currentBusiness}
          setCurrentBusiness={setCurrentBusiness}
          ref={businessFormRef}
          clientFacing
        />
        <LocationsSection
          setLoading={setLoading}
          show={fieldsDisplayed === 1}
          businessId={currentBusiness.id}
          clientFacing
        />
        <RepsSection
          key={currentBusiness?.representatives?.length}
          currentBusiness={currentBusiness}
          getBusiness={getBusiness}
          setLoading={setLoading}
          businessId={currentBusiness.id}
          show={fieldsDisplayed === 2}
          clientFacing
        />
        <div className="button-row">
          <DefaultBtn
            className="next-btn"
            color="primary"
            variant="outlined"
            startIcon={<ChevronLeft />}
            disabled={fieldsDisplayed === 0}
            onClick={() => setFieldsDisplayed(fieldsDisplayed - 1)}
          >
            Prev Section
          </DefaultBtn>
          {fieldsDisplayed === 2 && (
            <div className="submit-btn-container">
              {currentBusiness.id && !currentBusiness.transactions.length ? (
                <DefaultBtn
                  className="submit-btn"
                  color="primary"
                  variant="contained"
                  onClick={handleBusinessSubmit}
                >
                  Checkout
                </DefaultBtn>
              ) : (
                <DefaultBtn
                  className="submit-btn"
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={handleBusinessSubmit}
                >
                  Activate Listing
                </DefaultBtn>
              )}
            </div>
          )}

          <DefaultBtn
            className="next-btn"
            color="primary"
            variant="outlined"
            endIcon={<ChevronRight />}
            disabled={fieldsDisplayed === 2}
            onClick={() => setFieldsDisplayed(fieldsDisplayed + 1)}
          >
            Next Section
          </DefaultBtn>
        </div>
      </Container>
      <Backdrop open={paymentModalOpen} style={{ zIndex: 1200 }}>
        {paymentModalOpen && (
          <BusinessPaymentModal
            closeModal={closeModal}
            business={currentBusiness}
            onPaymentComplete={() => handleBusinessSubmit(true)}
            jwt={jwt}
            clientFacing
          />
        )}
      </Backdrop>
    </StyledClientForm>
  );
};

const StyledClientForm = styled(StyledFormPage)`
  h2,
  .thank-you-msg {
    margin-bottom: 24px;
  }

  .fields-group {
    margin-top: 24px;
  }

  .instructions {
    margin-top: 24px;
  }
`;

export default CllientCompleteListingForm;
