import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Link,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import EyeIcon from '@material-ui/icons/VisibilityOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import PlusIcon from '@material-ui/icons/AddOutlined';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { StyledPage } from './styles';
import formatPhone from 'utils/formatPhone';
import Container from 'styles/Container';
import DefaultBtn from 'global/DefaultBtn';
import LoadingSpinner from '../../global/LoadingSpinner';
import { usePagination } from 'utils/hooks/usePagination';

const AllBusinesses = () => {
  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState({});
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [searchedName, setSearchedName] = useState('');
  const { currentPage, setCurrentPage, handlePageChange } = usePagination(1);
  const [cookies] = useCookies(['jwt']);
  const [status, setStatus] = useState(['active', 'pending']);

  useEffect(() => {
    setLoading(true);
    const options = {
      params: {
        page: currentPage,
      },
      headers: { 'x-auth-token': cookies?.jwt },
    };
    const isSearch = searchSubmitted ? 'search/' : '';
    if (searchSubmitted) {
      options.params.name = searchedName;
      options.params.status = status;
    }
    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/business/${isSearch}`, options)
      .then(res => {
        setLoading(false);
        if (res.status === 200) {
          setBusinesses(res.data);
        } else {
          throw new Error(
            `There was an error fetching the businesses. Error code: ${res.status}`
          );
        }
      })
      .catch(err => {
        setLoading(false);
        console.log('error: ', err.response?.data);
      });
  }, [currentPage]);

  const search = () => {
    if (!searchedName && JSON.stringify(status) == '["active","pending"]') {
      setSearchSubmitted(false);
    } else {
      setSearchSubmitted(true);
    }
    setLoading(true);
    setCurrentPage(1);
    const options = {
      params: {
        name: searchedName,
        status,
        page: currentPage,
      },
      headers: { 'x-auth-token': cookies?.jwt },
    };

    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/business/search`, options)
      .then(res => {
        if (res.status === 200) {
          setBusinesses(res.data);
        } else {
          throw new Error(
            `There was an error fetching the businesses. Error code: ${res.status}`
          );
        }
        setLoading(false);
      })
      .catch(err => {
        console.log('error: ', err.response?.data);
        setLoading(false);
      });
  };

  const handleStatusChanges = (event, newStatus) => {
    setStatus(newStatus);
  };

  return (
    <StyledPage>
      <Container className="page-container">
        <Typography variant="h2">All Businesses</Typography>
        <div className="top-row">
          <form
            className="search-form"
            onSubmit={e => {
              e.preventDefault();
              search();
            }}
          >
            <TextField
              variant="outlined"
              label="Business Name"
              name="name"
              value={searchedName}
              onChange={e => setSearchedName(e.target.value)}
            />

            <DefaultBtn variant="contained" color="primary" type="submit">
              Search
            </DefaultBtn>
            <DefaultBtn
              variant="contained"
              color="secondary"
              textColor="#fff"
              component={RouterLink}
              to="/agent/business"
              startIcon={<PlusIcon />}
            >
              Create Business
            </DefaultBtn>
            <ToggleButtonGroup
              className="status-group"
              value={status}
              onChange={handleStatusChanges}
            >
              <ToggleButton value="active">
                <Typography variant="body1">Active</Typography>
              </ToggleButton>
              <ToggleButton value="pending">
                <Typography variant="body1">Pending</Typography>
              </ToggleButton>
              <ToggleButton value="deleted">
                <Typography variant="body1">Deleted</Typography>
              </ToggleButton>
              <ToggleButton value="expired">
                <Typography variant="body1">Expired</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </form>
          {businesses?.count && (
            <div className="pagination">
              <Typography variant="body1">
                Results: <b>{businesses.count}</b>
              </Typography>
              <Pagination
                count={Math.ceil(businesses.count / 50)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
        {!!businesses.rows ? (
          <>
            <StyledTable component={Paper}>
              <Table className="business-list" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Logo</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Website</TableCell>
                    <TableCell align="center">Location(s)</TableCell>
                    <TableCell>Rep</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {businesses.rows.map(business => {
                    let normalizedWebsite = '';
                    if (business.website) {
                      normalizedWebsite =
                        business.website.indexOf('http') !== -1
                          ? business.website
                          : `http://${business.website}`;
                    }
                    return (
                      <TableRow
                        key={business.id}
                        className="business-list-item"
                      >
                        <TableCell component="th" scope="row">
                          <div className="logo">
                            {business.logo && (
                              <img
                                className="business-logo"
                                src={`${process.env.REACT_APP_S3_URL}${business.logo}`}
                                alt={`${business.name} logo`}
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="name">
                            <Typography variant="h6">
                              {business.name}
                            </Typography>
                            <Typography
                              className="business-status"
                              variant="body1"
                            >
                              Status: <i>{business.status}</i>
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="website">
                            {business.website && (
                              <Typography variant="body1">
                                <Link href={normalizedWebsite} target="_blank">
                                  {business.website}
                                </Link>
                              </Typography>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div className="locations">
                            <Typography variant="body1">
                              {business.locations?.length}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="rep">
                            {business.representatives?.slice(0, 1).map(rep => (
                              <div key={rep.id} className="rep-item">
                                <Typography variant="body1">
                                  {rep.firstName} {rep.lastName}
                                </Typography>
                                <Typography variant="body2">
                                  <Link href={`mailto:${rep.email}`}>
                                    {rep.email}
                                  </Link>
                                </Typography>
                                <Typography variant="body2">
                                  <Link href={`tel:${rep.phone}`}>
                                    {formatPhone(rep.phone)}
                                  </Link>
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="links">
                            <IconButton
                              href={`/business/${business.id}`}
                              title="View Business Detail Page"
                            >
                              <EyeIcon />
                            </IconButton>
                            <IconButton
                              href={`/agent/business/${business.id}`}
                              title="Edit Business"
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </StyledTable>
            {businesses?.count && (
              <div className="pagination">
                <Typography variant="body1">
                  Results: <b>{businesses.count}</b>
                </Typography>
                <Pagination
                  count={Math.ceil(businesses.count / 50)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </>
        ) : (
          <Typography variant="h5">
            Sorry, no businesses matched your search
          </Typography>
        )}
      </Container>
      <LoadingSpinner loading={loading} />
    </StyledPage>
  );
};

const StyledTable = styled(TableContainer)`
  margin-bottom: 25px;

  .MuiTableCell-head {
    font-weight: bold;
  }

  .website {
    word-break: break-word;
  }
`;

export default AllBusinesses;
