import React from "react";
import styled from "styled-components";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CloseIcon from "@material-ui/icons/ClearOutlined";
import SaveIcon from "@material-ui/icons/SaveOutlined";

import states from "utils/stateAbbr";
import daysOfWeek from "utils/daysOfWeek";

import DefaultBtn from "global/DefaultBtn";
import { SingleItem } from "styles/FormStyles";

const LocationModal = ({
  open,
  handleClose,
  location,
  handleSubmitLocation,
}) => {
  const { register, errors, control, handleSubmit } = useForm();

  const days = location?.hours?.split(",");

  const submitForm = (locationData) => {
    if (location.id) {
      locationData.id = location.id;
    }

    if (!locationData.phone) {
      locationData.phone = "";
    }

    handleSubmitLocation(locationData);
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <Paper className="location-form-container">
        <SingleItem variant="outlined" className="single-item">
          <Typography variant="h5">
            {location?.id ? "Updating Location" : "New Location"}
          </Typography>
          <FormControl className="form-control">
            <TextField
              key={location.locationName}
              variant="outlined"
              label="Location Name"
              name="locationName"
              inputRef={register({ required: true })}
              defaultValue={location ? location.locationName : ""}
              helperText={
                errors.locationName ? "This is a required field" : null
              }
            />
            <div className="field-row phone-row">
              <Controller
                key={location.phone}
                as={PhoneInput}
                control={control}
                country="us"
                onlyCountries={["us"]}
                countryCodeEditable={false}
                disableDropdown
                variant="outlined"
                label="Phone"
                name="phone"
                masks={{ us: "(...) ...-...." }}
                rules={{ minLength: 11, maxLength: 11 }}
                inputProps={{ maxLength: 17 }}
                defaultValue={location ? location.phone : ""}
              />
            </div>
            {errors.phone && (
              <FormHelperText
                className="phone-error"
                style={{ marginTop: "-24px", marginBottom: "24px" }}
              >
                Please enter a valid phone number
              </FormHelperText>
            )}
            <TextField
              key={location.streetAddress1}
              variant="outlined"
              label="Address"
              name="streetAddress1"
              inputRef={register({ required: true })}
              defaultValue={location ? location.streetAddress1 : ""}
              helperText={
                errors.streetAddress1 ? "This is a required field" : null
              }
            />
            <TextField
              key={location.streetAddress2}
              variant="outlined"
              label="Address line 2 (optional)"
              name="streetAddress2"
              inputRef={register}
              defaultValue={location ? location.streetAddress2 : ""}
            />
            <div className="field-row">
              <TextField
                kye={location.city}
                className="city"
                variant="outlined"
                label="City"
                name="city"
                inputRef={register({ required: true })}
                defaultValue={location ? location.city : ""}
                helperText={errors.city ? "This is a required field" : null}
              />
              <FormControl variant="outlined" className="state">
                <InputLabel htmlFor="state-select">State</InputLabel>
                <Controller
                  key={location.state}
                  as={Select}
                  control={control}
                  label="State"
                  name="state"
                  inputRef={register({ required: true })}
                  defaultValue={location ? location.state : ""}
                  native
                >
                  <option value=""></option>
                  {states &&
                    states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                </Controller>
                {errors.state && (
                  <FormHelperText className="phone-error">
                    This field is required
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                key={location.zip}
                className="zip"
                variant="outlined"
                label="Zip Code"
                name="zip"
                inputRef={register({
                  required: true,
                  minLength: 5,
                  maxLength: 5,
                })}
                inputProps={{ maxLength: 5 }}
                defaultValue={location ? location.zip : ""}
                helperText={
                  errors.zip?.type === "required"
                    ? "This is a required field"
                    : errors.zip
                    ? "Please enter a valid zip code"
                    : null
                }
              />
            </div>
            <TextField
              key={location.locationUrl}
              className="location-url"
              variant="outlined"
              label="Location URL"
              name="locationUrl"
              inputRef={register}
              defaultValue={location ? location.locationUrl : ""}
            />

            <Typography variant="h5">Hours of Operation</Typography>
            {daysOfWeek.map((day, dayIndex) => {
              let timeIn, timeOut;

              if (location?.hours) {
                const hours = days[dayIndex].split("-");
                timeIn = hours[1] === "Closed" ? "" : hours[1];
                timeOut = hours[2] === "Closed" ? "" : hours[2];
              } else {
                timeIn = "Closed";
                timeOut = "Closed";
              }
              return (
                <FormGroup
                  className="hours-row"
                  row
                  key={`${location.id}-${day}`}
                >
                  <TextField
                    key={`${location.id}-${day}-${timeIn}`}
                    label={day}
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name={`${day}-hours-in`}
                    inputRef={register}
                    defaultValue={timeIn}
                  />
                  <Typography variant="body1"> - </Typography>
                  <TextField
                    key={`${location.id}-${day}-${timeOut}`}
                    type="time"
                    label=" "
                    name={`${day}-hours-out`}
                    inputRef={register}
                    defaultValue={timeOut}
                  />
                </FormGroup>
              );
            })}
          </FormControl>
          <div className="buttons-row">
            <DefaultBtn
              variant="contained"
              color="secondary"
              endIcon={<SaveIcon />}
              size="small"
              onClick={handleSubmit(submitForm)}
            >
              Save Location
            </DefaultBtn>
            <DefaultBtn
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              endIcon={<CloseIcon />}
              size="small"
            >
              Cancel
            </DefaultBtn>
            {location?.id && (
              <DefaultBtn
                variant="contained"
                endIcon={<DeleteIcon />}
                size="small"
                backgroundColor="#e31937"
                textColor="#fff"
              >
                Delete Location
              </DefaultBtn>
            )}
          </div>
        </SingleItem>
      </Paper>
    </StyledModal>
  );
};

export default LocationModal;

const StyledModal = styled(Backdrop)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;

  .location-form-container {
    outline: 0;
    overflow-y: scroll;
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
    padding: 25px 15px;

    .single-item {
      width: auto;

      h5 {
        margin-bottom: 15px;
      }

      .MuiFormControl-root,
      .MuiFormGroup-root {
        margin-bottom: 15px;
      }

      .flag-dropdown::before {
        display: none;
      }

      .state {
        margin-left: 15px;
        margin-right: 15px;
        width: 100px;
      }

      .hours-row {
        display: flex;
        align-items: center;

        p {
          margin: 0 15px;
        }
      }
    }

    .buttons-row {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
`;
