import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import DefaultBtn from 'global/DefaultBtn'
import { useUser } from 'global/context/UserContext'
import { useToastMessage } from 'global/context/ToastMessageContext'

const ForgotPassword = () => {
	const { forgotPassword } = useUser()
	const { toastMessage } = useToastMessage()
	const history = useHistory()
	const { errors, register, handleSubmit } = useForm()

	useEffect(() => {
		if (toastMessage.type === 'success') {
			history.push('/agent-access')
		}
	}, [toastMessage.type])

	return (
		<>
			<Paper className='form-container'>
				<Typography variant='h3'>Forgot Password</Typography>
				<form onSubmit={handleSubmit(forgotPassword)}>
					<TextField
						className='login-input'
						variant='outlined'
						fullWidth
						label='Email'
						type='email'
						inputRef={register({ required: true })}
						name='email'
						defaultValue=''
						helperText={errors.email ? 'This is a required field' : null}
						autoFocus
					/>
					<DefaultBtn type='submit' variant='contained' color='secondary'>
						Send Password Reset Email
					</DefaultBtn>
				</form>
			</Paper>
		</>
	)
}

export default ForgotPassword
