import React, { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { useCookies } from 'react-cookie'

import DefaultBtn from 'global/DefaultBtn'
import { useToastMessage } from 'global/context/ToastMessageContext'

const DeleteBusinessConfirm = ({ businessId }) => {
	const [deleteConfirmOpen, toggleDeleteConfirm] = useState(false)
	const history = useHistory()
	const [cookies] = useCookies(['jwt'])

	const { showToastMessage } = useToastMessage()

	const handleDelete = async () => {
		try {
			const { data } = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/business/delete/${businessId}`, {
				headers: { 'x-auth-token': cookies?.jwt },
			})

			showToastMessage({
				type: 'success',
				message: 'Business deleted successfully',
			})
			history.push('/agent/businesses')
		} catch (err) {
			showToastMessage({
				type: 'error',
				message: `Error! ${err.message}`,
			})
		}
	}

	return (
		<>
			<DefaultBtn
				variant='contained'
				onClick={() => toggleDeleteConfirm(true)}
				startIcon={<DeleteIcon />}
				size='small'
				backgroundColor='#e31937'
				textColor='#fff'
			>
				Delete Business
			</DefaultBtn>
			<Dialog
				open={deleteConfirmOpen}
				onClose={() => toggleDeleteConfirm(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title' disableTypography>
					<Typography variant='h4'>Delete this Business?</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Deleting this business will remove all of it's locations from the map. It will{' '}
						<i>
							<b>NOT</b>
						</i>{' '}
						refund their payment. To refund their payment you must do so through{' '}
						<a href='https://stripe.com/'>Stripe</a>.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<DefaultBtn onClick={() => toggleDeleteConfirm(false)} color='primary' variant='outlined'>
						Cancel
					</DefaultBtn>
					<DefaultBtn onClick={handleDelete} variant='contained' backgroundColor='#e31937' textColor='#fff' autoFocus>
						Delete
					</DefaultBtn>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default DeleteBusinessConfirm
