import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import "react-phone-input-2/lib/material.css";
import AddIcon from "@material-ui/icons/AddOutlined";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

import CurrentReps from "./CurrentReps";
import { StyledReps } from "styles/FormStyles";
import DefaultBtn from "global/DefaultBtn";
import RepModal from "./RepModal";

import { useBusinessObjectsCrud } from "../hooks/useBusinessObjectsCrud";

const RepsFields = ({ currentBusiness, getBusiness, setLoading, show }) => {
  const { jwt: jwtParam } = useParams();
  const [cookies] = useCookies(["jwt"]);
  const [repBeingEdited, setRepBeingEdited] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const { deleteCurrent: deleteRep, addOrUpdateRep } = useBusinessObjectsCrud({
    callback: getBusiness,
    jwt: cookies?.jwt ? cookies.jwt : jwtParam,
  });

  const handleSubmitRep = async (repData) => {
    setLoading(true);
    let result;
    result = await addOrUpdateRep(repData, currentBusiness.id);

    if (result === "success") {
      handleCloseModal();
      getBusiness();
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setRepBeingEdited({});
    setModalOpen(false);
  };

  return (
    <StyledReps className="reps-information fields-group" show={show}>
      {!!currentBusiness?.representatives?.length && (
        <CurrentReps
          currentReps={currentBusiness.representatives}
          deleteRep={deleteRep}
          setRepBeingEdited={setRepBeingEdited}
          setModalOpen={setModalOpen}
        />
      )}

      <Typography variant="h5">New and Updated Representatives</Typography>

      <DefaultBtn
        variant="contained"
        color="secondary"
        onClick={() => setModalOpen(true)}
        endIcon={<AddIcon />}
        size="small"
      >
        Add Rep
      </DefaultBtn>
      {modalOpen && (
        <RepModal
          open={modalOpen}
          handleClose={handleCloseModal}
          rep={repBeingEdited}
          handleSubmitRep={handleSubmitRep}
        />
      )}
    </StyledReps>
  );
};

export default RepsFields;
