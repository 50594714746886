import axios from "axios";

const createBusinessFromApplication = async (data) => {
  const business = {
    name: data.businessName,
    description: data.businessDescription,
    website: data.website,
    streetAddress1: data.businessAddress,
    streetAddress2: data.businessAddress2,
    city: data.businessCity,
    state: data.businessState,
    zip: data.businessZip,
    status: "pending",
    amacMemberId: data.amacMemberId ? data.amacMemberId : null,
    referral: data.referral === 'other' && data.otherReferral ? 'other - ' + data.otherReferral : data.referral
  };

  const representatives = [
    {
      firstName: data.repFirstName,
      lastName: data.repLastName,
      phone: data.repPhone,
      email: data.repEmail,
    },
  ];

  const payload = {
    business,
    representatives,
  };

  let result;

  try {
    result = await axios.post(
      `${process.env.REACT_APP_ENDPOINT}/business/create`,
      payload
    );
  } catch (error) {
    throw new Error(error);
  }

  return result;
};

export default createBusinessFromApplication;
