import React, { useRef } from "react";
import {
  Paper,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  TextField,
  IconButton,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import TrashIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

import generateRandomString from "utils/generateRandomString";
import uploadImage from "utils/api/uploadImage";

const NewDiscountsFields = ({
  register,
  errors,
  discount,
  discounts,
  setDiscounts,
  deleteDiscount,
  removeDiscount,
  index,
}) => {
  const fileInput = useRef(null);
  const [cookies] = useCookies(["jwt"]);
  const { jwt } = useParams();

  const addCouponFile = async (e, discountIndex) => {
    const discountsCopy = [...discounts];
    const file = e.target.files[0];
    const fileKey = generateRandomString(16);

    if (file.name) {
      const { result, error } = await uploadImage(
        file,
        fileKey,
        cookies?.jwt || jwt
      );
      if (!error) {
        discountsCopy[discountIndex].file = result;
        setDiscounts(discountsCopy);
      } else {
        console.log("Image upload error: ", error.message);
        alert(
          `Uh oh, there was a problem uploading that image. Please try again.`
        );
      }
    }
  };

  return (
    <Paper className="discount-item">
      <div className="discount-left">
        <FormControl
          variant="outlined"
          error={errors[`discount-${index}`]}
          size="small"
        >
          <InputLabel htmlFor="logo">Coupon</InputLabel>
          <OutlinedInput
            variant="outlined"
            type="file"
            className="discount-file-input"
            name={`discount-${index}-file`}
            id={`discount-${index}-file`}
            label="Coupon"
            inputProps={{ accept: ".jpg, .png, .gif, .pdf" }}
            onChange={(e) => addCouponFile(e, index)}
            inputRef={fileInput}
          />
          {errors.logo && (
            <FormHelperText>There was an error in this field.</FormHelperText>
          )}
        </FormControl>
        <TextField
          className="discount-text"
          variant="outlined"
          multiline
          rowsMax={3}
          label="Discount Text"
          name={`discount-${index}-text`}
          inputRef={register({ required: true })}
          helperText={
            errors[`discount-${index}-text`] ? "This field is required" : null
          }
          defaultValue={discount.text}
          size="small"
        />
      </div>
      <div className="discount-right">
        <img src={`${process.env.REACT_APP_S3_URL}${discount.file}`} alt="" />
      </div>
      {!!discount.id ? (
        <IconButton className="remove-discount-item" onClick={deleteDiscount}>
          <TrashIcon />
        </IconButton>
      ) : (
        <IconButton
          className="remove-discount-item"
          onClick={() => removeDiscount(index)}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default NewDiscountsFields;
