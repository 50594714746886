import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { useCookies } from "react-cookie";

import BusinessListItem from "./components/BusinessListItem";
import Sidebar from "./components/Sidebar";
import LoadingSpinner from "../../global/LoadingSpinner";
import { usePagination } from "utils/hooks/usePagination";

const ClientBusinessSearch = ({ onlineBusinessesOnly }) => {
  const [businesses, setBusinesses] = useState([]);
  const { currentPage, handlePageChange } = usePagination();
  const [searchedName, setSearchedName] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const [cookies] = useCookies(["jwt"]);

  useEffect(() => {
    setLoading(true);
    const options = {
      params: { page: currentPage },
      headers: { "x-auth-token": cookies?.jwt },
    };
    if ( onlineBusinessesOnly ) {
      options.params.isRemote = 1
    }
    if (searchSubmitted) {
      options.params.name = searchedName;
      options.params.tags = selectedTags;
    }
    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/business/search`, options)
      .then((res) => {
        if (res.status === 200) {
          setBusinesses(res.data);
        } else {
          throw new Error(
            `There was an error fetching the businesses. Error code: ${res.status}`
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error: ", err.response?.data);
        setLoading(false);
      });
  }, [currentPage]);

  const search = () => {
    if (!searchedName && !selectedTags.length) {
      setSearchSubmitted(false);
    } else {
      setSearchSubmitted(true);
    }
    setLoading(true);

    const params = {
        page: currentPage,
        name: searchedName,
        tags: selectedTags,
      }

    if ( onlineBusinessesOnly ) {
      params.isRemote = 1
    }

    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/business/search`, {
        params,
        headers: { "x-auth-token": cookies?.jwt },
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setBusinesses(res.data);
        } else {
          throw new Error(
            `There was an error fetching the businesses. Error code: ${res.status}`
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("error: ", err.response?.data);
      });
  };

  return (
    <StyledContainer>
      <LoadingSpinner loading={loading} />
      <Sidebar
        search={search}
        searchedName={searchedName}
        setSearchedName={setSearchedName}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <Paper className="search-results" elevation={3}>
        <ul className="results-list">
          {!!businesses.rows?.length &&
            businesses.rows?.map((business) => (
              <BusinessListItem key={business.id} business={business} />
            ))}
        </ul>
        {businesses.count > 50 && (
          <div className="pagination">
            <Pagination
              count={Math.ceil(businesses.count / 50)}
              page={currentPage}
              onChange={handlePageChange}
              color="secondary"
            />
          </div>
        )}
      </Paper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: calc(100vh - 150px);
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;

  .search-results {
    flex: 1;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .results-list {
      margin: 0;
      padding: 0;
      flex: 1;
      overflow-y: scroll;
      box-shadow: 0 -10px 10px -10px rgba(50, 50, 50, 0.2) inset;
    }

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      width: 100%;
      border-top: 1px solid rgba(75, 75, 75, 0.1);
    }
  }

  @media (max-width: 990px) {
    flex-direction: column;

    .search-results {
      margin-left: 15px;
    }
  }
`;

export default ClientBusinessSearch;
