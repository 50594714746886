import React from "react";
import ScrollIntoView from "react-scroll-into-view";

import MapMarkerIcon from "images/map-marker.png";
import MarkerBorder from "images/marker-border.svg";

const MapMarker = ({
  id,
  isActive,
  listRef,
  scrollToItem,
  setActiveLocation,
  centerOnLocation,
}) => {
  const handleClickLocation = () => {
    if (listRef?.current) {
      const locationTarget = document.getElementById(`location-${id}`);
      listRef.current.scrollTop =
        locationTarget.offsetTop - listRef.current.offsetTop;
    } else if (scrollToItem) {
      scrollToItem();
    }
    centerOnLocation();
    setActiveLocation(id, "marker-click");
  };

  return (
    <div
      className={`map-marker-container ${isActive ? "active" : ""}`}
      onMouseEnter={() => setActiveLocation(id, "marker")}
      onClick={handleClickLocation}
    >
      <img
        className={`marker-border ${isActive ? "active" : ""}`}
        src={MarkerBorder}
        alt=""
      />
      <img src={MapMarkerIcon} alt="" />
    </div>
  );
};

export default React.memo(MapMarker);
