import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import CloseIcon from "@material-ui/icons/CloseOutlined";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useUser } from "global/context/UserContext";

import { StyledHeroContent, StyledFormControl } from "../styles";
import heroImage from "images/hero-bg-image.png";
import DefaultBtn from "global/DefaultBtn";
import Hero from "global/Hero";

const HeroSection = () => {
  const {
    userLocation,
    getUserLocation,
    userAllowLocation,
    removeUserLocation,
  } = useUser();
  const { register, handleSubmit, errors, setValue } = useForm();
  const history = useHistory();

  const onSubmit = (formData) => {
    const { zip } = formData;
    if (userLocation?.coords) {
      history.push({
        pathname: "/map",
      });
    } else if (zip) {
      history.push({
        pathname: "/map/",
        search: `?zip=${zip}`,
      });
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const handleRemoveLocation = () => {
    removeUserLocation();
    setValue("zip", "");
  };

  return (
    <Hero background={heroImage}>
      <StyledHeroContent className="hero-content">
        <Typography variant="h3">
          Looking for discounts on goods and services right in your hometown?
        </Typography>
        <Typography variant="h5" className="with-background">
          Look no further.
        </Typography>
        <Typography variant="body1">
          The AMAC Discount Map shows local merchants who offer a special
          discount just for AMAC members!
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} className="input-row">
          <StyledFormControl>
            <OutlinedInput
              startAdornment={
                <InputAdornment
                  position="start"
                  onClick={userAllowLocation}
                  title="Use my location"
                >
                  <LocationIcon />
                </InputAdornment>
              }
              endAdornment={
                userLocation?.coords && (
                  <InputAdornment
                    position="end"
                    onClick={handleRemoveLocation}
                    title="Remove my location"
                  >
                    <CloseIcon />
                  </InputAdornment>
                )
              }
              className={`o-input zip-input ${errors.zip && "input-error"}`}
              name="zip"
              type="text"
              inputRef={register({
                required: true,
              })}
              placeholder="Enter your Zip code to find discounts near you..."
              value={userLocation?.coords ? "Current Location" : null}
              disabled={!!userLocation?.coords}
            />
            {errors.zip && (
              <span className="error">
                A valid zip code is required to search
              </span>
            )}
          </StyledFormControl>

          <DefaultBtn
            variant="contained"
            color="secondary"
            width="180px"
            type="submit"
          >
            SEARCH
          </DefaultBtn>
        </form>
      </StyledHeroContent>
    </Hero>
  );
};

export default HeroSection;
