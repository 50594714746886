import { useState } from "react";

export const usePagination = (defaultPage = 1) => {
  const [currentPage, setCurrentPage] = useState(defaultPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return { currentPage, setCurrentPage, handlePageChange };
};
