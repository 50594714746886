import React from "react";
import styled from "styled-components";

const Container = ({ className, children }) => {
  return <StyledContainer className={className}>{children}</StyledContainer>;
};

const StyledContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: auto;
  padding-left: 2%;
  padding-right: 2%;
`;

export default Container;
