import React from "react";
import styled from "styled-components";
import {
  Backdrop,
  Paper,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import CloseIcon from "@material-ui/icons/ClearOutlined";
import SaveIcon from "@material-ui/icons/SaveOutlined";

import DefaultBtn from "global/DefaultBtn";
import { SingleItem } from "styles/FormStyles";

const LocationModal = ({ open, handleClose, rep, handleSubmitRep }) => {
  const { register, errors, control, handleSubmit } = useForm();

  const submitForm = (repData) => {
    if (rep.id) {
      repData.id = rep.id;
    }

    handleSubmitRep(repData);
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <Paper className="rep-form-container">
        <SingleItem variant="outlined" className="single-item">
          <Typography variant="h5">
            {rep?.id ? "Updating Location" : "New Location"}
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  name="status"
                  inputRef={register}
                  defaultChecked={rep.status === "active"}
                />
              }
              label="Representative Active"
            />
          </Typography>
          <FormControl className="form-control">
            <div className="field-row">
              <TextField
                variant="outlined"
                label="First Name"
                name="firstName"
                inputRef={register({ required: true })}
                defaultValue={rep.firstName}
                helperText={
                  errors.firstName ? "This is a required field" : null
                }
              />
              <TextField
                variant="outlined"
                label="Last Name"
                name="lastName"
                inputRef={register({ required: true })}
                defaultValue={rep.lastName}
                helperText={errors.lastName ? "This is a required field" : null}
              />
            </div>
            <div className="field-row">
              <TextField
                variant="outlined"
                label="Email"
                type="email"
                inputRef={register({ required: true })}
                name="email"
                defaultValue={rep.email}
                helperText={errors.email ? "This is a required field" : null}
              />
              <div>
                <Controller
                  as={PhoneInput}
                  control={control}
                  country="us"
                  onlyCountries={["us"]}
                  countryCodeEditable={false}
                  disableDropdown
                  variant="outlined"
                  label="Phone"
                  name="phone"
                  masks={{ us: "(...) ...-...." }}
                  rules={{ required: true, minLength: 11, maxLength: 11 }}
                  inputProps={{ maxLength: 17 }}
                  defaultValue={rep.phone}
                />
                {errors.phone && (
                  <FormHelperText
                    className="phone-error"
                    style={{ marginTop: "-24px", marginBottom: "24px" }}
                  >
                    Please enter a valid phone number
                  </FormHelperText>
                )}
              </div>
            </div>
          </FormControl>
          <div className="buttons-row">
            <DefaultBtn
              variant="contained"
              color="secondary"
              endIcon={<SaveIcon />}
              size="small"
              onClick={handleSubmit(submitForm)}
            >
              Save Rep
            </DefaultBtn>
            <DefaultBtn
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              endIcon={<CloseIcon />}
              size="small"
            >
              Cancel
            </DefaultBtn>
          </div>
        </SingleItem>
      </Paper>
    </StyledModal>
  );
};

export default LocationModal;

const StyledModal = styled(Backdrop)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;

  .rep-form-container {
    outline: 0;
    overflow-y: scroll;
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
    padding: 25px 15px;

    .single-item {
      width: auto;

      h5 {
        margin-bottom: 15px;
      }

      .MuiFormControl-root,
      .MuiFormGroup-root {
        margin-bottom: 15px;
      }

      .flag-dropdown::before {
        display: none;
      }
    }

    .buttons-row {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
`;
