import styled from 'styled-components'

export const StyledPage = styled.div`
	background: #e0e7fd;
	padding: 100px 2%;

	h2,
	h3,
	h5 {
		text-align: center;
		margin-bottom: 25px;
		font-weight: 100;
	}

	.reset-expired-btn {
		max-width: 200px;
		margin: auto;
	}

	.form-container {
		background: #f4f7fe;
		width: 100%;
		max-width: 500px;
		margin: auto;
		padding: 25px 50px 50px;

		.login-input {
			margin-bottom: 25px;

			div {
				background: #fff;
			}

			.MuiFormHelperText-root {
				color: red;
			}
		}

		input {
			background: #fff;
		}

		.button-row {
			display: flex;
			justify-content: space-around;
			align-items: center;

			.forgot-password-btn {
				opacity: 0.5;
				font-size: 16px;
			}
		}
	}
`
