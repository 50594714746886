import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

const SubscriptionData = ({ currentBusiness }) => {
  return (
    <>
      <Typography variant="h6">Subscription</Typography>

      <StyledTable component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Activation Date</TableCell>
              <TableCell>Expiration Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {currentBusiness.status}
              </TableCell>
              <TableCell>
                {currentBusiness.activationDate
                  ? moment(currentBusiness.activationDate).format('MM/DD/YY')
                  : ''}
              </TableCell>
              <TableCell className="expiration-date-cell">
                {currentBusiness.expirationDate
                  ? moment(currentBusiness.expirationDate).format('MM/DD/YY')
                  : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTable>
    </>
  );
};

export default SubscriptionData;

const StyledTable = styled(TableContainer)`
  margin-bottom: 50px;

  .MuiTableCell-head {
    font-weight: bold;
  }
`;
