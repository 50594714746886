import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { StyledIconSection } from '../styles'
import Container from 'styles/Container'
import IconPoint from 'global/IconPoint'
import searchBarIcon from 'images/search-bar-icon.png'
import mapWithMarkerIcon from 'images/map-with-marker-icon.png'
import usMapFlagIcon from 'images/us-map-flag-icon.png'

const IconSection = () => {
	return (
		<StyledIconSection>
			<Container>
				<Typography variant='h2' color='secondary'>
					Using the map is a snap
				</Typography>
				<div className='icons-section'>
					<IconPoint icon={<img src={searchBarIcon} alt='Search Bar Icon' />} iconWidth='171px' iconHeight='130px'>
						<Typography variant='body1' color='primary'>
							Simply enter your zip code and/or the name of the service or business.
						</Typography>
					</IconPoint>
					<IconPoint icon={<img src={mapWithMarkerIcon} alt='Roadmap Icon' />} iconWidth='135px' iconHeight='130px'>
						<Typography variant='body1' color='primary'>
							The map will display local merchants in your area along with the discount offered.  Supporting local small
							business couldn’t be easier!
						</Typography>
					</IconPoint>
					<IconPoint icon={<img src={usMapFlagIcon} alt='US Map Flag Icon' />} iconWidth='157px' iconHeight='130px'>
						<Typography variant='body1' color='primary'>
							The AMAC discount map is just another reason why AMAC is better for you, better for America!
						</Typography>
					</IconPoint>
				</div>
			</Container>
		</StyledIconSection>
	)
}

export default IconSection
