import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const QuickBusinessData = ({ currentBusiness }) => {
  return (
    <>
      <Typography variant="h6">Business Information</Typography>

      <StyledTable component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Mailing Address</TableCell>
              <TableCell>BusinessCategories</TableCell>
              <TableCell># of Locations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {currentBusiness.name}
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  {currentBusiness.streetAddress1}
                </Typography>
                {currentBusiness.streetAddress2 && (
                  <Typography variant="body1">
                    {currentBusiness.streetAddress2}
                  </Typography>
                )}
                <Typography variant="body1">
                  {currentBusiness.city}, {currentBusiness.state}{' '}
                  {currentBusiness.zip}
                </Typography>
              </TableCell>
              <TableCell>
                <ul>
                  {currentBusiness.tags.map(tag => (
                    <li key={tag.id}>{tag.title}</li>
                  ))}
                </ul>
              </TableCell>
              <TableCell>{currentBusiness.locations.length}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTable>
      <Typography variant="body1" style={{ marginBottom: '50px' }}>
        * if anything above is incorrect and needs to be changed please call us
        at <a href="tel:800-876-6196">800-876-6196</a>
      </Typography>
    </>
  );
};

export default QuickBusinessData;

const StyledTable = styled(TableContainer)`
  .MuiTableCell-head {
    font-weight: bold;
  }

  p {
    font-size: 0.875rem;
  }
`;
