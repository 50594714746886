import React from 'react'
import styled from 'styled-components'

const Hero = ({ background, noOverlay, children }) => {
	return <HeroSection background={background} noOverlay={noOverlay}>{children}</HeroSection>
}

export default Hero

const HeroSection = styled.div`
  position: relative;
  background-image: ${(props) =>
      props.noOverlay
        ? ''
        : 'linear-gradient(-160deg, rgba(1, 11, 28, 0.6) 3%, rgba(1, 11, 28, 0.3) 100%),'}
    url(${(props) => (props.background ? props.background : '')});
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 500px;
`;
