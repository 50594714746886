import React from "react";
import EditIcon from "@material-ui/icons/EditOutlined";
import TrashIcon from "@material-ui/icons/DeleteForeverOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

const CurrentReps = ({
  currentReps,
  editRep,
  deleteRep,
  setRepBeingEdited,
  setModalOpen,
  getBusiness,
}) => {
  return (
    <div className="current-reps">
      <Typography variant="h5">Current Representatives</Typography>
      <Paper>
        <ul>
          {!!currentReps?.length &&
            currentReps.map((rep, index) => (
              <li className="current-rep-item" key={rep.id}>
                <div className="left">
                  <Typography variant="h6">
                    {rep.firstName} {rep.lastName}
                  </Typography>
                  <Typography variant="body1">
                    Status: <i>{rep.status}</i>
                  </Typography>
                </div>

                <div className="middle">
                  <Typography variant="body1">
                    <a href={`mailto:${rep.email}`}>{rep.email}</a>
                  </Typography>
                  <Typography variant="body1">
                    <a href={`tel:${rep.phone}`}>{rep.phone}</a>
                  </Typography>
                </div>

                <div className="right">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setRepBeingEdited(rep);
                      setModalOpen(true);
                    }}
                    title="Edit Current Representative"
                  >
                    <EditIcon />
                  </IconButton>
                  {rep.status !== "deleted" && (
                    <IconButton
                      onClick={() => deleteRep("representative", rep.id)}
                      title="Delete Current Representative"
                    >
                      <TrashIcon />
                    </IconButton>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </Paper>
    </div>
  );
};

export default CurrentReps;
