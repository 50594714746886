import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { useCookies } from "react-cookie";

import { useToastMessage } from "global/context/ToastMessageContext";
import DefaultBtn from "global/DefaultBtn";

const WelcomeEmailModal = ({ businessId, reps, closeModal }) => {
  const [isSending, setIsSending] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [cookies] = useCookies(["jwt"]);

  const { showToastMessage } = useToastMessage();

  const handleEmailChange = (e) => {
    setSelectedEmail(e.target.value);
  };

  const handleEmailSelect = (e) => {
    setSelectedEmail(e.target.innerHTML);
  };

  const handleSend = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/business/update-request`,
        { email: selectedEmail, businessId },
        {
          headers: { "x-auth-token": cookies?.jwt },
        }
      );

      console.log("data: ", data);

      if (data) {
        closeModal();
        showToastMessage({
          type: "success",
          message: "Welcome email successfully sent.",
        });
      }
    } catch (err) {
      showToastMessage({
        type: "error",
        message: "Error! Please try sending again.",
      });
    }
  };

  return (
    <StyledModal>
      <IconButton className="close-btn" onClick={closeModal}>
        <CloseIcon />
      </IconButton>
      <Typography variant="h3">Send Welcome Email</Typography>
      <Autocomplete
        freeSolo
        id="welcome-email-input"
        options={reps}
        getOptionLabel={(rep) => rep.email}
        // style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Email Address"
            variant="outlined"
            value={selectedEmail}
            onChange={handleEmailChange}
          />
        )}
        onChange={handleEmailSelect}
      />
      <DefaultBtn
        variant="contained"
        color="primary"
        disabled={isSending || !selectedEmail}
        onClick={handleSend}
      >
        {isSending
          ? "Sending..."
          : selectedEmail
          ? "Send Email"
          : "Enter Email Address"}
      </DefaultBtn>
    </StyledModal>
  );
};

const StyledModal = styled(Paper)`
  padding: 40px 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  max-width: 675px;

  h3 {
    margin-bottom: 40px;
    text-align: center;
  }

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
  }
`;

export default WelcomeEmailModal;
