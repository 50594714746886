import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

import { StyledBusinessOwnerSection } from '../styles'
import DefaultBtn from 'global/DefaultBtn'
import Container from 'styles/Container'

const BusinessOwnerSection = () => {
	return (
		<StyledBusinessOwnerSection>
			<Container className='description-container'>
				<div className='inner-container'>
					<Typography variant='h3' color='secondary'>
						Hello business owners!
					</Typography>
					<Typography variant='body1'>
						Would you like information about appearing on the AMAC Discount Map and offering a discount to AMAC members
						at YOUR business?
					</Typography>
					{/* <DefaultBtn to='/business' component={Link} variant='contained' color='secondary' width='240px' type='submit'>
						Learn More Here
					</DefaultBtn> */}
				</div>
				<div className='mobile-store-img-container' />
			</Container>
		</StyledBusinessOwnerSection>
	)
}

export default BusinessOwnerSection
