import React from "react";
import styled from "styled-components";

const ImageRow = ({ image, imageAlt, reverse, children }) => {
  return (
    <StyledRow reverse={reverse}>
      <img src={image} alt={imageAlt} />
      <div className="content-container">{children}</div>
    </StyledRow>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 120px auto;

  img {
    order: ${({ reverse }) => (reverse ? 2 : 1)};
    margin: ${({ reverse }) => (reverse ? "0 0 0 36px" : "0 36px 0 0")};
    width: calc(50% - 36px);
  }

  .content-container {
    order: ${({ reverse }) => (reverse ? 1 : 2)};
    margin: ${({ reverse }) => (reverse ? "0 36px 0 0" : "0 0 0 36px")};
    width: calc(50% - 36px);

    .second-header {
      margin-top: 25px;
    }

    .inline-link {
      padding: 0;
      box-shadow: none;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin: 60px auto;

    img {
      order: 1;
      width: 100%;
      margin: 0;
    }

    .content-container {
      order: 2;
      width: 100%;
      margin: 24px 0 0;
    }
  }
`;

export default ImageRow;
