import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import LocationIcon from "@material-ui/icons/LocationOn";
import { getCoords } from "utils/getCoords";

const MapSearch = ({ defaultCenter, setMapCenter }) => {
  const [zip, setZip] = useState("");

  const handleCoords = async (e) => {
    e.preventDefault();
    const coords = await getCoords(zip);
    setMapCenter(coords);
  };

  return (
    <form onSubmit={handleCoords}>
      <SearchInput
        label="Search your zip or address..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCoords}>
                <LocationIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={zip}
        onChange={(e) => setZip(e.target.value)}
      />
    </form>
  );
};

export default MapSearch;

const SearchInput = styled(TextField)`
  background: #fff;
  z-index: 10;
  width: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;

  .MuiInputBase-root {
    margin-top: 0;
    padding: 8px;
  }

  .MuiInputBase-input {
    padding: 12px 5px 5px;
    font-size: 14px;
  }

  .MuiInputLabel-formControl {
    transform: translate(8px, 16px);
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.75);
  }

  svg {
    color: rgba(50, 50, 50, 0.6);
  }
`;
