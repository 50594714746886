import { useState, useEffect } from "react";
import axios from "axios";

export const useGetTags = (initial = []) => {
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(initial);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_ENDPOINT}/tag/`)
      .then((res) => {
        if (res.status === 200) {
          setTags(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error: ", err.response?.data);
        setLoading(false);
      });
  }, []);

  return { tags, loading };
};
