import styled from "styled-components";

import Container from "styles/Container";
import { StyledFormPage } from "styles/FormStyles";

export const StyledHeroSection = styled.div`
  .hero-content {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 104px 5%;
    text-align: center;

    h1 {
      font-size: 70px;
      line-height: 80px;
      font-weight: 800;
      margin-bottom: 38px;
    }

    h5 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 12px;
    }

    p {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 48px;
    }

    button,
    a {
      box-shadow: 0px 5px 10px rgba(26, 47, 84, 0.3);
      font-size: 30px;
      padding: 16px 32px;
    }
  }

  @media (max-width: 767px) {
    .hero-content {
      h1 {
        font-size: 48px;
        line-height: 58px;
      }

      h5 {
        font-size: 24px;
        line-height: 34px;
      }

      p {
        font-size: 22px;
        line-height: 32px;
      }

      button,
      a {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 575px) {
    .hero-content {
      h1 {
        font-size: 32px;
        line-height: 42px;
      }

      h5 {
        font-size: 20px;
        line-height: 30px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }

      button,
      a {
        font-size: 20px;
      }
    }
  }
`;

export const StyledSection = styled(Container)`
  h3 {
    font-size: 44px;
    line-height: 54px;
    margin-bottom: 24px;
  }

  h4 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 24px;
  }

  p {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;

    .big-text {
      font-size: 32px;
      font-style: italic;
    }
  }

  button,
  a {
    box-shadow: 0px 5px 10px rgba(26, 47, 84, 0.3);
    font-family: "Source Sans Pro", sans-serif;
    font-size: 21px;
    margin-top: 36px;
    padding: 16px 32px;
  }

  @media (max-width: 767px) {
    h3 {
      font-size: 36px;
      line-height: 46px;
    }

    h4 {
      font-size: 24px;
      line-height: 34px;
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }

    button,
    a {
      font-size: 18px;
    }
  }
`;

export const StyledApplicationSection = styled(StyledFormPage)`
  background: #e0e7fd;
  padding: 100px 2%;

  svg.error {
    color: #e31937;
  }

  .form-container {
    background: #f4f7fe;
    width: 100%;
    max-width: 800px;
    margin: auto;
    border-radius: 4px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);

    input {
      background: #fff;
    }

    .MuiFormHelperText-root {
      background: transparent;
    }

    .form-error input {
      border-bottom: 2px solid #e31937;
      border-radius: 4px;
      transition: all 0.2s linear;
    }

    .success-message {
      display: flex;
      align-items: center;
      background: #279f8233;
      color: #279f82;
      border-left: 5px solid #279f82;
      border-radius: 4px;
      padding: 12px 24px;

      h2 {
        margin-bottom: 0;
        padding-right: 24px;
      }
    }

    .error-message {
      margin: -24px 10% 24px;
      color: #e31937;
      background: #e3193722;
      border-left: 5px solid #e31937;
      border-radius: 4px;
      padding: 12px 24px;
    }

    @media (max-width: 540px) {
      .field-row {
        flex-direction: column;

        .city,
        .state,
        .zip {
          width: 100%;
        }
      }
    }
  }

  .button-row {
    display: flex;
    justify-content: space-between;
    margin: 50px auto;
    width: 80%;
    position: relative;

    div {
      flex: 1;

      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .MuiCircularProgress-root {
      position: absolute;
      right: 0;
    }

    span.app {
      margin-right: 7px;
    }

    @media (max-width: 600px) {
      & > button {
        margin: 0 15px;
      }
      span.app {
        display: none;
      }
    }
  }
`;
