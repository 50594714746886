import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'

export const StyledPage = styled.div`
	background: #e0e7fd;
	text-align: center;
	padding: 50px 2%;

	h1 {
		margin-bottom: 50px;
	}

	.accordion-container {
		width: 800px;
		max-width: 100%;
		margin: 0 auto 50px;
		text-align: left;
		background: #e0e7fd;
		border: none;

		.MuiAccordionSummary-root.Mui-expanded {
			background: #f7f7f7;
		}

		.faq-question {
			font-weight: bold;
		}

		.faq-answer {
			flex-direction: column;
		}
	}

	.more-info-btn {
		margin: auto;
	}
`

export const StyledPopover = styled(Popover)`
	top: 15px !important;
	.MuiPopover-paper {
		text-align: center;
		padding: 25px;
	}

	.button-row {
		padding-top: 15px;
		display: flex;
	}

	a,
	button {
		margin: 0 10px;
	}

	@media (max-width: 575px) {
		.button-row {
			flex-direction: column;

			a,
			button {
				margin: 10px;
			}
		}
	}
`
