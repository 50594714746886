import axios from "axios";

import uploadImage from "utils/api/uploadImage";

const useSubmitBusiness = ({
  currentBusiness,
  discounts,
  selectedTags,
  setLoading,
  logo,
  jwt,
} ) => {
  const submit = async ( data, status = null ) => {

    console.log('data: ', data)
    let result = {};
    setLoading(true);
    let logoKey = currentBusiness?.logo ? currentBusiness.logo : null;
    if (logo.name) {
      const { result, error } = await uploadImage(logo, logoKey, jwt);
      if (!error) {
        logoKey = result;
      }
    }

    // create the array of discounts with the correct fields
    const discountsArray = discounts.map((discount, index) => {
      const obj = {
        text: data[`discount-${index}-text`],
        file: discount.file,
      };

      // if discount has id key, it means it's a current discount being edited
      if (discount.id) obj.id = discount.id;

      return obj;
    } );

    // get Business data formatted
    const business = {
      name: data.businessName,
      ein: data.ein ? data.ein : null,
      logo: logoKey,
      website: data.website,
      description: data.businessDescription,
      streetAddress1: data.businessAddress,
      streetAddress2: data.businessAddress2,
      city: data.businessCity,
      state: data.businessState,
      zip: data.businessZip,
      discounts: discountsArray,
      amacMemberId: data.amacMemberId ? data.amacMemberId : null,
      isRemote: data.isRemote ? 1 : 0,
      referral: !data.referral ? null : data.referral === 'other' && data.otherReferral ? 'other - ' + data.otherReferral : data.referral
    };

    if (currentBusiness.id) {
      business.id = parseInt(currentBusiness.id);
    }

    if (status === "active") {
      business.status = status;
      business.activationDate = !currentBusiness.activationDate
        ? new Date()
        : currentBusiness.activationDate;
    }

    // get Tags array together
    const tags = selectedTags.map((tag) => tag.id);

    const payload = {
      business,
      tags,
    };

    // Either update business if businessId is present, or create new business otherwise
    if (currentBusiness.id) {
      result = await axios
        .put(`${process.env.REACT_APP_ENDPOINT}/business/update`, payload, {
          headers: { "x-auth-token": jwt },
        })
        .then((res) => {
          setLoading(false);
          return res;
        })
        .catch((err) => {
          const result = {
            status: "error",
            message: err.message,
          };
          setLoading(false);
          return result;
        });
    } else {
      result = await axios
        .post(`${process.env.REACT_APP_ENDPOINT}/business/create`, payload, {
          headers: { "x-auth-token": jwt },
        })
        .then((res) => {
          setLoading(false);
          return res;
        })
        .catch((err) => {
          const result = {
            status: "error",
            message: err.message,
          };
          setLoading(false);
          return result;
        });
    }
    return result;
  };

  return { submit };
};

export default useSubmitBusiness;
